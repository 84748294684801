import Vue from "vue";
import VueRouter from 'vue-router'

// import storage from './storage.js';
import {appInit} from '../guard';
import routerConfig from './router-config';

Vue.use(VueRouter);

const router = new VueRouter(routerConfig);

router.beforeEach(appInit);


export default router;
