/**
 * Created by User on 07.10.2019.
 */
'use strict';
import User from './user.js'
import Employee from './employee.js'
import Role from './role.js'
import { RoleCodeEnum } from "../enums/index.js";

export default class Session {
    /**
     * @typedef {Object} SessionConfig
     * @property {UserConfig} [user]
     * @property {EmployeeConfig} [employee]
     * @property {RoleConfig[]} [roles]
     */

    /**
     *
     * @param {SessionConfig} config
     */
    constructor( config = {} ) {
        this.user     = new User( config.user );
        this.employee = config.employee && new Employee( config.employee );
        this.roles    = ( config.roles || [] ).map( ( item ) => new Role( item ) );
    }

    /**
     *
     * @return {Employee}
     */
    getEmployee() { return Object.assign( {}, this.employee ); }

    /**
     *
     * @param roleCode
     * @return {boolean}
     */
    isRole( roleCode ) { return this.roles.some( ( item ) => item.code === roleCode ); }

    /**
     *
     * @return {boolean}
     */
    isAdmin() { return this.isRole( RoleCodeEnum.Admin ); }

    /**
     *
     * @return {boolean}
     */
    isMember() { return this.isRole( RoleCodeEnum.Member ); }

    /**
     *
     * @return {boolean}
     */
    isViewer() { return this.isRole( RoleCodeEnum.Viewer ); }

    /**
     *
     * @return {boolean}
     */
    isAnalytic() { return this.isRole( RoleCodeEnum.Analytic ); }

    /**
     *
     * @return {boolean}
     */
    isDepartment() { return this.isRole( RoleCodeEnum.Department ); }

    /**
     *
     * @return {boolean}
     */
    isExpertise() { return this.isRole( RoleCodeEnum.Expertise ); }
}
