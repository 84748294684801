/**
 * Created by User on 12.10.2019.
 */
'use strict';

import IEntity from "./i-entity.js";


export default class DefendedUnderSupervision extends IEntity {
    /**
     * @typedef {IEntityConfig} DefendedUnderSupervisionConfig
     * @property {string} [science_report_id]
     * @property {string} [fio]
     * @property {string} [scientific_specialties_code_name]
     * @property {string} [work_training_type_id]
     * @property {string} [dissertation_council_text]
     */

    /**
     *
     * @param {DefendedUnderSupervisionConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.science_report_id                   = config.science_report_id;
        this.fio                                 = config.fio;
        this.work_training_type_id               = config.work_training_type_id;
        this.scientific_specialties_code_name    = config.scientific_specialties_code_name;


        //Диссертационный совет
        this.dissertation_council_text = config.dissertation_council_text;
    }
}
