<template>
    <b-form-group :label="displayLabel"
                  :label-for="displayNameId"
                  :label-cols="labelCols"
                  :label-cols-sm="labelColsSm"
                  :label-cols-md="labelColsMd"
                  :label-cols-lg="labelColsLg"
                  :label-cols-xl="labelColsXl"
                  :label-align="labelAlign"
                  :label-align-sm="labelAlignSm"
                  :label-align-md="labelAlignMd"
                  :label-align-lg="labelAlignLg"
                  :label-align-xl="labelAlignXl"
                  :state="state"
                  :label-size="labelSize"
                  :description="description"
                  :valid-feedback="validFeedback"
                  :invalid-feedback="invalidFeedback">
        <b-form-checkbox-group ref="input" :id="displayNameId"
                               :name="name"
                               :state="state"
                               :checked="computedValue"
                               @change="onChange"
                               :required="required"
                               :size="size"
                               :plain="plain"
                               :options="options"
                               :autofocus="autofocus"
                               :stacked="stacked"
                               :buttons="buttons"
                               :buttonVariant="buttonVariant"
                               :valueField="valueField"
                               :textField="textField"
                               :disabledField="disabledField"
                               :switches="switches"
                               :disabled="!isEdit"></b-form-checkbox-group>
    </b-form-group>
</template>

<script>
    export default {
        name:       "FieldGroupCheckbox"
        , model:    {
            prop:    'value'
            , event: 'update'
        }
        , props:    {
            label:             { type: String, default: '' }
            , labelSize:       { type: String }
            , description:     { type: String }
            , disabled:        { type: Boolean }
            , isEdit:          { type: Boolean, default: false }
            , labelCols:       { type: [ Number, String, Boolean ] }
            , labelColsSm:     { type: [ Number, String, Boolean ] }
            , labelColsMd:     { type: [ Number, String, Boolean ] }
            , labelColsLg:     { type: [ Number, String, Boolean ] }
            , labelColsXl:     { type: [ Number, String, Boolean ] }
            , labelAlign:      { type: String }
            , labelAlignSm:    { type: String }
            , labelAlignMd:    { type: String }
            , labelAlignLg:    { type: String }
            , labelAlignXl:    { type: String }
            , validFeedback:   { type: String }
            , invalidFeedback: { type: String }
            //--
            , state:           { type: Boolean, default: null }

            , itemId:        { type: String }
            , name:          { type: String, default: 'fgc' }
            , value:         { type: [ String, Number, Object, Array, Boolean ] }
            , size:          { type: String }
            , required:      { type: Boolean, default: false }
            , options:       { type: [ Array, Object ], default: function () {return [];} }
            , autofocus:     { type: Boolean, default: false }
            , stacked:       { type: Boolean, default: false }
            , plain:         { type: Boolean, default: false }
            , buttons:       { type: Boolean, default: false }
            , buttonVariant: { type: String, default: 'outline-secondary' }
            , valueField:    { type: String, default: 'id' }
            , textField:     { type: String, default: 'name' }
            , itemKeyField:  { type: String, required: true }
            , itemIdField:   { type: String, required: true }
            , disabledField: { type: String, default: 'disabled' }
            , switches:      { type: Boolean, default: false }
        }
        , data:     function () {
            return {
                localState: null
            };
        }
        , computed: {
            displayLabel:    function () {
                let label = this.label ? `${ this.label }${ this.required ? '*' : '' }:` : '';
                return ( label );
            }
            , displayNameId: function () {
                return `${ this.name }-${ this._uid }-id`;
            }
            , computedValue: function () {
                return ( this.value || [] ).map( ( item ) => item[ this.itemKeyField ] );
            }
        }
        , methods:  {
            onChange: function ( event ) {
                let data = event.map( ( item ) => ( {
                    [ this.itemIdField ]:  this.itemId,
                    [ this.itemKeyField ]: item
                } ) );
                this.$emit( 'update', data )
            }
        }
    }
</script>

<style scoped>

</style>
