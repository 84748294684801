/**
 * Created by User on 10.11.2020.
 */

'use strict';
import qs from 'qs';
import storage from "../storage.js";
import { getFetchInit } from "../../shared/helper.js";

export default class ReportController {
    constructor( config ) {
    }

    /**
     * Перечень годов, для которых есть отчеты
     * @return {Promise<{year: number}[]>}
     */
    static async getReportYears() {
        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'get' );

        // let params   = qs.stringify( filter );
        let response = await fetch( `${ apiUrl }/report/getReportYears`, initOptions );

        if ( response.status !== 200 ) {
            storage.logNetworkError( response );
            throw new Error( 'Ошибка получения данных' );
        }

        let json = await response.json();
        return ( json );
    }

    /**
     * количество публикаций в web of science
     * @param {number} year
     * @param {Object} filter
     * @param {string} filter.department_id
     * @param {string} filter.member_type_id
     * @returns {Promise<{count: number}>}
     */
    static async getWebOfScienceCount( year, filter = {} ) {
        if ( !year ) { throw new Error( `Не задан год` ); }

        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'get' );

        let params = qs.stringify( filter );
        let response = await fetch( `${ apiUrl }/report/getWebOfScienceCount/${ year }?${ params }`, initOptions );

        if ( response.status !== 200 ) {
            storage.logNetworkError( response );
            throw new Error( 'Ошибка получения данных' );
        }

        let json = await response.json();
        return ( json );
    }

    /**
     * колчиество публикаций в  scopus
     * @param {number} year
     * @param {Object} filter
     * @param {string} filter.department_id
     * @param {string} filter.member_type_id
     * @returns {Promise<{count: number}>}
     */
    static async getScopusCount( year, filter = {} ) {
        if ( !year ) { throw new Error( `Не задан год` ); }

        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'get' );

        let params = qs.stringify( filter );
        let response = await fetch( `${ apiUrl }/report/getScopusCount/${ year }?${ params }`, initOptions );

        if ( response.status !== 200 ) {
            storage.logNetworkError( response );
            throw new Error( 'Ошибка получения данных' );
        }

        let json = await response.json();
        return ( json );
    }

    /**
     * Цитируемость в Web of Science и цитиемость в Scopus;
     * Сумма по данным в колонке «Общая цитируемость» в «Цитируемость» группы «Опубликованные произведения»
     * @param {number} year
     * @param {Object} filter
     * @param {string} filter.department_id
     * @param {string} filter.member_type_id
     * @returns {Promise<{sum_web_of_science: number, sum_scopus: number}>}
     */
    static async getSumWebOfScienceAndScopus( year, filter = {} ) {
        if ( !year ) { throw new Error( `Не задан год` ); }

        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'get' );

        let params = qs.stringify( filter );
        let response = await fetch( `${ apiUrl }/report/getSumWebOfScienceAndScopus/${ year }?${ params }`, initOptions );

        if ( response.status !== 200 ) {
            storage.logNetworkError( response );
            throw new Error( 'Ошибка получения данных' );
        }

        let json = await response.json();
        return ( json );
    }

    /**
     * Индекс Хирша максимальный по Web of Science, Scopus
     * Максимум по данным в колонке «Индекс Хирша» в «Цитируемость» группы «Опубликованные произведения»
     * @param {number} year
     * @param {Object} filter
     * @param {string} filter.department_id
     * @param {string} filter.member_type_id
     * @returns {Promise<{year: number, employee_id: string, fio: string, max_web_of_science: number, max_web_of_science_index_hirsch: number, max_scopus: number, max_scopus_index_hirsch: number}[]>}
     */
    static async getStatistic( year, filter = {} ) {
        if ( !year ) { throw new Error( `Не задан год` ); }

        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'get' );

        let params = qs.stringify( filter );
        let response = await fetch( `${ apiUrl }/report/getStatistic/${ year }?${ params }`, initOptions );

        if ( response.status !== 200 ) {
            storage.logNetworkError( response );
            throw new Error( 'Ошибка получения данных' );
        }

        let json = await response.json();
        return ( json );
    }

    /**
     * @typedef {Object} ReportStatus
     * @property {string} id - employee id
     * @property {boolean} is_dead - employee is_dead
     * @property {number} year - science report id
     * @property {string} fio - employee lastname + firstname + middlename
     * @property {string} department_id - department id
     * @property {string} department_name - department name
     * @property {string} member_type_id - member_type id
     * @property {string} member_type_name - member_type name
     * @property {string} science_report_id - science_report id
     * @property {string} status - enum ('Нет отчета', 'Зафиксирован', 'Отчет в работе')
     */

    /**
     * @param {number} year
     * @param {Object} filter
     * @return {Promise<ReportStatus[]>}
     */
    static async getReportStatus( year, filter = {} ) {
        if ( !year ) { throw new Error( `Не задан год` ); }

        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'get' );

        let params = qs.stringify( filter );
        let response = await fetch( `${ apiUrl }/report/getReportStatus/${ year }?${ params }`, initOptions );

        if ( response.status !== 200 ) {
            storage.logNetworkError( response );
            throw new Error( 'Ошибка получения данных' );
        }

        let json = await response.json();
        return ( json );
    }

    /**
     * @param {number} year - год отчета
     * @param {string} employeeId - id сотрудника
     * @return {Promise<ReportStatus>}
     */
    static async getReportStatusById( year, employeeId ) {
        if ( !year ) { throw new Error( `Не задан год` ); }
        if ( !employeeId ) { throw new Error( `Не задан id сотрудника` ); }

        let json = await ReportController.getReportStatus( year, { employee_id: employeeId } );
        return json.length && json[ 0 ];
    }

    /**
     *
     * @return {Promise<Schema[]>}
     */
    static async getOlapSchemeData( schemaId, filter ) {
        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'get' );

        let params = qs.stringify( filter );
        let response = await fetch( `${ apiUrl }/olap/${ schemaId }?${ params }`, initOptions );

        if ( response.status !== 200 ) {
            storage.logNetworkError( response );
            throw new Error( 'Ошибка получения данных' );
        }

        let json = await response.json();
        return ( json );
    }

    /**
     *
     * @param {string} report_id
     * @returns {string}
     */
    static getEmployeeDocxReportLink( report_id ) {
        if ( !report_id ) { return ( '' ); }
        let apiUrl = storage.state.apiUrl;
        return ( `${ apiUrl }/scienceReport/${ report_id }/report` );
    }

    /**
     *
     * @param {string} department_id
     * @param {number} year
     * @return {string}
     */
    static getDepartmentReportLink( department_id, year ) {
        return '';
        // if ( !report_id ) { return ( '' ); }
        // let apiUrl = storage.state.apiUrl;
        // return ( `${ apiUrl }/scienceReport/${ report_id }/reportDepartment` );
    }
}