/**
 * Created by User on 12.10.2019.
 */
'use strict';
import IEntity from "./i-entity.js";


export default class ParticipationExpertCouncilVak extends IEntity {
    /**
     * @typedef {IEntityConfig} ParticipationExpertCouncilVakConfig
     * @property {string} [science_report_id]
     * @property {string} [expert_council_vak_id]
     */

    /**
     *
     * @param {ParticipationExpertCouncilVakConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.science_report_id     = config.science_report_id;
        this.expert_council_vak_id = config.expert_council_vak_id;
    }
}
