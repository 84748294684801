<template>
    <div>
        <b-table ref="tbl"
                 :id="tableId"
                 striped
                 hover
                 :no-provider-sorting="false"
                 :no-provider-paging="false"
                 :no-provider-filtering="false"
                 show-empty
                 empty-text="Нет данных"
                 caption-top
                 :per-page="localPerPage"
                 :current-page="localCurrentPage"
                 :busy.sync="isBusy"
                 :fields="fields"
                 :selectable="isSelectItem"
                 :select-mode="selectMode"
                 primary-key="id"
                 v-model="items"
                 :items="getItems">
            <template v-slot:table-caption>
                <h5>{{ schema.name }} ({{totalCount}})</h5>
                <slot name="top-header" v-bind:items="items">
                </slot>
                <b-row>
                    <b-col>
                        <div v-if="isAddItem">
                            <b-button variant="outline-secondary" @click="itemCard({}, true)">Добавить</b-button>
                        </div>
                        <div v-if="exportWord">
                            <b-button variant="outline-secondary"
                                      target="_blank"
                                      :href="getExportWordLink()">Экспорт
                            </b-button>
                        </div>
                    </b-col>
                    <b-col>
                        <div class="overflow-auto" v-if="localPerPage!==0">
                            <b-pagination
                                    align="right"
                                    v-model="localCurrentPage"
                                    :total-rows="totalCount"
                                    :per-page="localPerPage"
                                    :aria-controls="tableId"></b-pagination>
                        </div>
                    </b-col>
                </b-row>
            </template>
            <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Идет загрузка данных...</strong>
                </div>
            </template>
            <template v-slot:head(_view)="data">
                <font-awesome-icon icon="eye"/>
            </template>
            <template v-slot:cell(_view)="row">
                <b-button size="sm" variant="outline-secondary" @click="itemCard(row.item, false)">
                    <font-awesome-icon icon="eye"/>
                </b-button>
            </template>
            <template v-slot:head(_edit)="data">
                <font-awesome-icon icon="pencil-alt"/>
            </template>
            <template v-slot:cell(_edit)="row">
                <b-button size="sm" variant="outline-secondary" @click="itemCard(row.item, true)">
                    <font-awesome-icon icon="pencil-alt"/>
                </b-button>
            </template>
            <template v-slot:head(_delete)="data">
                <font-awesome-icon icon="trash-alt"/>
            </template>
            <template v-slot:cell(_delete)="row">
                <b-button size="sm" variant="outline-secondary" @click="deleteItem(row.item.id)">
                    <font-awesome-icon icon="trash-alt"/>
                </b-button>
            </template>
            <template v-slot:row-details="row">
            </template>
        </b-table>
        <b-modal ref="modal"
                 hide-footer
                 :scrollable="false"
                 size="xl">
            <slot name="default" v-bind="modal">
                <ItemCard :model="model"
                          :item="modal.item"
                          @update="update"
                          :is-edit="modal.isEdit"/>
            </slot>
        </b-modal>
    </div>
</template>

<script>
    import storage from '../storage';
    import { GeneralController } from '../controllers'
    import { SchemaFieldTypeEnum } from '../../shared/enums';

    import ItemCard from './ItemCard.vue';

    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faEye, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    library.add( faEye, faPencilAlt, faTrashAlt );

    // @addItem, @editItem, @deleteItem

    export default {
        name: "ItemList"
        , components: { FontAwesomeIcon, ItemCard }
        , props: {
            model: { type: String, required: true }
            , filter: { type: Object }
            , isAddItem: { type: Boolean, default: false }
            , isViewItem: { type: Boolean, default: false }
            , isEditItem: { type: Boolean, default: false }
            , isDeleteItem: { type: Boolean, default: false }
            //selectable
            , isSelectItem: { type: Boolean, default: false }
            // single, multi, range
            , selectMode: { type: String, default: 'single' }
            , selected: { type: Array, default: function () {return ( [] );} }
            , perPage: { type: [ String, Number ] }
            , currentPage: { type: [ String, Number ] }
            , defaultItem: { type: Object, default: function () {return {}; } }
            , exportWord: { type: Boolean, default: false }
        }
        , data: function () {
            return {
                schemaManager: storage.state.schemaManager,
                isBusy: false,
                items: [],
                totalCount: 0,
                localPerPage: this.perPage || storage.state.schemaManager.getSchemaById( this.model ).pagination.default,
                localCurrentPage: this.currentPage || 1,
                modal: {
                    item: null
                    , isEdit: false
                    , update: this.update
                }
            };
        }
        , computed: {
            schema: function () { return this.schemaManager.getSchemaById( this.model ); }
            , tableId: function () { return ( `tbl_${ this.model }_id` );}
            , fields: function () {
                let items = [];
                if ( this.isViewItem ) {
                    items.push( {
                        key: '_view'
                        , label: 'Просмотр'
                        , sortable: false
                        , class: 'text-center'
                        , thStyle: { width: '25px' }
                    } );
                }

                if ( this.isEditItem ) {
                    items.push( {
                        key: '_edit'
                        , label: 'Редактировать'
                        , sortable: false
                        , class: 'text-center'
                        , thStyle: { width: '25px' }
                    } );

                }

                // add custom
                this.schema.fields
                    .filter( ( item ) => item.isList )
                    .forEach( ( item ) => {
                        let formatter, css;
                        // console.log( item.type, item.systemName );
                        switch ( item.type ) {
                            case SchemaFieldTypeEnum.Boolean:
                                formatter = item.formatter || 'booleanFormatter';
                                css = item.css || 'text-center';
                                break;
                            case SchemaFieldTypeEnum.Date:
                                formatter = 'dateFormatter';
                                css = item.css || 'text-center';
                                break;
                            case SchemaFieldTypeEnum.Lookup:
                                formatter = 'lookupFormatter';
                                css = item.css || 'text-left';
                                break;
                            case SchemaFieldTypeEnum.Select:
                                formatter = 'lookupFormatter';
                                css = item.css || 'text-center';
                                break;
                            default:
                                css = item.css || 'text-left';
                                break;
                        }

                        items.push( {
                            key: item.systemName
                            , label: item.shortName || item.name
                            // , headerTitle: item.description
                            // , headerAbbr: item.shortName
                            , sortable: item.isSort
                            , class: css
                            , formatter: formatter
                            // , thStyle:  { width: '25px' }
                            // , tdClass: {}
                        } );
                    } );

                if ( this.isDeleteItem ) {
                    items.push( {
                        key: '_delete'
                        , label: 'Удалить'
                        , sortable: false
                        , class: 'text-center'
                        , thStyle: { width: '25px' }
                    } );

                }

                return ( items );
            }
        }
        , watch: {
            filter() {
                this.refresh();
            }
        }
        , methods: {
            refresh() { this.$refs.tbl.refresh(); }
            /**
             *
             * @param {{currentPage: number, perPage: number, filter: any, sortBy: string, sortDesc: boolean, apiUrl: string}} ctx
             * @return {Promise<Array>}
             */
            , getItems: async function ( ctx ) {
                let sorts = [];
                if ( ctx.sortBy ) { sorts.push( { sortBy: ctx.sortBy, sortDesc: ctx.sortDesc } ) }

                let filter = Object.assign( {}, this.filter );

                return GeneralController.getItems( this.model, {
                    sorts
                    , filter
                    , complete: true
                    , perPage: ctx.perPage
                    , currentPage: ctx.currentPage
                } )
                    .then( ( result ) => {
                        this.totalCount = result.count;
                        return ( result.rows );
                    } ).catch( ( error ) => {
                        storage.logNetworkError( error );
                        return ( [] );
                    } );
            }
            , getExportWordLink() {
                let sorts = [];
                // if ( ctx.sortBy ) { sorts.push( { sortBy: ctx.sortBy, sortDesc: ctx.sortDesc } ) }
                let filter = Object.assign( {}, this.filter );

                return GeneralController.getExportWordLink( this.model, { sorts, filter } );
            }
            , itemCard( item, isEdit = false ) {
                if ( !item || !item.id ) { item = Object.assign( {}, this.defaultItem ); }

                this.modal.item = item;
                this.modal.isEdit = isEdit;
                this.$refs.modal.show();
            }
            , deleteItem( itemId ) {
                //ToDo: добавить подтверждение на удаление, еще можно сделать чтоб удаление было только логическим
                GeneralController.removeItemById( this.model, itemId )
                    .then( () => {
                        this.refresh();
                    } )
                    .catch( ( error ) => {
                        storage.logNetworkError( error );
                    } )
            }

            , update( item ) {
                let elm = this.items.find( ( elm ) => elm.id === item.id );
                if ( !elm ) { return this.refresh(); }

                // ToDo: проверить копируется ли что то лишнее
                Object.keys( item ).forEach( ( key ) => {
                    elm[ key ] = item[ key ];
                } );
            }

            , dateFormatter( value ) {
                let date = new Date( value );
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                month = month.toString().padStart( 2, '0' );
                let day = date.getDate();
                day = day.toString().padStart( 2, '0' );
                return ( `${ day }.${ month }.${ year }` );
            }
            , booleanFormatter( value ) {
                return ( value ? 'Да' : 'Нет' );
            }
            , booleanPlusMinusFormatter( value ) {
                return ( value ? '+' : '-' );
            }

            , lookupFormatter( value, key, item ) {
                let elm = this.schema.fields.find( ( elm ) => elm.systemName === key );
                if ( !elm ) { return value; }
                return item[ elm.lookupSystemName ] ? item[ elm.lookupSystemName ][ elm.lookupName ] : value;
            }
        }
    }
</script>

<style scoped>

</style>
