/**
 * Created by User on 13.10.2019.
 */
'use strict';

import IEntity from "./i-entity.js";


export default class ParticipationScientificPracticalActivity extends IEntity {
    /**
     * @typedef {IEntityConfig} ParticipationScientificPracticalActivityConfig
     * @property {string} [science_report_id]
     * @property {string} [name]
     * @property {Date} [start_date]
     * @property {string} [country]
     * @property {string} [city]
     * @property {string} [participation_form]
     * @property {boolean} [is_international]
     */

    /**
     *
     * @param {ParticipationScientificPracticalActivityConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.science_report_id  = config.science_report_id;
        this.name               = config.name;
        this.start_date         = config.start_date && new Date( config.start_date );
        this.country            = config.country;
        this.city               = config.city;
        this.participation_form = config.participation_form;
        this.is_international   = !!config.is_international;
    }
}
