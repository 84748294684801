/**
 * Created by User on 30.09.2019.
 */
'use strict';

import storage from './storage';


export const ifNotAuthenticated = ( to, from, next ) => {
    if ( !storage.authorized() ) { return next(); }
    next( { name: 'home' } );
};

export const ifAuthenticated = ( to, from, next ) => {
    if ( storage.authorized() ) { return next(); }
    next( { name: 'login' } );
};

export const appInit = ( to, from, next ) => {
    if ( storage.state.isInitApp ) { return next(); }

    storage.initApp()
        .then( () => { next(); } )
        .catch( next );
};


export const ifAdmin = ( to, from, next ) => {
    if ( storage.state.session.isAdmin() ) { return next(); }
    next( { name: 'permission' } )
};

export const ifMember = ( to, from, next ) => {
    if ( storage.state.session.isMember() ) { return next(); }
    next( { name: 'permission' } )
};

export const ifAnalytic = ( to, from, next ) => {
    if ( storage.state.session.isAnalytic() ) { return next(); }
    next( { name: 'permission' } )
};
