<template>
    <b-card :no-body="false" :title="fgosNameSchema.singleName">
        <b-form ref="form" @submit.stop.prevent="debounceSave" novalidate>
            <b-alert v-model="alert.show" fade>{{ alert.message }}</b-alert>
            <b-card-header v-if="isEdit">
                <b-button-group>
                    <b-button type="submit" variant="outline-secondary">Сохранить</b-button>
                    <b-button variant="outline-secondary" @click="cancel()">Отменить</b-button>
                </b-button-group>
            </b-card-header>
            <b-tabs card>
                <ItemCardTab ref="fgosNameTab"
                             :title="fgosNameSchema.singleName"
                             :schema-id="fgosNameSchema.id"
                             :fields="['name', 'fgos_type_id', 'doc_link', 'is_end']"
                             :dictionaries="dictionaries"
                             :item="localFgosName"
                             :label-cols="labelCols"
                             :label-align="labelAlign"
                             :is-edit="isEdit"/>
                <b-tab title="Результат">
                    <b-card-body>
                        <FgosExpertiseResult :fgos-name-id="fgosName.id"/>
                    </b-card-body>
                </b-tab>
                <b-tab title="Эксперты">
                    <b-card-body>
                        <FgosExpertList :fgos-name-id="fgosName.id"/>
                    </b-card-body>
                </b-tab>
            </b-tabs>
        </b-form>
    </b-card>
</template>

<script>
    import storage from '../storage.js';
    import { FgosName } from '../../shared/models/index.js';
    import ItemCardTab from "../components/ItemCardTab";
    import { GeneralController } from '../controllers/index.js';
    import FgosExpertiseResult from "./FgosExpertiseResult";
    import FgosExpertList from "./FgosExpertList";


    export default {
        name:       "FgosNameCard",
        components: { FgosExpertiseResult, ItemCardTab, FgosExpertList },
        model:      {
            prop:    'fgosName'
            , event: 'update'
        },
        props:      {
            fgosName:     { type: Object, default: function () { return new FgosName() } }
            , isEdit:     { type: Boolean, default: false }
            , labelCols:  { type: [ String, Number ], default: 3 }
            , labelAlign: { type: String, default: 'right' }
        }
        , data:     function () {
            return {
                fgosNameSchema:      storage.state.schemaManager.getSchemaById( 'fgosName' ),
                fgosExpertiseSchema: storage.state.schemaManager.getSchemaById( 'fgosExpertise' ),
                localFgosName:       this.getItem( this.fgosName ),
                dictionaries:        { answer: [], simpleAnswer: [] },
                expertiseTotal:      {},
                experts:             [],
                alert:               {
                    message: '',
                    show:    false
                }
            };
        },
        methods:    {
            getItem( value ) { return new FgosName( value ) },
            touch() { this.$refs.fgosNameTab.touch(); },
            reset() { this.$refs.fgosNameTab.reset(); },
            invalid() { return this.$refs.fgosNameTab && this.$refs.fgosNameTab.invalid; },
            pending() { return this.$refs.fgosNameTab && this.$refs.fgosNameTab.pending; },
            checkValidity() { return this.$refs.fgosNameTab.checkValidity(); },
            debounceSave: function ( e ) {
                this.touch();
                if ( !this.pending() ) { return this.save( e ); }

                let intervalId;
                intervalId = setInterval( () => {
                    if ( !this.pending() ) {
                        this.save( e );
                        clearInterval( intervalId );
                    }
                }, storage.debouncers.default )
            },
            save( e ) {
                this.show( 'Проверяем данные...', true );
                // this.touch();
                if ( !this.checkValidity() ) {
                    return this.show( 'Поправьте данные...', true );
                }

                this.show( 'Идет сохранение...', true );
                let promise;
                if ( this.localFgosName.id ) {
                    promise = GeneralController.updateItemById( this.fgosNameSchema.id, this.localFgosName.id, this.localFgosName );
                } else {
                    promise = GeneralController.addItem( this.fgosNameSchema.id, this.localFgosName );
                }

                promise
                    .then( ( json ) => {
                        this.localFgosName = json;
                        this.$emit( 'update', json );
                        this.show( 'Запись сохранена', 5 );
                    } )
                    .catch( ( error ) => {
                        storage.logNetworkError( error );
                        this.show( 'Ошибка сохранения на сервере', 5 );
                    } );
            },
            cancel() {
                this.localFgosName = this.getItem( this.fgosName );
                this.reset()
            },
            show( message, timeSec = 5 ) {
                this.alert.message = message;
                this.alert.show    = timeSec || true;
            },
            hide() { this.alert.show = false; }
        }
    }
</script>

<style scoped>

</style>
