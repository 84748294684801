/**
 * Created by User on 01.10.2019.
 */
'use strict';
import storage from '../storage';
import qs from 'qs';
import { getFetchInit } from '../../shared/helper';

/**
 * getItems - функция для получения данных
 * getItemById - функция для полуения одной записи
 * updateItemById - обновляет одну запись по идентификатору
 * addItem - добавляет новый элемент
 * removeItemById - удалить один элемент
 * checkUniqueField
 * checkId
 * getLikeField
 * getFgosExperts
 * getEmployeeMemberTypeCount
 */
export default class GeneralController {
    constructor( config ) { }

    /**
     *
     * @param model
     * @param {{fields: string[], sorts: { sortBy: string, sortDesc: boolean }[], filter: Object|Array, currentPage: number, perPage: number}} [options]
     * @param {boolean} [isCache=false]
     * @return {Promise<Object[]>}
     */
    static async getItems( model, options = {}, isCache = false ) {
        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'get' );
        if ( isCache ) { initOptions.cache = 'force-cache' }

        let params = qs.stringify( options );
        let response = await fetch( `${ apiUrl }/${ model }?${ params }`, initOptions );

        let json = await response.json();

        return ( json );
    }

    /**
     *
     * @param {string} model
     * @param {string} itemId
     * @param {boolean} [isCache=false]
     * @return {Promise<Object>}
     */
    static async getItemById( model, itemId, isCache = false ) {
        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'get' );
        if ( isCache ) { initOptions.cache = 'force-cache' }

        let response = await fetch( `${ apiUrl }/${ model }/${ itemId }`, initOptions );

        let json = await response.json();

        return ( json );
    }

    /**
     *
     * @param {string} model
     * @param {string} itemId
     * @param {Object} item
     * @return {Promise<Object>}
     */
    static async updateItemById( model, itemId, item ) {
        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'put', item );

        let response = await fetch( `${ apiUrl }/${ model }/${ itemId }`, initOptions );
        switch ( response.status ) {
            case 500:
                // console.log( response.statusText );
                throw new Error( 'Ошибка сохранения...' );
            case 403:

                throw new Error( 'У вас нет прав доступа...' );
        }

        let json = await response.json();
        return ( json );
    }

    /**
     *
     * @param {string} model
     * @param {Object} item
     * @return {Promise<Object>}
     */
    static async addItem( model, item ) {
        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'post', item );

        let response = await fetch( `${ apiUrl }/${ model }`, initOptions );

        // console.log( response.statusText );
        let json = await response.json();
        // console.log( json.message, json.detail );

        switch ( response.status ) {
            case 500:
                // console.log( response.statusText );
                throw new Error( 'Ошибка сохранения...' );
            // throw new Error( json.detail );
            case 403:
                throw new Error( 'У вас нет прав доступа...' );
        }

        return ( json );
    }

    /**
     *
     * @param {string} model
     * @param {string} itemId
     * @return {Promise<Object>}
     */
    static async removeItemById( model, itemId ) {
        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'delete' );

        let response = await fetch( `${ apiUrl }/${ model }/${ itemId }`, initOptions );

        // console.log( response.statusText );
        let json = await response.json();

        // console.log( json.message );
        switch ( response.status ) {
            case 500:
                // console.log( response.statusText );
                throw new Error( 'Ошибка операции...' );
            case 403:
                throw new Error( 'У вас нет прав доступа...' );
        }

        return ( json );
    }

    /**
     *
     * @param {string} model
     * @param {object} [filter]
     * @return {Promise<boolean>} true - уникально, false - не уникально
     */
    static async checkUniqueField( model, filter ) {
        let json = await this.getItems( model, { filter } );
        return ( json.rows.length === 0 );
    }

    /**
     *
     * @param {string} model
     * @param {string} field - поле в бд
     * @param {string} value - значение на уникальность
     * @return {Promise<Object[]>}
     */
    static async getLikeField( model, field, value ) {
        let filter = { [ field ]: { $sLike: value } };
        let json = await this.getItems( model, { filter } );
        return ( json.rows );
    }

    /**
     *
     * @param {string} model
     * @param {string} itemId - поле в бд
     * @return {Promise<boolean>}
     */
    static async checkId( model, itemId ) {
        let filter = { id: itemId };
        let json = await this.getItems( model, { filter } );
        return ( json.rows.length === 1 );
    }

    /**
     *
     * @param {string} fgos_name_id
     * @return {Promise<any>}
     */
    static async getExpertiseTotal( fgos_name_id ) {
        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'get' );
        let params = qs.stringify( { fgos_name_id } );
        // console.log(params);
        let response = await fetch( `${ apiUrl }/fgos/getExpertiseTotal?${ params }`, initOptions );

        // console.log( response.statusText );
        let json = await response.json();

        // console.log( json.message );
        switch ( response.status ) {
            case 500:
                // console.log( response.statusText );
                throw new Error( 'Ошибка операции...' );
            case 403:
                throw new Error( 'У вас нет прав доступа...' );
        }

        return ( json );
    }

    /**
     *
     * @param {string} fgos_name_id
     * @return {Promise<{id: string, lastname: string, firstname: string, middlename: string, department_name: string}[]>}
     */
    static async getFgosExperts( fgos_name_id ) {
        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'get' );
        let params = qs.stringify( { fgos_name_id } );
        // console.log(params);
        let response = await fetch( `${ apiUrl }/fgos/getFgosExperts?${ params }`, initOptions );

        // console.log( response.statusText );
        let json = await response.json();

        // console.log( json.message );
        switch ( response.status ) {
            case 500:
                // console.log( response.statusText );
                throw new Error( 'Ошибка операции...' );
            case 403:
                throw new Error( 'У вас нет прав доступа...' );
        }

        return ( json );
    }

    /**
     *
     * @param {string[]} [options=[]] - перечень id департаментов РАО
     * @param {boolean} [is_dead] - undefined - все значения, false - со значением false, true - со значением true
     * @return {Promise<Object>}
     */
    static async getEmployeeMemberTypeCount( options = [], is_dead ) {
        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'get' );
        let filter = {};
        if ( options.length ) { filter.department = options; }
        if ( is_dead !== undefined ) { filter.is_dead = is_dead }
        let params = Object.keys( filter ).length ? qs.stringify( { filter } ) : '';
        // console.log(params);
        let response = await fetch( `${ apiUrl }/employee/getEmployeeMemberTypeCount?${ params }`, initOptions );

        // console.log( response.statusText );
        let json = await response.json();

        // console.log( json.message );
        switch ( response.status ) {
            case 500:
                // console.log( response.statusText );
                throw new Error( 'Ошибка операции...' );
            case 403:
                throw new Error( 'У вас нет прав доступа...' );
        }
        return ( json );
    }

    /**
     *
     * @param model
     * @param {{fields: string[], sorts: { sortBy: string, sortDesc: boolean }[], filter: Object|Array}} [options]
     * @return {string}
     */
    static getExportWordLink( model, options = {} ) {
        let apiUrl = storage.state.apiUrl;

        let params = qs.stringify( options );

        return `${ apiUrl }/${ model }/getExportWord?${ params }`;
    }

    /**
     *
     * @param {string} employeeId - uuid employee id
     * @param {number} year -
     * @returns {Promise<Object>} - удаленный объект (отчет)
     */
    static async removeCascadeScienceReportByEmployeeId( employeeId, year ) {
        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'delete' );
        let response = await fetch( `${ apiUrl }/employee/removeCascadeScienceReport?employee_id=${ employeeId }&year=${ year }`, initOptions );

        // console.log( response.statusText );
        let json = await response.json();

        // console.log( json.message );
        switch ( response.status ) {
            case 500:
                // console.log( response.statusText );
                throw new Error( 'Ошибка операции...' );
            case 403:
                throw new Error( 'У вас нет прав доступа...' );
        }

        return ( json );
    }
}


