<template>
    <b-form-group :label="displayLabel"
                  :label-for="displayNameId"
                  :label-cols="labelCols"
                  :label-cols-sm="labelColsSm"
                  :label-cols-md="labelColsMd"
                  :label-cols-lg="labelColsLg"
                  :label-cols-xl="labelColsXl"
                  :label-align="labelAlign"
                  :label-align-sm="labelAlignSm"
                  :label-align-md="labelAlignMd"
                  :label-align-lg="labelAlignLg"
                  :label-align-xl="labelAlignXl"
                  :state="state"
                  :label-size="labelSize"
                  :description="description"
                  :valid-feedback="validFeedback"
                  :invalid-feedback="invalidFeedback">
        <b-form-checkbox ref="input" :id="displayNameId"
                         :name="name"
                         :state="state"
                         :checked="checked"
                         @change="onChange"
                         :required="required"
                         :size="size"
                         :plain="plain"
                         :autofocus="autofocus"
                         :stacked="stacked"
                         :button="buttons"
                         :buttonVariant="buttonVariant"
                         :indeterminate="indeterminate"
                         :value="value"
                         :unchecked-value="uncheckedValue"
                         :switch="switches"
                         :disabled="!isEdit"></b-form-checkbox>
    </b-form-group>
</template>

<script>
    export default {
        name:       "FieldBoolean"
        , model:    {
            prop:    'checked'
            , event: 'update'
        }
        , props:    {
            label:             { type: String, default: '' }
            , labelSize:       { type: String }
            , description:     { type: String }
            , disabled:        { type: Boolean }
            , isEdit:          { type: Boolean, default: false }
            , labelCols:       { type: [ Number, String, Boolean ] }
            , labelColsSm:     { type: [ Number, String, Boolean ] }
            , labelColsMd:     { type: [ Number, String, Boolean ] }
            , labelColsLg:     { type: [ Number, String, Boolean ] }
            , labelColsXl:     { type: [ Number, String, Boolean ] }
            , labelAlign:      { type: String }
            , labelAlignSm:    { type: String }
            , labelAlignMd:    { type: String }
            , labelAlignLg:    { type: String }
            , labelAlignXl:    { type: String }
            , validFeedback:   { type: String }
            , invalidFeedback: { type: String }
            //--
            , state:           { type: Boolean, default: null }

            , name:          { type: String, default: 'fgb' }
            , checked:       { type: [ String, Number, Object, Array, Boolean ] }
            , size:          { type: String }
            , required:      { type: Boolean, default: false }
            , autofocus:     { type: Boolean, default: false }
            , stacked:       { type: Boolean, default: false }
            , inline:        { type: Boolean, default: false }
            , plain:         { type: Boolean, default: false }
            , buttons:       { type: Boolean, default: false }
            , buttonVariant: { type: String, default: 'outline-secondary' }

            , value:          { type: [ Object, Boolean ], default: true }
            , uncheckedValue: { type: [ Object, Boolean ], default: false }

            , indeterminate: { type: Boolean, default: false }
            , switches:      { type: Boolean, default: false }
        }
        , data:     function () {
            return {
                localState: null
            };
        }
        , computed: {
            displayLabel:    function () {
                let label = this.label ? `${ this.label }${ this.required ? '*' : '' }:` : '';
                return ( label );
            }
            , displayNameId: function () {
                return `${ this.name }-${ this._uid }-id`;
            }
        }
        , methods:  {
            onChange: function ( event ) {
                this.$emit( 'update', event )
            }
        }
    }
</script>

<style scoped>

</style>
