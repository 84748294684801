<template>
    <b-card :no-body="false" :title="fgosExpertisechema.singleName">
        <b-form ref="form" @submit.stop.prevent="debounceSave" novalidate>
            <b-alert v-model="alert.show" fade>{{ alert.message }}</b-alert>
            <b-card-header v-if="isEdit">
                <b-button-group>
                    <b-button type="submit" variant="outline-secondary">Сохранить</b-button>
                    <b-button variant="outline-secondary" @click="cancel()">Отменить</b-button>
                </b-button-group>
            </b-card-header>
            <b-card-body>
                <FieldTextarea label="ФГОС"
                               :label-align="labelAlign"
                               :label-cols="labelCols"
                               required
                               v-model="item.name"
                               :is-edit="false"
                               :rows="1"
                               :max-rows="6"
                />
<!--                <FieldSelect label="Тип"-->
<!--                             :label-align="labelAlign"-->
<!--                             :label-cols="labelCols"-->
<!--                             required-->
<!--                             v-model="item.fgos_type_id"-->
<!--                             :is-edit="false"-->
<!--                             :options="dictionaries.fgosType"-->
<!--                />-->
                <b-link :href="item.doc_link" v-if="item.doc_link">Скачать документ</b-link>
            </b-card-body>
            <b-card-body>
                <b-table-simple striped fixed>
                    <b-thead>
                        <b-tr class="text-center">
                            <b-th>Позиция для экспертной оценки</b-th>
                            <b-th>Экспертная оценка</b-th>
                            <b-th>Примечание</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-th colspan="3">5.1. Представленный проект ФГОС в целом:</b-th>
                        </b-tr>
                        <b-tr>
                            <b-td>5.1.1) соответствует нормативным срокам освоения основных образовательных программ
                                данного
                                уровня образования; ступени
                            </b-td>
                            <b-td>
                                <FieldSelect v-model="fgosExpertise.field_511_id"
                                             :state="$v.fgosExpertise.field_511_id.$dirty  ? !$v.fgosExpertise.field_511_id.$error : null"
                                             :options="dictionaries.answer"
                                             :is-edit="isEdit"
                                />
                            </b-td>
                            <b-td>
                                <FieldTextarea v-model="fgosExpertise.field_511_note"
                                               :is-edit="isEdit"
                                               :rows="1"
                                               :max-rows="6"
                                />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>5.1.2) соответствует возрастным особенностям и возможностям обучающихся</b-td>
                            <b-td>
                                <FieldSelect v-model="fgosExpertise.field_512_id"
                                             :state="$v.fgosExpertise.field_512_id.$dirty  ? !$v.fgosExpertise.field_512_id.$error : null"
                                             :options="dictionaries.answer"
                                             :is-edit="isEdit"
                                />
                            </b-td>
                            <b-td>
                                <FieldTextarea v-model="fgosExpertise.field_512_note"
                                               :is-edit="isEdit"
                                               :rows="1"
                                               :max-rows="6"
                                />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>5.1.5) соответствует законодательству Российской Федерации</b-td>
                            <b-td>
                                <FieldSelect v-model="fgosExpertise.field_515_id"
                                             :state="$v.fgosExpertise.field_515_id.$dirty  ? !$v.fgosExpertise.field_515_id.$error : null"
                                             :options="dictionaries.simpleAnswer"
                                             :is-edit="isEdit"
                                />
                            </b-td>
                            <b-td>
                                <FieldTextarea v-model="fgosExpertise.field_515_note"
                                               :is-edit="isEdit"
                                               :rows="1"
                                               :max-rows="6"
                                />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>5.1.6) отвечает характеристикам современного образования и перспективным задачам
                                образования в Российской Федерации
                            </b-td>
                            <b-td>
                                <FieldSelect v-model="fgosExpertise.field_516_id"
                                             :state="$v.fgosExpertise.field_516_id.$dirty  ? !$v.fgosExpertise.field_516_id.$error : null"
                                             :options="dictionaries.answer"
                                             :is-edit="isEdit"
                                />
                            </b-td>
                            <b-td>
                                <FieldTextarea v-model="fgosExpertise.field_516_note"
                                               :is-edit="isEdit"
                                               :rows="1"
                                               :max-rows="6"
                                />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>5.1.7) может быть реализован в системе образования Российской Федерации</b-td>
                            <b-td>
                                <FieldSelect v-model="fgosExpertise.field_517_id"
                                             :state="$v.fgosExpertise.field_517_id.$dirty  ? !$v.fgosExpertise.field_517_id.$error : null"
                                             :options="dictionaries.answer"
                                             :is-edit="isEdit"
                                />
                            </b-td>
                            <b-td>
                                <FieldTextarea v-model="fgosExpertise.field_517_note"
                                               :is-edit="isEdit"
                                               :rows="1"
                                               :max-rows="6"
                                />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th colspan="3">5.2. Полно представлена информация о разработке представленного проекта
                                ФГОС:
                            </b-th>
                        </b-tr>
                        <b-tr>
                            <b-td>5.2.1) об организациях, которые приняли участие в разработке</b-td>
                            <b-td>
                                <FieldSelect v-model="fgosExpertise.field_521_id"
                                             :state="$v.fgosExpertise.field_521_id.$dirty  ? !$v.fgosExpertise.field_521_id.$error : null"
                                             :options="dictionaries.answer"
                                             :is-edit="isEdit"
                                />
                            </b-td>
                            <b-td>
                                <FieldTextarea v-model="fgosExpertise.field_521_note"
                                               :is-edit="isEdit"
                                               :rows="1"
                                               :max-rows="6"
                                />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>5.2.2) об организации и результатах обсуждения и апробации проекта и его отдельных
                                элементов
                            </b-td>
                            <b-td>
                                <FieldSelect v-model="fgosExpertise.field_522_id"
                                             :state="$v.fgosExpertise.field_522_id.$dirty  ? !$v.fgosExpertise.field_522_id.$error : null"
                                             :options="dictionaries.answer"
                                             :is-edit="isEdit"
                                />
                            </b-td>
                            <b-td>
                                <FieldTextarea v-model="fgosExpertise.field_522_note"
                                               :is-edit="isEdit"
                                               :rows="1"
                                               :max-rows="6"
                                />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>5.3.Текст представленного проекта ФГОС аккуратно оформлен, соответствует правилам
                                русской орфографии и пунктуации
                            </b-td>
                            <b-td>
                                <FieldSelect v-model="fgosExpertise.field_53_id"
                                             :state="$v.fgosExpertise.field_53_id.$dirty  ? !$v.fgosExpertise.field_53_id.$error : null"
                                             :options="dictionaries.answer"
                                             :is-edit="isEdit"
                                />
                            </b-td>
                            <b-td>
                                <FieldTextarea v-model="fgosExpertise.field_53_note"
                                               :is-edit="isEdit"
                                               :rows="1"
                                               :max-rows="6"
                                />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th colspan="3">5.4. Общее экспертное мнение о проекте:</b-th>
                        </b-tr>
                        <b-tr>
                            <b-td>5.4.1) может быть рекомендован к утверждению</b-td>
                            <b-td>
                                <FieldSelect v-model="fgosExpertise.field_541_id"
                                             :state="$v.fgosExpertise.field_541_id.$dirty  ? !$v.fgosExpertise.field_541_id.$error : null"
                                             :options="dictionaries.answer"
                                             :is-edit="isEdit"
                                />
                            </b-td>
                            <b-td>
                                <FieldTextarea v-model="fgosExpertise.field_541_note"
                                               :is-edit="isEdit"
                                               :rows="1"
                                               :max-rows="6"
                                />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>5.4.2) следует рекомендовать к отклонению</b-td>
                            <b-td>
                                <FieldSelect v-model="fgosExpertise.field_542_id"
                                             :state="$v.fgosExpertise.field_542_id.$dirty  ? !$v.fgosExpertise.field_542_id.$error : null"
                                             :options="dictionaries.answer"
                                             :is-edit="isEdit"
                                />
                            </b-td>
                            <b-td>
                                <FieldTextarea v-model="fgosExpertise.field_542_note"
                                               :is-edit="isEdit"
                                               :rows="1"
                                               :max-rows="6"
                                />
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>5.4.3) следует рекомендовать к доработке</b-td>
                            <b-td>
                                <FieldSelect v-model="fgosExpertise.field_543_id"
                                             :state="$v.fgosExpertise.field_543_id.$dirty  ? !$v.fgosExpertise.field_543_id.$error : null"
                                             :options="dictionaries.answer"
                                             :is-edit="isEdit"
                                />
                            </b-td>
                            <b-td>
                                <FieldTextarea v-model="fgosExpertise.field_543_note"
                                               :is-edit="isEdit"
                                               :rows="1"
                                               :max-rows="6"
                                />
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-card-body>
        </b-form>
    </b-card>
</template>

<script>
    import { FgosName, FgosExpertise } from '../../shared/models/index.js'
    import { GeneralController } from '../controllers/index.js'
    import storage from '../storage.js'
    import FieldSelect from "../components/FieldSelect";
    import FieldTextarea from "../components/FieldTextarea";
    import { required } from 'vuelidate/lib/validators'

    export default {
        name:        "FgosExpertiseCard"
        ,
        components:  { FieldTextarea, FieldSelect },
        props:       {
            isEdit:       { type: Boolean, default: false }
            , labelCols:  { type: [ String, Number ], default: 3 }
            , labelAlign: { type: String, default: 'right' }
            , item:       { type: Object, default: function () { return new FgosName(); } }
        }
        , data:      function () {
            return {
                fgosNameSchema:     storage.state.schemaManager.getSchemaById( 'fgosName' ),
                fgosExpertisechema: storage.state.schemaManager.getSchemaById( 'fgosExpertise' ),
                fgosExpertise:      new FgosExpertise( {
                    fgos_name_id: this.item.id,
                    employee_id:  storage.state.session.employee.id
                } ),
                dictionaries:       { fgosType: [], answer: [], simpleAnswer: [] },
                alert:              {
                    message: '',
                    show:    false
                }
            };
        },
        validations: {
            fgosExpertise: {
                field_511_id: { required },
                field_512_id: { required },
                field_515_id: { required },
                field_516_id: { required },
                field_517_id: { required },
                field_521_id: { required },
                field_522_id: { required },
                field_53_id:  { required },
                field_541_id: { required },
                field_542_id: { required },
                field_543_id: { required }
            }
        },
        methods:     {
            touch() {
                this.$v.fgosExpertise.$touch();
            }
            , reset() {
                this.$v.fgosExpertise.$reset();
            }
            , pending() { return this.$v.fgosExpertise.$pending; }
            , checkValidity() {
                return !( this.$v.fgosExpertise.$pending || this.$v.fgosExpertise.$anyError );
            },
            debounceSave( e ) {
                this.touch();
                if ( !this.pending() ) { return this.save( e ); }

                let intervalId;
                intervalId = setInterval( () => {
                    if ( !this.pending() ) {
                        this.save( e );
                        clearInterval( intervalId );
                    }
                }, storage.debouncers.default );
            },
            save() {
                this.show( 'Проверяем корректность данных', true );
                // this.touch();
                if ( !this.checkValidity() ) {
                    return this.show( 'Не корректно заполнены данные...', true );
                }

                this.show( 'Идет сохранение...', true );

                let promise;
                if ( !this.fgosExpertise.id ) {
                    promise = GeneralController.addItem( this.fgosExpertisechema.id, this.fgosExpertise );
                } else {
                    promise = GeneralController.updateItemById( this.fgosExpertisechema.id, this.fgosExpertise.id, this.fgosExpertise );
                }

                promise
                    .then( ( data ) => {
                        this.fgosExpertise = new FgosExpertise( data );
                        this.show( 'Данные сохранены', true );
                    } )
                    .catch( ( error ) => {
                        storage.logNetworkError( error );
                        this.show( 'Ошибка в работе сервера', true );
                    } );
            },
            cancel() {
                let fgosNameId = this.item.id || storage.emptyUID;
                this.setFgosExperties( fgosNameId )
                    .then( () => {
                        this.reset();
                        this.hide();
                    } );
            },
            show( message, timeSec = 5 ) {
                this.alert.message = message;
                this.alert.show    = timeSec
            },
            hide() { this.alert.show = false; },
            setFgosExperties( fgosNameId ) {
                return GeneralController.getItems( this.fgosExpertisechema.id, {
                    filter: {
                        fgos_name_id: fgosNameId,
                        employee_id:  storage.state.session.employee.id || storage.emptyUID
                    }
                } )
                    .then( ( data ) => {
                        if ( data ) {
                            if ( data.rows.length ) {
                                this.fgosExpertise = new FgosExpertise( data.rows[ 0 ] );
                            }
                        }
                        return ( data );
                    } )
                    .catch( ( error ) => {
                        storage.logNetworkError( error );
                        this.show( 'Ошибка получения данных от сервера', true );
                    } )
            }
        },
        created() {
            storage.getDictionary( 'fgosType' )
                .then( ( items ) => {
                    this.dictionaries.fgosType = items;
                } );

            storage.getDictionary( 'fgosExpertiseAnswer' )
                .then( ( items ) => {
                    this.dictionaries.answer       = items;
                    this.dictionaries.simpleAnswer = items.filter( ( item ) => item.is_simple );
                } );

            let fgosNameId = this.item.id || storage.emptyUID;
            this.setFgosExperties( fgosNameId );
        }
    }
</script>

<style scoped>

</style>
