/**
 * Created by User on 28.09.2019.
 */
'use strict';


export default class IEntity {
    /**
     * @typedef {Object} IEntityConfig
     * @property {string} [id] уникальный идентификатор
     * @property {string} [owner_id] - идентификатор кто создал запись
     * @property {string|Date} [created] - датасоздания записи
     * @property {string|Date} [modified] - дата последнего изменения
     * @property {boolean} [blocked=false] - заблокирована ли запись (нельзя удалять, изменять)
     * @property {boolean} [deleted=false] - удалена ли запись
     * @property {number} [sort_number] - Порядковый номер для сортировки
     *
     */

    /**
     *
     * @param {IEntityConfig} config
     */
    constructor( config = {} ) {
        /**
         * Уникальный идентифкатор записи
         * @type {string}
         */
        this.id = config.id;
        /**
         * идентификатор создавшего запись
         * @type {string}
         */
        this.owner_id = config.owner_id;
        /**
         * Дата создания записи
         * @type {Date}
         */
        this.created = config.created && new Date( config.created );
        /**
         * Дата последнего изменения записи
         * @type {Date}
         */
        this.modified = config.modified && new Date( config.modified );
        /**
         * Заблокирована ли заспись на удаление
         * @type {boolean}
         */
        this.blocked = !!config.blocked;
        /**
         * Удалена ли запись
         * @type {boolean}
         */
        this.deleted = !!config.deleted;
        /**
         * Порядковый номер для сортировки
         * @type {number}
         */
        this.sort_number = config.sort_number;

    }
}
