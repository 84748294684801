<template>
    <div>
        <b-table ref="tbl" striped hover
                 :id="tableId"
                 show-empty
                 empty-text="Нет данных"
                 caption-top
                 :busy.sync="isBusy"
                 :fields="fields"
                 :per-page="localPerPage"
                 :current-page="localCurrentPage"
                 primary-key="id"
                 v-model="items"
                 :items="getItems">
            <template v-slot:table-caption>
                <h5>Пользователи:</h5>
                <b-row>
                    <b-col>
                        <div v-if="isAddItem">
                            <b-button variant="outline-secondary" @click="modalItem({}, true)">Добавить</b-button>
                        </div>
                    </b-col>
                    <b-col>
                        <div class="overflow-auto" v-if="localPerPage!==0">
                            <b-pagination
                                    align="right"
                                    v-model="localCurrentPage"
                                    :total-rows="totalCount"
                                    :per-page="localPerPage"
                                    :aria-controls="tableId"></b-pagination>
                        </div>
                    </b-col>
                </b-row>
            </template>
            <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Загрузка данных...</strong>
                </div>
            </template>
            <template v-slot:head(_view)="data">
                <font-awesome-icon icon="eye"/>
            </template>
            <template v-slot:cell(_view)="row">
                <b-button size="sm" variant="outline-secondary" @click="modalItem(row.item, false)">
                    <font-awesome-icon icon="eye"/>
                </b-button>
            </template>
            <template v-slot:head(_edit)="data">
                <font-awesome-icon icon="pencil-alt"/>
            </template>
            <template v-slot:cell(_edit)="row">
                <b-button size="sm" variant="outline-secondary" @click="modalItem(row.item, true)">
                    <font-awesome-icon icon="pencil-alt"/>
                </b-button>
            </template>
            <template v-slot:head(_delete)="data">
                <font-awesome-icon icon="trash-alt"/>
            </template>
            <template v-slot:cell(_delete)="row">
                <b-button size="sm" variant="outline-secondary" @click="removeItem(row.item)">
                    <font-awesome-icon icon="trash-alt"/>
                </b-button>
            </template>
            <template v-slot:cell(roles)="data">
                <FieldGroupCheckbox :options="dictionaries['role']"
                                    item-id-field="user_id"
                                    item-key-field="role_id"
                                    v-model="data.item.roles"/>
            </template>
        </b-table>
        <b-modal ref="modal" size="lg" hide-footer title="Пользователь">
            <b-form @submit.stop.prevent="debounceSubmit" novalidate>
                <b-card no-body>
                    <b-alert :variant="alert.variant" v-model="alert.show">{{ alert.message }}</b-alert>
                    <b-card-header v-if="modal.isEdit">
                        <b-button-group>
                            <b-button type="submit" variant="outline-secondary" :disabled="$v.modal.$pending">
                                Сохранить
                            </b-button>
                            <b-button variant="outline-secondary">Отменить</b-button>
                        </b-button-group>
                    </b-card-header>
                    <b-tabs card>
                        <b-tab title="Учетная запись">
                            <b-card-body>
                                <FieldInput ref="login"
                                            label="Логин"
                                            :label-cols="labelCols"
                                            :label-align="labelAlign"
                                            v-model="modal.user.login"
                                            invalid-feedback="Должно быть уникальным"
                                            :state="$v.modal.user.login.$dirty  ? !$v.modal.user.login.$error : null"
                                            required
                                            :is-edit="modal.user.id ? false : modal.isEdit"/>
                                <FieldBoolean label="Заблокирован"
                                              :label-cols="labelCols"
                                              :label-align="labelAlign"
                                              v-model="modal.user.locked"
                                              :is-edit="modal.isEdit"/>
                                <field-group-checkbox label="Роли"
                                                      name="userRoles"
                                                      :label-cols="labelCols"
                                                      :label-align="labelAlign"
                                                      description=""
                                                      valid-feedback=""
                                                      invalid-feedback=""
                                                      :options="dictionaries['role']"
                                                      stacked
                                                      :itemId="modal.user.id"
                                                      item-id-field="user_id"
                                                      item-key-field="role_id"
                                                      v-model="modal.userRoles"
                                                      :isEdit="modal.isEdit"/>
                            </b-card-body>
                        </b-tab>
                        <b-tab title="Установить пароль">
                            <FieldInput label="Новый пароль"
                                        :label-cols="labelCols"
                                        :label-align="labelAlign"
                                        type="password"
                                        description="Сохраняется не пустой пароль"
                                        valid-feedback=""
                                        invalid-feedback=""
                                        :state="$v.modal.user.password.$dirty  ? !$v.modal.user.password.$error : null"
                                        v-model="modal.user.password"
                                        :is-edit="modal.isEdit"/>
                            <FieldInput label="Повторить пароль"
                                        :label-cols="labelCols"
                                        :label-align="labelAlign"
                                        type="password"
                                        description="Проверка корректности ввода пароля"
                                        :state="$v.modal.repeatPassword.$dirty  ? !$v.modal.repeatPassword.$error : null"
                                        valid-feedback=""
                                        invalid-feedback="Не совпадвает с паролем выше"
                                        v-model="modal.repeatPassword"
                                        :is-edit="modal.isEdit"/>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
    import storage from '../storage';
    import FieldGroupCheckbox from '../components/FieldGroupCheckbox.vue'
    import { UserController, GeneralController } from "../controllers";
    import { User, UserRole } from '../../shared/models';
    import { required, sameAs, requiredUnless, requiredIf, maxLength } from 'vuelidate/lib/validators'
    import { debounce } from "debounce";

    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faEye, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import FieldInput from "../components/FieldInput";
    import FieldBoolean from "../components/FieldBoolean";

    library.add( faEye, faPencilAlt, faTrashAlt );

    export default {
        name:          "UserList"
        , components:  { FieldInput, FieldBoolean, FontAwesomeIcon, FieldGroupCheckbox }
        , data:        function () {
            return {
                isBusy:             false
                , items:            []
                , dictionaries:     {}
                , localPerPage:     50
                , localCurrentPage: 1
                , totalCount:       null
                , isAddItem:        true
                , fields:           [
                    {
                        key:        '_view'
                        , label:    'Просмотр'
                        , sortable: false
                        , class:    'text-center'
                        , thStyle:  { width: '25px' }
                    }
                    , {
                        key:        '_edit'
                        , label:    'Редактировать'
                        , sortable: false
                        , class:    'text-center'
                        , thStyle:  { width: '25px' }
                    }
                    , {
                        key:        'login'
                        , label:    'Логин'
                        , sortable: false
                    }
                    , {
                        key:         'locked'
                        , label:     'Заблокирован'
                        , sortable:  false
                        , formatter: ( value, key, item ) => {
                            return value ? 'Да' : 'Нет'
                        }
                    }
                    , {
                        key:        'roles'
                        , label:    'Роли'
                        , sortable: false
                    }
                    // , {
                    //     key:        '_delete'
                    //     , label:    'Удалить'
                    //     , sortable: false
                    //     , class:    'text-center'
                    //     , thStyle:  { width: '25px' }
                    // }
                ]
                , labelCols:        3
                , labelAlign:       'right'
                , alert:            {
                    show:    false,
                    message: ''
                }
                , modal:            {
                    user:             new User()
                    , repeatPassword: ''
                    , userRoles:      []
                    , isEdit:         false
                }
            };
        }
        , validations: {
            modal: {
                user:             {
                    login:      {
                        required
                        , isUnique( value ) {
                            if ( value === '' ) { return false; }

                            if ( !this.$refs.login || !this.$refs.login.isEdit ) { return true; }
                            // this.modal.user.id, 'login', value
                            let filter = { login: value };
                            if ( !this.modal.user.id ) {
                                filter.id = { $neq: this.modal.user.id };
                            }
                            return ( GeneralController.checkUniqueField( 'user', filter ) );
                        }
                    }
                    , password: {
                        required: requiredIf( ( data ) => !data.id )
                    }
                }
                , repeatPassword: {
                    sameAs: sameAs( ( data ) => data.user.password )
                }
            }
        }
        , computed:    {
            tableId: function () { return `table-uid-${ this._uid }`; }
        }
        , methods:     {
            getItems:         function ( ctx ) {
                let sorts = [];
                if ( ctx.sortBy ) { sorts.push( { sortBy: ctx.sortBy, sortDesc: ctx.sortDesc } ) }

                let perPage     = ctx.perPage;
                let currentPage = ctx.currentPage;

                let fields = [ 'login', 'locked', 'roles' ];

                return UserController.getItems( {
                    sorts
                    , fields
                    , perPage
                    , currentPage
                } )
                    .then( ( items ) => {
                        this.totalCount = items.count;
                        return ( items.rows );
                    } )
                    .catch( ( error ) => {
                        storage.logNetworkError( error );
                        return ( [] );
                    } )
            }
            , modalItem( item, isEdit = false ) {
                this.$v.modal.$reset();
                this.modal.user           = new User( item || {} );
                this.modal.userRoles      = ( ( item && item.roles ) || [] ).map( ( elm ) => ( {
                    user_id: item.id,
                    role_id: elm.role_id
                } ) ) || [];
                this.modal.repeatPassword = '';
                this.modal.isEdit         = isEdit;
                this.hide();
                this.$refs.modal.show();
            }
            , removeItem( itemId ) {
                // itemId
            }
            , refresh() { this.$refs.tbl.refresh(); }
            , debounceSubmit: debounce( function ( e ) { this.submit( e ) }, storage.debouncers.default )
            , submit() {
                this.show( 'Проверка формы...', true );
                this.$v.modal.$touch();
                if ( this.$v.modal.$pending || this.$v.modal.$anyError ) {
                    return this.show( 'Не корректно заполнены данные...' );
                }

                this.show( 'Идет сохранение данных...', true );

                let user  = this.modal.user;
                let roles = this.modal.userRoles.map( ( item ) => ( { id: item.role_id } ) );

                let promise = user.id ? UserController.updateUser( user, roles ) : UserController.addUser( user, roles );
                promise
                    .then( ( item ) => {
                        this.show( 'Данные сохранены' );
                        // add - user, roles
                        if ( !user.id ) { return this.refresh(); }

                        // update locked, roles, password
                        let elm = this.items.find( ( elm ) => elm.id === item.id );
                        if ( !elm ) { return; }
                        elm.locked = item.locked;
                        elm.roles  = this.modal.userRoles;
                    } )
                    .catch( ( error ) => {
                        storage.logNetworkError( error );
                        this.show( 'Произошла ошибка...' );
                    } )
            }
            , show( message, timeSec = 5 ) {
                this.alert.message = message;
                this.alert.show    = timeSec;
            }
            , hide() { this.alert.show = false; }
        }
        , created:     function () {
            storage.getDictionary( 'role' )
                .then( ( items ) => {
                    this.dictionaries[ 'role' ] = items;
                } )
                .catch( ( error ) => {
                    storage.logNetworkError( error );
                    return ( [] );
                } )
        }
    }
</script>

<style scoped>

</style>
