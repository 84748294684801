<template>
    <b-form-group :label="displayLabel"
                  :label-for="displayNameId"
                  :label-cols="labelCols"
                  :label-cols-sm="labelColsSm"
                  :label-cols-md="labelColsMd"
                  :label-cols-lg="labelColsLg"
                  :label-cols-xl="labelColsXl"
                  :label-align="labelAlign"
                  :label-align-sm="labelAlignSm"
                  :label-align-md="labelAlignMd"
                  :label-align-lg="labelAlignLg"
                  :label-align-xl="labelAlignXl"
                  :state="state"
                  :label-size="labelSize"
                  :description="description"
                  :valid-feedback="validFeedback"
                  :invalid-feedback="invalidFeedback">
        <model-list-select :list="items"
                           option-value="id"
                           :is-error="state === false"
                           :custom-text="displayText"
                           :placeholder="placeholder"
                           v-model="selected"
                           @input="onUpdate"
                           @searchchange="debounceGetItems"/>
    </b-form-group>
</template>

<script>
    import { ModelListSelect } from 'vue-search-select';
    import { GeneralController } from '../controllers/index.js';
    import storage from '../storage.js';
    import { debounce } from "debounce";

    export default {
        name:         "FieldLookup"
        , components: { ModelListSelect }
        , model:      {
            prop:    'value'
            , event: 'update'
        }
        , props:      {
            label:          { type: String, default: '' }
            , labelSize:    { type: String }
            , type:         { type: String, default: 'text' }
            , placeholder:  { type: String }
            , trim:         { type: Boolean, default: false }
            , number:       { type: Boolean, default: false }
            , formatter:    { type: Function }
            , autocomplete: { type: String, default: 'off' }
            , plaintext:    { type: Boolean, default: false }
            , size:         { type: String }
            , autofocus:    { type: Boolean, default: false }
            // state
            , state:        { type: Boolean, default: null }

            , name:            { type: String, default: 'fgl' }
            , value:           { type: [ String, Number ] }
            , description:     { type: String }
            , disabled:        { type: Boolean }
            , isEdit:          { type: Boolean, default: false }
            , required:        { type: Boolean, default: false }
            , labelCols:       { type: [ Number, String, Boolean ] }
            , labelColsSm:     { type: [ Number, String, Boolean ] }
            , labelColsMd:     { type: [ Number, String, Boolean ] }
            , labelColsLg:     { type: [ Number, String, Boolean ] }
            , labelColsXl:     { type: [ Number, String, Boolean ] }
            , labelAlign:      { type: String }
            , labelAlignSm:    { type: String }
            , labelAlignMd:    { type: String }
            , labelAlignLg:    { type: String }
            , labelAlignXl:    { type: String }
            , validFeedback:   { type: String }
            , invalidFeedback: { type: String }

            //
            , isLoaded:           { type: [ Boolean, String ], default: true }
            , lookupSchemaId:     { type: String, required: true }
            , lookupSearchFields: { type: Array, required: true }
        }
        , data:       function () {
            return {
                items:      []
                , selected: {}
            };
        }
        , computed:   {
            displayLabel:    function () {
                let label = this.label ? `${ this.label }${ this.required ? '*' : '' }:` : '';
                return ( label );
            }
            , displayNameId: function () {
                return `${ this.name }-${ this._uid }-id`;
            }
        }
        , watch:      {
            value: function ( value, oldValue ) {
                this.getItem( value );
            }
        }
        , methods:    {
            onUpdate:           function ( event ) {
                this.$emit( 'update', event && event.id )
            }
            , displayText( item ) { return this.lookupSearchFields.map( ( name ) => item[ name ] ).join( ' - ' ); }
            , debounceGetItems: debounce( function ( e ) { this.getItems( e ) }, storage.debouncers.default )
            , getItems( e ) {
                if ( this.isLoaded ) { return; }

                this.lookupSearchFields.forEach( ( name ) => {
                    GeneralController.getLikeField( this.lookupSchemaId, name, e )
                        .then( ( items ) => {
                            items.forEach( ( item ) => {
                                if ( this.items.some( ( elm ) => elm.id === item.id ) ) { return; }
                                this.items.push( item );
                            } );
                        } )
                        .catch( ( error ) => {
                            storage.logNetworkError( error );
                        } );
                } );
            }
            , getItem( id ) {
                GeneralController.getItemById( this.lookupSchemaId, id )
                    .then( ( item ) => {
                        if ( this.items.some( ( elm ) => elm.id === item.id ) ) { this.items.push( item ); }
                        this.selected = item;
                    } )
                    .catch( ( error ) => {
                        storage.logNetworkError( error );
                    } );
            }
        }
        , created:    function () {
            if ( this.isLoaded ) {
                storage.getDictionary( this.lookupSchemaId )
                    .then( ( items ) => {
                        items.forEach( ( item ) => {
                            if ( this.items.some( ( elm ) => elm.id === item.id ) ) { return; }
                            this.items.push( item );
                        } );
                    } );
            }

            if ( !this.value ) { return; }
            this.getItem( this.value );
        }
    }
</script>

<style scoped>

</style>
