<template>
    <div>
        <b-row>
            <b-col cols="2">
                <b-form-select class="border-success text-success"
                               :options="years"
                               v-model="year"
                               @change="update"></b-form-select>
            </b-col>
            <b-col cols="5">
                <FieldSelect label="Членство в РАО"
                             label-cols="5"
                             label-align='right'
                             :options="memberTypes"
                             v-model="filter.member_type_id"
                             @change="update"
                             is-edit
                />
            </b-col>
            <b-col cols="5" v-if="isViewer">
                <FieldSelect label="Отделение"
                             label-cols="5"
                             label-align='right'
                             :options="departments"
                             v-model="filter.department_id"
                             @change="update"
                             is-edit
                />
            </b-col>
        </b-row>
        <b-alert :show="true" variant="light">
            <b-row>
                <b-col>Нет отчета: {{ getItemsByStatus('Нет отчета').length }}</b-col>
                <b-col>Зафиксирован: {{ getItemsByStatus('Зафиксирован').length }}</b-col>
                <b-col>Отчет в работе: {{ getItemsByStatus('Отчет в работе').length }}</b-col>
                <b-col></b-col>
            </b-row>
        </b-alert>
        <b-table striped hover
                 primary-key="id"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 :items="items" :fields="fields">
            <template v-slot:head(_view)>
                <font-awesome-icon icon="eye"/>
            </template>
            <template v-slot:cell(_view)="row">
                <b-button size="sm" variant="outline-secondary"
                          v-if="row.item.science_report_id"
                          @click="itemViewCard(row.item.science_report_id)">
                    <font-awesome-icon icon="eye"/>
                </b-button>
            </template>
        </b-table>
        <b-modal ref="modal"
                 hide-footer
                 :scrollable="false"
                 size="xl">
            <slot name="default" v-bind="modal">
                <ScienceReportCard :scienceReport="modal.item" :is-edit="true" @update="updateScienceReport"/>
            </slot>
        </b-modal>
    </div>
</template>

<script>
    import FieldSelect from "../components/FieldSelect.vue";
    import ScienceReportCard from './ScienceReportCard.vue';
    import storage from "../storage.js";
    import ReportController from '../controllers/report-controller.js'
    import GeneralController from "../controllers/general-controller.js";
    import { library } from "@fortawesome/fontawesome-svg-core";
    import { faEye } from "@fortawesome/free-solid-svg-icons";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    library.add( faEye );

    export default {
        name: "ReportStatus",
        components: { FieldSelect, FontAwesomeIcon, ScienceReportCard },
        props: {
            model: { type: String, require: true }
        },
        data() {
            return {
                isViewer: storage.state.session.isViewer(),
                isDepartment: storage.state.session.isDepartment(),
                modal: { item: null },
                year: 2020,
                memberTypes: [],
                departments: [],
                filter: {},
                years: [],
                items: [],
                sortBy: 'fio',
                sortDesc: false,
                fields: [
                    { key: '_view', label: 'Просмотр', class: 'text-center', thStyle: { width: '25px' } },
                    { key: 'fio', label: 'ФИО', sortable: true, class: 'text-center' },
                    { key: 'member_type_name', label: 'Членство в РАО', sortable: true, class: 'text-center' },
                    { key: 'department_name', label: 'Отделение', sortable: true, class: 'text-center' },
                    { key: 'is_dead', label: 'Умер', formatter: 'plusMinusBooleanFormatter', class: 'text-center' },
                    { key: 'status', label: 'Отчет', sortable: true, class: 'text-center' },
                ]
            };
        },
        methods: {
            async getItems() { return ReportController.getReportStatus( this.year, this.filter ); },
            update() {
                this.getItems()
                    .then( ( items ) => { this.items = items; } );
            },
            plusMinusBooleanFormatter( value ) { return value ? '+' : '-';},
            getItemsByStatus( status ) {
                return this.items
                    .filter( ( item ) => !( item.is_dead && item.status === '' ) )
                    .filter( ( item ) => item.status === status )
            },
            async itemViewCard( science_report_id ) {
                let scienceReport = await GeneralController.getItemById( 'scienceReport', science_report_id );
                this.modal.item = scienceReport;
                this.$refs.modal.show();
            },
            updateScienceReport( science_report ) {
                let index = this.items.findIndex( ( item ) => item.science_report_id === science_report.id );
                if ( index < 0 ) { return; }
                ReportController.getReportStatusById( this.year, this.items[ index ].id )
                    .then( ( elm ) => { this.$set( this.items, index, elm ); } );
            }
        },
        created() {
            storage.getDictionary( 'department' ).then( ( items ) => { this.departments = items; } );
            storage.getDictionary( 'memberType' ).then( ( items ) => { this.memberTypes = items; } );

            ReportController.getReportYears()
                .then( ( items ) => {
                    this.years = items.map( ( item ) => ( {
                        value: item.year,
                        text: `${ item.year } год`
                    } ) );
                } );

            if ( this.isDepartment ) { this.filter.department_id = storage.state.session.employee.department_id }

            this.getItems()
                .then( ( items ) => { this.items = items; } );
        }
    }
</script>

<style scoped>

</style>