<template>
    <div>
        <b-alert show>Удаление отчета о научной деятельности специально реализовано сложно.</b-alert>
        <b-row>
            <b-col>
                <b-form @submit="onSubmit">
                    <b-form-group
                            id="label-employee-id"
                            label="UUID члена РАО:"
                            label-for="input-employee-id"
                            description="UUID члена РАО, для которого будет удален отчет о научной деятельности."
                    >
                        <b-form-input
                                id="input-employee-id"
                                v-model.trim="form.employeeId"
                                @change="onEmployeeIdChange"
                                type="text"
                                required
                                placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                            id="label-year-id"
                            label="Год:"
                            label-for="input-year-id"
                            description="Год отчета о научной деятельности, который хотим удалить для заданного члена РАО."
                    >
                        <b-form-input
                                id="input-year-id"
                                v-model.number="form.year"
                                type="number"
                                required
                                placeholder="в формате гггг"
                        ></b-form-input>
                    </b-form-group>
                    <b-button variant="outline-danger" type="submit">Удалить отчет</b-button>
                </b-form>
            </b-col>
            <b-col>
                <b-form-group
                        id="label-employee-name-id"
                        label="ФИО:"
                        label-for="input-employee-name-id"
                        description=""
                >
                    <b-form-input
                            id="input-employee-name-id"
                            :value="fullName"
                            disabled
                    ></b-form-input>
                </b-form-group>
                <ItemList model="scienceReport"
                          ref="itemList"
                          :filter="filter"
                          is-view-item>
                    <template v-slot:default="modal">
                        <ScienceReportCard :scienceReport="modal.item"
                                           @update="modal.update"
                                           :is-edit="false"/>
                    </template>
                </ItemList>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { GeneralController } from "../controllers";
    import { Employee } from "../../shared/models";
    import storage from "../storage.js";
    import ItemList from '../components/ItemList.vue'
    import ScienceReportCard from '../views/ScienceReportCard.vue';

    export default {
        name: "ScienceReportRemove",
        components: { ItemList, ScienceReportCard },
        data() {
            return {
                form: {
                    employeeId: '',
                    year: ''
                },
                employee: new Employee()
            }
        },
        computed: {
            fullName() {
                if ( !this.employee.id ) { return ''; }
                return this.employee.fullName();
            },
            filter() {
                return {
                    employee_id: this.employee.id ? this.employee.id : storage.emptyUID
                };

            }
        },
        methods: {
            onSubmit( event ) {
                event.preventDefault();
                GeneralController.removeCascadeScienceReportByEmployeeId( this.form.employeeId, this.form.year )
                    .then( ( data ) => {
                        this.$refs.itemList.refresh();
                    } );
            },
            onEmployeeIdChange( event ) {
                GeneralController.getItemById( 'employee', this.form.employeeId )
                    .then( ( employee ) => { this.employee = new Employee( employee ); } );
            }
        }

    }
</script>

<style scoped>

</style>