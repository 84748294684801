<template>
    <div>
        <b-button type="button" variant="outline-secondary" @click="showModal()">Выход</b-button>
        <b-modal ref="modal"
                 title="Выход из личного кабинета РАО"
                 ok-title="Да"
                 ok-variant="outline-danger"
                 cancel-title="Отмена"
                 cancel-variant="outline-secondary"
                 @cancel="hideModal()"
                 @ok="logout()"
                 hide-header-close>
            Вы уверены что хотите выйти из системы?
        </b-modal>
    </div>
</template>

<script>
    import { UserController } from '../controllers' ;
    import storage from '../storage.js';

    export default {
        name:      "Logout"
        , methods: {
            logout:      function () {
                UserController.logout()
                    .then( ( status ) => {
                        if ( !status ) { return this.$router.go( -1 ); }

                        storage.state.session = null;
                        this.$router.push( { name: 'login' } );
                    } )
                    .catch( ( error ) => {
                        storage.logNetworkError( error );
                    } );
            }
            , showModal: function () {
                this.$refs.modal.show();
            }
            , hideModal: function () {
                this.$refs.modal.hide();
            }
        }
    }
</script>

<style scoped>

</style>
