/**
 * Created by User on 10.10.2019.
 */
'use strict';

import IEntity from './i-entity.js';

export default class SciencePublication extends IEntity {
    /**
     * @typedef {IEntityConfig} SciencePublicationConfig
     * @property {string} [science_report_id]
     * @property {string} [name]
     * @property {string} [http_link]
     * @property {boolean} [is_foreign_expert]
     * @property {boolean} [is_web_of_science]
     * @property {boolean} [is_scopus]
     * @property {boolean} [is_vak]
     * @property {boolean} [is_rinc]
     * @property {boolean} [is_core_rinc]
     * @property {boolean} [is_google_scholar]
     * @property {boolean} [is_erich]
     * @property {boolean} [is_other]
     */

    /**
     *
     * @param {SciencePublicationConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.science_report_id = config.science_report_id;
        this.name              = config.name;
        this.http_link         = config.http_link;
        this.is_foreign_expert = !!config.is_foreign_expert;
        this.is_web_of_science = !!config.is_web_of_science;
        this.is_scopus         = !!config.is_scopus;
        this.is_vak            = !!config.is_vak;
        this.is_rinc           = !!config.is_rinc;
        this.is_core_rinc      = !!config.is_core_rinc;
        this.is_google_scholar = !!config.is_google_scholar;
        this.is_erich          = !!config.is_erich;
        this.is_other          = !!config.is_other;
    }
}
