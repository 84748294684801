<template>
    <b-form-group :label="displayLabel"
                  :label-for="displayNameId"
                  :label-cols="labelCols"
                  :label-cols-sm="labelColsSm"
                  :label-cols-md="labelColsMd"
                  :label-cols-lg="labelColsLg"
                  :label-cols-xl="labelColsXl"
                  :label-align="labelAlign"
                  :label-align-sm="labelAlignSm"
                  :label-align-md="labelAlignMd"
                  :label-align-lg="labelAlignLg"
                  :label-align-xl="labelAlignXl"
                  :state="state"
                  :label-size="labelSize"
                  :description="description"
                  :valid-feedback="validFeedback"
                  :invalid-feedback="invalidFeedback">
        <b-form-textarea ref="input" :id="displayNameId"
                         :name="name"
                         :state="state"
                         :value="value"
                         @update="onUpdate"
                         @blur="onBlur"
                         :required="required"
                         :placeholder="placeholder"
                         :size="size"
                         :trim="trim"
                         :number="number"
                         :formatter="formatter"
                         :autocomplete="autocomplete"
                         :plaintext="plaintext"
                         :autofocus="autofocus"
                         :rows="rows"
                         :max-rows="maxRows"
                         :wrap="wrap"
                         :no-resize="noResize"
                         :no-auto-shrink="noAutoShrink"
                         :readonly="!isEdit"></b-form-textarea>
    </b-form-group>
</template>

<script>
    export default {
        name:       "FieldTextarea"
        , model:    {
            prop:    'value'
            , event: 'update'
        }
        , props:    {
            label:          { type: String, default: '' }
            , labelSize:    { type: String }
            , placeholder:  { type: String }
            , trim:         { type: Boolean, default: false }
            , number:       { type: Boolean, default: false }
            , formatter:    { type: Function }
            , autocomplete: { type: String, default: 'off' }
            , plaintext:    { type: Boolean, default: false }
            , size:         { type: String }
            , autofocus:    { type: Boolean, default: false }

            , rows:         { type: [ Number, String ], default: 2 }
            , maxRows:      { type: [ Number, String ] }
            , wrap:         { type: String, default: 'soft' }
            , noResize:     { type: Boolean, default: false }
            , noAutoShrink: { type: Boolean, default: false }

            // , state:           { type: Boolean }
            , state: { type: Boolean, default: null }

            , name:            { type: String, default: 'fgt' }
            , value:           { type: [ String, Number ] }
            , description:     { type: String }
            , isEdit:          { type: Boolean, default: false }
            , required:        { type: Boolean, default: false }
            , labelCols:       { type: [ Number, String, Boolean ] }
            , labelColsSm:     { type: [ Number, String, Boolean ] }
            , labelColsMd:     { type: [ Number, String, Boolean ] }
            , labelColsLg:     { type: [ Number, String, Boolean ] }
            , labelColsXl:     { type: [ Number, String, Boolean ] }
            , labelAlign:      { type: String }
            , labelAlignSm:    { type: String }
            , labelAlignMd:    { type: String }
            , labelAlignLg:    { type: String }
            , labelAlignXl:    { type: String }
            , validFeedback:   { type: String }
            , invalidFeedback: { type: String }
        }
        , data:     function () {
            return {
                localState: null
            };
        }
        , computed: {
            displayLabel:    function () {
                let label = this.label ? `${ this.label }${ this.required ? '*' : '' }:` : '';
                return ( label );
            }
            , displayNameId: function () {
                return `${ this.name }-${ this._uid }-id`;
            }
        }
        , methods:  {
            onUpdate: function ( event ) {
                this.$emit( 'update', event )
            }
            , onBlur( e ) {
                if ( this.isEdit ) {
                    this.$emit( 'blur', e );
                }
            }
        }
    }
</script>

<style scoped>

</style>
