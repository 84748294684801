import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

import Employee from '../views/Employee.vue'
import EmployeeList from '../views/EmployeeList.vue'
import EmployeeCard from '../views/EmployeeCard.vue'

import User from '../views/User.vue'
import UserList from '../views/UserList.vue'
import UserProfile from '../views/UserProfile.vue'

import ScienceReport from '../views/ScienceReport.vue'
import ScienceReportList from '../views/ScienceReportList.vue'
import ScienceReportCard from '../views/ScienceReportCard.vue'

import FgosName from '../views/FgosName.vue'
import FgosNameList from '../views/FgosNameList.vue'
import FgosNameCard from '../views/FgosNameCard.vue'

import FgosExpertise from '../views/FgosExpertise.vue'
import FgosExpertiseList from '../views/FgosExpertiseList.vue'
import FgosExpertiseCard from '../views/FgosExpertiseCard.vue'

import Model from '../views/Model.vue'
import ModelList from '../views/ModelList.vue'
import ModelCard from '../views/ModelCard.vue'

// import storage from './storage.js';
import { ifNotAuthenticated, ifAuthenticated } from '../guard';


import ViewReport from "../views/ViewReport";
import ViewReportGroup from "../views/ViewReportGroup";
import ViewReportList from "../views/ViewReportList";

import DataViewList from "../views/DataViewList.vue";
import ReportStatus from "../views/ReportStatus.vue";
import ReportStatisticIndex from "../views/ReportStatisticIndex.vue";

import ScienceReportRemove from "../views/ScienceReportRemove.vue";

export default {
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            redirect: '/user/profile',
            component: Home,
            beforeEnter: ifAuthenticated
        }
        , {
            path: '/about',
            name: 'about',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
            beforeEnter: ifAuthenticated
        }
        , {
            path: '/login',
            name: 'login',
            component: Login,
            beforeEnter: ifNotAuthenticated
        }
        , {
            path: '/employee',
            name: 'employee',
            component: Employee,
            beforeEnter: ifAuthenticated
            , children: [
                {
                    path: 'card',
                    name: 'employee-card',
                    component: EmployeeCard,
                }
                , {
                    path: 'list',
                    name: 'employee-list',
                    component: EmployeeList,
                }
            ]
        }
        , {
            path: '/user',
            name: 'user',
            component: User,
            beforeEnter: ifAuthenticated
            , children: [
                {
                    path: 'profile',
                    name: 'user-profile',
                    component: UserProfile,
                }
                , {
                    path: 'list',
                    name: 'user-list',
                    component: UserList,
                }
                , {
                    path: 'deleteScienceReport',
                    name: 'science-report-remove',
                    component: ScienceReportRemove,
                }
            ]
        }
        , {
            path: '/scienceReport',
            name: 'science-report',
            component: ScienceReport,
            beforeEnter: ifAuthenticated
            , children: [
                {
                    path: 'list',
                    name: 'science-report-list',
                    component: ScienceReportList,
                }
                , {
                    path: 'card',
                    name: 'science-report-card',
                    component: ScienceReportCard,
                }
            ]
        }
        , {
            path: '/fgos/name',
            name: 'fgos-name',
            component: FgosName,
            beforeEnter: ifAuthenticated
            , children: [
                {
                    path: 'list',
                    name: 'fgos-name-list',
                    component: FgosNameList,
                }
                , {
                    path: 'card',
                    name: 'fgos-name-card',
                    component: FgosNameCard,
                }
            ]
        }
        , {
            path: '/fgos/expertise',
            name: 'fgos-expertise',
            component: FgosExpertise,
            beforeEnter: ifAuthenticated
            , children: [
                {
                    path: 'list',
                    name: 'fgos-expertise-list',
                    component: FgosExpertiseList,
                }
                , {
                    path: 'card',
                    name: 'fgos-expertise-card',
                    component: FgosExpertiseCard,
                }
            ]
        }
        , {
            path: '/view/getReportStatisticIndex',
            name: 'view-report-statistic-index',
            component: ReportStatisticIndex,
            beforeEnter: ifAuthenticated
        }
        , {
            path: '/view/:schemaId',
            name: 'view-report',
            component: ViewReport,
            beforeEnter: ifAuthenticated
            , children: [
                {
                    path: 'group',
                    name: 'view-report-group',
                    component: ViewReportGroup,
                }, {
                    path: 'list',
                    name: 'view-report-list',
                    component: ViewReportList,
                }
            ]
        }
        , {
            path: '/data/report'
            , name: 'data-report-status'
            , component: ReportStatus
            , props: true
            // ToDo скорее всего ошибка не хватает проверки авторизации ниже
            // , beforeEnter: ifAuthenticated
        }
        , {
            path: '/data/:model'
            , name: 'data-view-list'
            , component: DataViewList
            , props: true
            // ToDo скорее всего ошибка не хватает проверки авторизации ниже
            // , beforeEnter: ifAuthenticated
        }
        // general
        // /:model
        // /:model/:id
        , {
            path: '/:model',
            name: 'model',
            component: Model,
            beforeEnter: ifAuthenticated
            , children: [
                {
                    path: 'list',
                    name: 'model-list',
                    component: ModelList,
                    props: true
                }
                , {
                    path: 'card',
                    name: 'model-card',
                    component: ModelCard,
                }
            ]
        }
        , { path: '*', redirect: '/' }
    ]
}
