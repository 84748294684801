<template>
    <b-form-group :label="displayLabel"
                  :label-for="displayNameId"
                  :label-cols="labelCols"
                  :label-cols-sm="labelColsSm"
                  :label-cols-md="labelColsMd"
                  :label-cols-lg="labelColsLg"
                  :label-cols-xl="labelColsXl"
                  :label-align="labelAlign"
                  :label-align-sm="labelAlignSm"
                  :label-align-md="labelAlignMd"
                  :label-align-lg="labelAlignLg"
                  :label-align-xl="labelAlignXl"
                  :state="state"
                  :label-size="labelSize"
                  :description="description"
                  :valid-feedback="validFeedback"
                  :invalid-feedback="invalidFeedback">
        <b-form-select ref="select" :id="displayNameId"
                       name="name"
                       :state="state"
                       :select-size="selectSize"
                       :autofocus="autofocus"
                       :plain="plain"
                       :value-field="valueField"
                       :text-field="textField"
                       :disabled-field="disabledField"
                       :multiple="multiple"
                       :options="options"
                       :value="value"
                       @change="onChange"
                       :required="required"
                       :disabled="!isEdit">
            <template v-slot:first>
                <option :value="null">-- Выберите значение --</option>
                <!--                <option :value="null" disabled>&#45;&#45; Выбирите значение &#45;&#45;</option>-->
            </template>
        </b-form-select>
    </b-form-group>
</template>

<script>
    export default {
        name:       "FieldSelect"
        , model:    {
            prop:    'value'
            , event: 'change'
        }
        , props:    {
            label:       { type: String, default: '' }
            , labelSize: { type: String }

            , size:          { type: String }
            , autofocus:     { type: Boolean, default: false }
            , plain:         { type: Boolean, default: false }
            , valueField:    { type: String, default: 'id' }
            , textField:     { type: String, default: 'name' }
            , disabledField: { type: String, default: 'disabled' }
            , multiple:      { type: Boolean, default: false }
            , selectSize:    { type: Number, default: 0 }

            // , state:           { type: Boolean }
            , state: { type: Boolean, default: null }

            , options: { type: [ Array, Object ], default: function () { return []; } }

            , name:            { type: String, default: 'fgs' }
            , value:           { type: [ String, Number ], default: null }
            , description:     { type: String }
            , isEdit:          { type: Boolean, default: false }
            , required:        { type: Boolean, default: false }
            , labelCols:       { type: [ Number, String, Boolean ] }
            , labelColsSm:     { type: [ Number, String, Boolean ] }
            , labelColsMd:     { type: [ Number, String, Boolean ] }
            , labelColsLg:     { type: [ Number, String, Boolean ] }
            , labelColsXl:     { type: [ Number, String, Boolean ] }
            , labelAlign:      { type: String }
            , labelAlignSm:    { type: String }
            , labelAlignMd:    { type: String }
            , labelAlignLg:    { type: String }
            , labelAlignXl:    { type: String }
            , validFeedback:   { type: String }
            , invalidFeedback: { type: String }
        }
        , data:     function () {
            return {
                localState: null
            };
        }
        , computed: {
            displayLabel:    function () {
                let label = this.label ? `${ this.label }${ this.required ? '*' : '' }:` : '';
                return ( label );
            }
            , displayNameId: function () {
                return `${ this.name }-${ this._uid }-id`;
            }
        }
        , methods:  {
            onChange: function ( event ) {
                this.$emit( 'change', event )
            }
        }
    }
</script>

<style scoped>

</style>
