/**
 * Created by User on 21.09.2019.
 */

'use strict';

export default class SchemaField {
    /**
     * @typedef {Object} SchemaFieldConfig
     * @property {string} name
     * @property {string} [shortName]
     * @property {string} systemName
     * @property {Object} type
     * @property {Object} [lookupSchemaId]
     * @property {string} [lookupSystemName]
     * @property {string[]} [lookupSearchFields]
     * @property {string} [lookupName]
     * @property {boolean} [isPrimaryKey=false]
     * @property {boolean} [isList=false]
     * @property {boolean} [isCard=false]
     * @property {boolean} [isRequired=false]
     * @property {boolean} [isUnique=false]
     * @property {boolean} [isEdit=false]
     * @property {boolean} [isAddOnly=false]
     * @property {boolean} [isSort=false]
     * @property {string} [tooltip]
     * @property {string} [description]
     * @property {string} [formatter]
     * @property {string} [css]
     * @property {string} [validFeedback]
     * @property {string} [invalidFeedback]
     * @property {number} [rows]
     * @property {number} [maxRows]
     */

    /**
     *
     * @param {SchemaFieldConfig} config
     */
    constructor( config ) {
        this.name       = config.name;
        this.shortName  = config.shortName || config.name;
        this.systemName = config.systemName;

        this.type               = config.type;
        this.lookupSchemaId     = config.lookupSchemaId;
        this.lookupSystemName   = config.lookupSystemName;
        this.lookupSearchFields = config.lookupSearchFields || [];
        this.lookupName         = config.lookupName;

        this.tooltip     = config.tooltip;
        this.description = config.description;

        this.formatter   = config.formatter;

        this.isPrimaryKey = !!config.isPrimaryKey;

        this.isList = !!config.isList;
        this.isCard = !!config.isCard;

        this.isRequired = !!config.isRequired;
        this.isUnique   = !!config.isUnique;
        this.isAddOnly  = !!config.isAddOnly;
        this.isEdit     = !!config.isEdit;
        this.isSort     = !!config.isSort;

        this.css = config.css;

        this.validFeedback   = config.validFeedback;
        this.invalidFeedback = config.invalidFeedback;

        this.rows    = config.rows;
        this.maxRows = config.maxRows;
    }
}

