<template>
    <b-form ref="form" @submit.stop.prevent="debounceSave" novalidate :validated="validated">
        <b-card :title="schema.singleName">
            <b-alert :variant="alert.variant" v-model="alert.show" fade>{{ alert.message }}</b-alert>
            <b-card-header v-if="isEdit">
                <b-button-group>
                    <b-button type="submit" variant="outline-secondary">Сохранить</b-button>
                    <b-button variant="outline-secondary" @click="cancel()">Отменить</b-button>
                </b-button-group>
            </b-card-header>

            <b-card-body>
                <ItemCardFieldList ref="cardFieldList"
                                   :schema-id="model"
                                   :item="localItem"
                                   :is-edit="isEdit"/>
            </b-card-body>
        </b-card>
    </b-form>
</template>

<script>
    import storage from '../storage';
    import { GeneralController } from '../controllers'
    import ItemCardFieldList from "./ItemCardFieldList";
    // import { debounce } from "debounce";

    export default {
        name:         "ItemCard"
        , components: { ItemCardFieldList }
        , model:      {
            prop:    'item'
            , event: 'update'
        }
        , props:      {
            model:        { type: String, required: true }
            , item:       { type: Object, default: function () { return {}; } }
            , isEdit:     { type: Boolean, default: false }
            , labelCols:  { type: [ String, Number ], default: 3 }
            , labelAlign: { type: String, default: 'right' }
        }
        , data:       function () {
            return {
                schemaManager: storage.state.schemaManager
                , localItem:   this.getItem( this.item )
                , validated:   null
                , alert:       {
                    show:      false
                    , variant: 'info'
                    , message: ''
                }
            };
        }
        , computed:   {
            schema:       function () { return this.schemaManager.getSchemaById( this.model ); }
            , cardFields: function () { return this.schema.fields.filter( ( item ) => item.isCard ); }
        }
        , watch:      {
            item: function ( value ) {
                this.localItem = this.getItem( value );
            }
        }
        , methods:    {
            getItem( value ) { return Object.assign( {}, value ) }
            , touch() { this.$refs.cardFieldList.touch(); }
            , reset() { this.$refs.cardFieldList.reset(); }
            , invalid() { return this.$refs.cardFieldList && this.$refs.cardFieldList.invalid; }
            , pending() { return this.$refs.cardFieldList && this.$refs.cardFieldList.pending; }
            , checkValidity() { return this.$refs.cardFieldList.checkValidity(); }
            , debounceSave: function ( e ) {
                this.touch();
                if ( !this.pending() ) { return this.save( e ); }

                let intervalId;
                intervalId = setInterval( () => {
                    if ( !this.pending() ) {
                        this.save( e );
                        clearInterval( intervalId );
                    }
                }, storage.debouncers.default )
            }
            , save:         async function () {
                this.show( 'Проверяем данные...', true );
                // this.touch();
                if ( !this.checkValidity() ) {
                    this.show( 'Поправьте данные...', true );
                    return;
                }

                this.show( 'Идет сохранение...', true );
                let promise;
                if ( this.localItem.id ) {
                    promise = GeneralController.updateItemById( this.model, this.localItem.id, this.localItem );
                } else {
                    promise = GeneralController.addItem( this.model, this.localItem );
                }

                promise
                    .then( ( json ) => {
                        this.localItem = json;
                        this.$emit( 'update', json );
                        this.show( 'Запись сохранена', 5 );
                    } )
                    .catch( ( error ) => {
                        storage.logNetworkError( error );
                        this.show( 'Ошибка сохранения на сервере', 5 );
                    } );
            }
            , cancel() {
                this.localItem = this.getItem( this.item );
                this.reset()
            }
            , show( message, timeSec = 5 ) {
                this.alert.message = message;
                this.alert.show    = timeSec || true;
            }
            , hide() { this.alert.show = false; }
        }
    }
</script>

<style scoped>

</style>
