<template>
    <ItemList model="fgosName"
              :is-add-item="isEdit"
              :is-edit-item="isEdit"
              is-view-item
    >
        <template v-slot:default="modal">
            <FgosNameCard :fgosName="modal.item"
                          @update="modal.update"
                          :is-edit="modal.isEdit"/>
        </template>
    </ItemList>
</template>

<script>
    import storage from '../storage.js';
    import ItemList from "../components/ItemList";
    import FgosNameCard from "./FgosNameCard";

    export default {
        name:       "FgosNameList",
        components: { FgosNameCard, ItemList },
        computed:   {
            isEdit() { return storage.state.session && storage.state.session.isExpertise(); },
        }
    }
</script>

<style scoped>

</style>
