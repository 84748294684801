/**
 * Created by User on 12.10.2019.
 */
'use strict';

import IEntity from "./i-entity.js";


export default class PostgraduateTraining extends IEntity {
    /**
     * @typedef {IEntityConfig} PostgraduateTrainingConfig
     * @property {string} [science_report_id]
     * @property {string} [fio]
     * @property {string} [edu_name]
     * @property {string} [scientific_specialties_code_name]
     * @property {string} [work_training_type_id]
     */

    /**
     *
     * @param {PostgraduateTrainingConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.science_report_id                   = config.science_report_id;
        this.fio                                 = config.fio;
        this.edu_name                            = config.edu_name;
        this.scientific_specialties_code_name    = config.scientific_specialties_code_name;
        this.work_training_type_id               = config.work_training_type_id;
    }
}
