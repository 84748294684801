<template>
    <b-tab :title="title">
        <ItemCardFieldList ref="list"
                           :fields="fields"
                           :schema-id="schemaId"
                           :item="item"
                           :dictionaries="dictionaries"
                           :label-cols="labelCols"
                           :label-align="labelAlign"
                           :is-edit="isEdit"/>
        <slot></slot>
    </b-tab>
</template>

<script>
    import ItemCardFieldList from "./ItemCardFieldList.vue";

    export default {
        name:       "ItemCardTab",
        components: { ItemCardFieldList },
        props:      {
            title:          { type: String, default: 'Без названия' }
            , schemaId:     { type: String, required: true }
            , fields:       { type: Array, default: function () {return [];} }
            , item:         { type: Object, required: true }
            , dictionaries: { type: Object, default: function () { return {}; } }
            , isEdit:       { type: Boolean, default: false }
            , labelCols:    { type: [ String, Number ], default: 3 }
            , labelAlign:   { type: String, default: 'right' }
        }
        , methods:  {
            touch() { this.$refs.list.touch(); }
            , reset() { this.$refs.list.reset(); }
            , checkValidity() { return ( this.$refs.list.checkValidity() ); }
        }
    }
</script>

<style scoped>

</style>
