/**
 * Created by User on 12.10.2019.
 */
'use strict';

import IEntity from "./i-entity.js";


export default class ParticipationDissertationCouncil extends IEntity {
    /**
     * @typedef {IEntityConfig} ParticipationDissertationCouncilConfig
     * @property {string} [science_report_id]
     * @property {string} [dissertation_council_text]
     */

    /**
     *
     * @param {ParticipationDissertationCouncilConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.science_report_id       = config.science_report_id;
        this.dissertation_council_text = config.dissertation_council_text;
    }
}
