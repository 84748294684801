<template>
    <b-card no-body>
        <b-card-header>
            <editor-menu-bar :editor="editor" v-slot:default="{ commands, isActive }">
                <b-button-group>
                                        <b-button type="button"
                                                  variant="outline-secondary"
                                                  :pressed="isActive.bold()"
                                                  @click="commands.bold">
                                            <font-awesome-icon icon="bold"/>
                                        </b-button>
                                        <b-button type="button"
                                                  variant="outline-secondary"
                                                  :pressed="isActive.italic()"
                                                  @click="commands.italic">
                                            <font-awesome-icon icon="italic"/>
                                        </b-button>
                                        <b-button type="button"
                                                  variant="outline-secondary"
                                                  :pressed="isActive.strike()"
                                                  @click="commands.strike">
                                            <font-awesome-icon icon="strikethrough"/>
                                        </b-button>
                                        <b-button type="button"
                                                  variant="outline-secondary"
                                                  :pressed="isActive.underline()"
                                                  @click="commands.underline">
                                            <font-awesome-icon icon="underline"/>
                                        </b-button>
                    <!--                    <b-button type="button"-->
                    <!--                              variant="outline-secondary"-->
                    <!--                              :pressed="isActive.code()"-->
                    <!--                              @click="commands.code">-->
                    <!--                        <font-awesome-icon icon="code"/>-->
                    <!--                    </b-button>-->
                    <b-button type="button"
                              variant="outline-secondary"
                              :pressed="isActive.heading({ level: 1 })"
                              @click="commands.heading({ level: 1 })">
                        <font-awesome-icon icon="heading"/>
                        1
                    </b-button>
                    <b-button type="button"
                              variant="outline-secondary"
                              :pressed="isActive.heading({ level: 2 })"
                              @click="commands.heading({ level: 2 })">
                        <font-awesome-icon icon="heading"/>
                        2
                    </b-button>
                    <b-button type="button"
                              variant="outline-secondary"
                              :pressed="isActive.heading({ level: 3 })"
                              @click="commands.heading({ level: 3 })">
                        <font-awesome-icon icon="heading"/>
                        3
                    </b-button>
                    <b-button type="button"
                              variant="outline-secondary"
                              :pressed="isActive.bullet_list()"
                              @click="commands.bullet_list()">
                        <font-awesome-icon icon="list-ul"/>
                    </b-button>
<!--                    <b-button type="button"-->
<!--                              variant="outline-secondary"-->
<!--                              :pressed="isActive.ordered_list()"-->
<!--                              @click="commands.ordered_list()">-->
<!--                        <font-awesome-icon icon="list-ol"/>-->
<!--                    </b-button>-->
                    <!--                    <b-button type="button"-->
                    <!--                              variant="outline-secondary"-->
                    <!--                              :pressed="isActive.blockquote()"-->
                    <!--                              @click="commands.blockquote()">-->
                    <!--                        <font-awesome-icon icon="quote-right"/>-->
                    <!--                    </b-button>-->
                    <!--                    <b-button type="button"-->
                    <!--                              variant="outline-secondary"-->
                    <!--                              :pressed="isActive.code_block()"-->
                    <!--                              @click="commands.code_block">-->
                    <!--                        <font-awesome-icon icon="code"/>-->
                    <!--                    </b-button>-->
                    <!--                    <b-button type="button"-->
                    <!--                              variant="outline-secondary"-->
                    <!--                              :pressed="isActive.horizontal_rule()"-->
                    <!--                              @click="commands.horizontal_rule">-->
                    <!--                        <font-awesome-icon icon="window-minimize"/>-->
                    <!--                    </b-button>-->
                    <!--                    <b-button type="button"-->
                    <!--                              variant="outline-secondary"-->
                    <!--                              :pressed="isActive.paragraph()"-->
                    <!--                              @click="commands.paragraph">-->
                    <!--                        <font-awesome-icon icon="paragraph"/>-->
                    <!--                    </b-button>-->
                    <b-button type="button"
                              variant="outline-secondary"
                              @click="commands.undo">
                        <font-awesome-icon icon="undo"/>
                    </b-button>
                    <b-button type="button"
                              variant="outline-secondary"
                              @click="commands.rendo">
                        <font-awesome-icon icon="redo"/>
                    </b-button>
                </b-button-group>
            </editor-menu-bar>
        </b-card-header>
        <b-card-body style="overflow-y: auto; max-height: 500px">
            <editor-content :editor="editor"/>
        </b-card-body>
    </b-card>
</template>

<script>
    import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
    import {
        Blockquote,
        CodeBlock,
        HardBreak,
        Heading,
        HorizontalRule,
        OrderedList,
        BulletList,
        ListItem,
        TodoItem,
        TodoList,
        Bold,
        Code,
        Italic,
        Link,
        Strike,
        Underline,
        History,
    } from 'tiptap-extensions';

    import { library } from '@fortawesome/fontawesome-svg-core';
    import {
        faBold,
        faItalic,
        faStrikethrough,
        faUnderline,
        faCode,
        faParagraph,
        faHeading,
        faListUl,
        faListOl,
        faQuoteRight,
        faWindowMinimize,
        faUndo,
        faRedo
    } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    library.add(
        faBold, faItalic, faStrikethrough, faUnderline, faCode, faParagraph,
        faHeading, faListUl, faListOl, faQuoteRight, faWindowMinimize, faUndo, faRedo
    );

    export default {
        name:         "FieldEditor"
        , model:      {
            prop:    'content'
            , event: 'update'
        }
        , components: { EditorContent, EditorMenuBar, FontAwesomeIcon }
        , props:      {
            content:  { type: [ Object, String ], default: null }
            , isEdit: { type: Boolean, default: false }
        }
        , data:       function () {
            return {
                editor: null,
            }
        }
        , watch:      {
            isEdit: function ( value, oldValue ) {
                this.editor.editable = value;
            }
        }
        , methods:    {
            onUpdate( data ) { this.$emit( 'update', data.getJSON() ); }
        }
        , mounted() {
            this.editor = new Editor( {
                extensions: [
                    new Blockquote(),
                    new BulletList(),
                    new CodeBlock(),
                    new HardBreak(),
                    new Heading( { levels: [ 1, 2, 3 ] } ),
                    new HorizontalRule(),
                    new ListItem(),
                    new OrderedList(),
                    new TodoItem(),
                    new TodoList(),
                    new Link(),
                    new Bold(),
                    new Code(),
                    new Italic(),
                    new Strike(),
                    new Underline(),
                    new History(),
                ],
                content:    this.content,
                editable:   this.isEdit,
                onUpdate:   this.onUpdate,
                onInit:     function () { this.emitUpdate(); }
            } );
        },
        beforeDestroy() {
            this.editor.destroy()
        },
    }
</script>

<style scoped>

</style>
