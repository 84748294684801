/**
 * Created by User on 10.10.2019.
 */
'use strict';
import IEntity from './i-entity.js';

export default class ScienceReport extends IEntity {
    /**
     * @typedef {IEntityConfig} ScienceReportConfig
     * @property {string} [name]
     * @property {number} [year]
     * @property {string} [employee_id]
     * @property {boolean} [is_done=false]
     * @property {string} [note]
     */

    /**
     *
     * @param {ScienceReportConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.name        = config.name;
        this.year        = config.year;
        this.employee_id = config.employee_id;
        this.is_done      = !!config.is_done;
        this.note        = config.note;
    }
}
