<template>
    <b-table-simple striped>
        <b-thead v-if="answer.length">
            <b-tr class="text-center">
                <b-th class="align-middle" rowspan="2">Позиция для экспертной оценки</b-th>
                <b-th class="align-middle" rowspan="2" v-if="isResult">Результат</b-th>
                <b-th class="align-middle text-center" :colspan="answer.length">Экспертная оценка</b-th>
            </b-tr>
            <b-tr class="text-center">
                <b-th v-for="item in answer" v-bind:key="item.id">{{ item.name }}</b-th>
            </b-tr>
        </b-thead>
        <b-tbody v-if="answer.length">
            <b-tr>
                <b-th :colspan="answer.length+columnLength">5.1. Представленный проект ФГОС в целом:</b-th>
            </b-tr>
            <b-tr>
                <b-td>5.1.1) соответствует нормативным срокам освоения основных образовательных программ данного уровня
                    образования; ступени
                </b-td>
                <b-td class="text-center" v-if="isResult">{{ getMaxAnswer('field_511') }}</b-td>
                <b-td class="text-center" v-for="item in answer" v-bind:key="item.id"> {{ getCount( 'field_511', item.id
                    ) }}
                </b-td>
            </b-tr>
            <b-tr>
                <b-td>5.1.2) соответствует возрастным особенностям и возможностям обучающихся
                </b-td>
                <b-td class="text-center" v-if="isResult">{{ getMaxAnswer('field_512') }}</b-td>
                <b-td class="text-center" v-for="item in answer" v-bind:key="item.id"> {{ getCount( 'field_512', item.id
                    ) }}
                </b-td>
            </b-tr>
            <b-tr>
                <b-td>5.1.5) соответствует законодательству Российской Федерации
                </b-td>
                <b-td class="text-center" v-if="isResult">{{ getMaxAnswer('field_515') }}</b-td>
                <b-td class="text-center" v-for="item in answer" v-bind:key="item.id"> {{ item.is_simple ? getCount(
                    'field_515', item.id) : 'x' }}
                </b-td>
            </b-tr>
            <b-tr>
                <b-td>5.1.6) отвечает характеристикам современного образования и перспективным задачам образования в
                    Российской Федерации
                </b-td>
                <b-td class="text-center" v-if="isResult">{{ getMaxAnswer('field_516') }}</b-td>
                <b-td class="text-center" v-for="item in answer" v-bind:key="item.id"> {{ getCount( 'field_516', item.id
                    ) }}
                </b-td>
            </b-tr>
            <b-tr>
                <b-td>5.1.7) может быть реализован в системе образования Российской Федерации
                </b-td>
                <b-td class="text-center" v-if="isResult">{{ getMaxAnswer('field_517') }}</b-td>
                <b-td class="text-center" v-for="item in answer" v-bind:key="item.id"> {{ getCount( 'field_517', item.id
                    ) }}
                </b-td>
            </b-tr>
            <b-tr>
                <b-th :colspan="answer.length+columnLength">5.2. Полно представлена информация о разработке
                    представленного проекта ФГОС:
                </b-th>
            </b-tr>
            <b-tr>
                <b-td>5.2.1) об организациях, которые приняли участие в разработке
                </b-td>
                <b-td class="text-center" v-if="isResult">{{ getMaxAnswer('field_521') }}</b-td>
                <b-td class="text-center" v-for="item in answer" v-bind:key="item.id"> {{ getCount( 'field_521', item.id
                    ) }}
                </b-td>
            </b-tr>
            <b-tr>
                <b-td>5.2.2) об организации и результатах обсуждения и апробации проекта и его отдельных элементов
                </b-td>
                <b-td class="text-center" v-if="isResult">{{ getMaxAnswer('field_522') }}</b-td>
                <b-td class="text-center" v-for="item in answer" v-bind:key="item.id"> {{ getCount( 'field_522', item.id
                    ) }}
                </b-td>
            </b-tr>
            <b-tr>
                <b-td>5.3.Текст представленного проекта ФГОС аккуратно оформлен, соответствует правилам русской
                    орфографии и пунктуации
                </b-td>
                <b-td class="text-center" v-if="isResult">{{ getMaxAnswer('field_53') }}</b-td>
                <b-td class="text-center" v-for="item in answer" v-bind:key="item.id"> {{ getCount( 'field_53', item.id
                    ) }}
                </b-td>
            </b-tr>
            <b-tr>
                <b-th :colspan="answer.length+columnLength">5.4. Общее экспертное мнение о проекте:</b-th>
            </b-tr>
            <b-tr>
                <b-td>5.4.1) может быть рекомендован к утверждению
                </b-td>
                <b-td class="text-center" v-if="isResult">{{ getMaxAnswer('field_541') }}</b-td>
                <b-td class="text-center" v-for="item in answer" v-bind:key="item.id"> {{ getCount( 'field_541', item.id
                    ) }}
                </b-td>
            </b-tr>
            <b-tr>
                <b-td>5.4.2) следует рекомендовать к отклонению
                </b-td>
                <b-td class="text-center" v-if="isResult">{{ getMaxAnswer('field_542') }}</b-td>
                <b-td class="text-center" v-for="item in answer" v-bind:key="item.id"> {{ getCount( 'field_542', item.id
                    ) }}
                </b-td>
            </b-tr>
            <b-tr>
                <b-td>5.4.3) следует рекомендовать к доработке
                </b-td>
                <b-td class="text-center" v-if="isResult">{{ getMaxAnswer('field_543') }}</b-td>
                <b-td class="text-center" v-for="item in answer" v-bind:key="item.id"> {{ getCount( 'field_543', item.id
                    ) }}
                </b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>

<script>
    import storage from '../storage.js'
    import { GeneralController } from '../controllers/index.js'

    export default {
        name:     "FgosExpertiseResult",
        props:    {
            fgosNameId: { type: String }
        },
        data() {
            return {
                answer:         [],
                simpleAnswer:   [],
                expertiseTotal: {},
                isResult:       false,
            };
        },
        computed: {
            columnLength() { return this.isResult ? 2 : 1; }
        },
        methods:  {
            getCount( field, id ) {
                let data = this.expertiseTotal[ field ];
                if ( !data ) { return ''; }

                let elm = data.find( ( item ) => item.id === id );
                if ( !elm ) { return ''; }

                return ( elm.count );
            },
            getMaxAnswer( field ) {
                let data = this.expertiseTotal[ field ];
                if ( !data ) { return ''; }
                let max = 0;
                let elm;
                data.forEach( ( item ) => {
                    if ( item.count >= max ) {
                        elm = item;
                    }
                } );
                if ( !elm ) { return ''; }

                let answer = this.answer.find( ( item ) => item.id === elm.id );
                if ( !answer ) { return ''; }

                return ( answer.name );
            },
            getExpertiseTotal() {
                if ( !this.fgosNameId ) { return; }
                GeneralController.getExpertiseTotal( this.fgosNameId )
                    .then( ( data ) => {
                        this.expertiseTotal = data;
                    } )
                    .catch( ( error ) => {
                        storage.logNetworkError( error );
                        this.show( 'Ошибка получения данных от сервера', true );
                    } )
            }
        }
        , created() {
            storage.getDictionary( 'fgosExpertiseAnswer' )
                .then( ( items ) => {
                    this.answer       = items;
                    this.simpleAnswer = items.filter( ( item ) => item.is_simple );
                } );

            this.getExpertiseTotal();

            if ( this.$route.query.isResult === 'true') {
                this.isResult = true;
            }
        }
    }
</script>

<style scoped>

</style>
