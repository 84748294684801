/**
 * Created by User on 09.11.2019.
 */
'use strict';
import IEntity from "./i-entity.js";

export default class FgosExpertise extends IEntity {
    /**
     * @typedef {IEntityConfig} FgosExpertiseConfig
     * @property {string} [fgos_name_id]
     * @property {string} [employee_id]
     * @property {string} [field_511_id]
     * @property {string} [field_511_note]
     * @property {string} [field_512_id]
     * @property {string} [field_512_note]
     * @property {string} [field_515_id]
     * @property {string} [field_515_note]
     * @property {string} [field_516_id]
     * @property {string} [field_516_note]
     * @property {string} [field_517_id]
     * @property {string} [field_517_note]
     * @property {string} [field_521_id]
     * @property {string} [field_521_note]
     * @property {string} [field_522_id]
     * @property {string} [field_522_note]
     * @property {string} [field_53_id]
     * @property {string} [field_53_note]
     * @property {string} [field_541_id]
     * @property {string} [field_541_note]
     * @property {string} [field_542_id]
     * @property {string} [field_542_note]
     * @property {string} [field_543_id]
     * @property {string} [field_543_note]
     */

    /**
     *
     * @param {FgosExpertiseConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.fgos_name_id = config.fgos_name_id;
        this.employee_id  = config.employee_id;

        this.field_511_id   = config.field_511_id;
        this.field_511_note = config.field_511_note;
        this.field_512_id   = config.field_512_id;
        this.field_512_note = config.field_512_note;
        this.field_515_id   = config.field_515_id;
        this.field_515_note = config.field_515_note;
        this.field_516_id   = config.field_516_id;
        this.field_516_note = config.field_516_note;
        this.field_517_id   = config.field_517_id;
        this.field_517_note = config.field_517_note;
        this.field_521_id   = config.field_521_id;
        this.field_521_note = config.field_521_note;
        this.field_522_id   = config.field_522_id;
        this.field_522_note = config.field_522_note;
        this.field_53_id    = config.field_53_id;
        this.field_53_note  = config.field_53_note;
        this.field_541_id   = config.field_541_id;
        this.field_541_note = config.field_541_note;
        this.field_542_id   = config.field_542_id;
        this.field_542_note = config.field_542_note;
        this.field_543_id   = config.field_543_id;
        this.field_543_note = config.field_543_note;
    }
}
