/**
 * Created by User on 29.09.2019.
 */
'use strict';

/**
 *
 * @param {string} method
 * @param {Object} [body]
 * @return {Object}
 */
export function getFetchInit( method, body ) {
    return {
        method: method
        // , mode:        'no-cors'
        , cache: 'no-cache'
        // , credentials: 'same-origin'
        , credentials: 'include'
        , headers: { 'Content-Type': 'application/json' }
        , body: body && JSON.stringify( body )
    }
}
