/**
 * Created by User on 10.10.2019.
 */
'use strict';

import IEntity from './i-entity.js';

export default class OtherPublication extends IEntity {
    /**
     * @typedef {IEntityConfig} OtherPublicationConfig
     * @property {string} [science_report_id]
     * @property {string} [name]
     * @property {string} [publication_project_type_id]
     */

    /**
     *
     * @param {OtherPublicationConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.science_report_id           = config.science_report_id;
        this.name                        = config.name;
        this.publication_project_type_id = config.publication_project_type_id;
    }
}
