<template>
    <b-card :no-body="false" :title="schema.singleName">
        <b-form ref="form" @submit.stop.prevent="debounceSave" novalidate>
            <b-alert v-model="alert.show" fade>{{ alert.message }}</b-alert>
            <b-card-header>
                <b-button-group>
                    <b-button v-if="isEdit"
                              type="submit"
                              variant="outline-secondary">Сохранить
                    </b-button>
                    <b-button v-if="isEdit"
                              @click="cancel()"
                              variant="outline-secondary">Отменить
                    </b-button>
                </b-button-group>
            </b-card-header>
            <b-tabs card>
                <ItemCardTab title="Профиль"
                             ref="employeeTabInfo"
                             schema-id="employee"
                             :fields="['lastname', 'firstname', 'middlename', 'department_id', 'member_type_id', 'academic_degree', 'academic_rank', 'is_dead']"
                             :dictionaries="dictionaries"
                             :item="localEmployee"
                             :label-cols="labelCols"
                             :label-align="labelAlign"
                             :is-edit="isEdit"/>
                <ItemCardTab title="Контакты"
                             ref="employeeTabContact"
                             schema-id="employee"
                             :fields="['email', 'mobile_phone', 'home_phone', 'registration_address', 'post_address']"
                             :dictionaries="dictionaries"
                             :item="localEmployee"
                             :label-cols="labelCols"
                             :label-align="labelAlign"
                             :is-edit="isEdit"/>
                <ItemCardTab title="РАО"
                             ref="employeeTabMember"
                             schema-id="employee"
                             :fields="['member_info_rao', 'title_info_rao', 'member_info_other']"
                             :dictionaries="dictionaries"
                             :item="localEmployee"
                             :label-cols="labelCols"
                             :label-align="labelAlign"
                             :is-edit="isEdit"/>
                <ItemCardTab title="Место работы"
                             ref="employeeTabOrganization"
                             schema-id="employee"
                             :fields="['work_organization', 'work_position', 'work_additional_organization', 'work_additional_position']"
                             :dictionaries="dictionaries"
                             :item="localEmployee"
                             :label-cols="labelCols"
                             :label-align="labelAlign"
                             :is-edit="isEdit"/>
                <ItemCardTab title="Контактные лица"
                             ref="employeeTabRelative"
                             schema-id="employee"
                             :fields="['contact_person', 'contact_person_phone', 'contact_person_email']"
                             :dictionaries="dictionaries"
                             :item="localEmployee"
                             :label-cols="labelCols"
                             :label-align="labelAlign"
                             :is-edit="isEdit"/>
                <b-tab title="Отчеты" v-if="showReport">
                    <b-card-body>
                        <ItemList model="scienceReport"
                                  :filter="{employee_id: computedEmployeeId}"
                                  is-view-item>
                            <template v-slot:default="modal">
                                <ScienceReportCard :scienceReport="modal.item"
                                                   @update="modal.update"
                                                   :is-edit="false"/>
                            </template>
                        </ItemList>
                    </b-card-body>
                </b-tab>
                <b-tab title="Учетная запись" v-if="isAdmin">
                    <b-card-body>
                        <FieldInput ref="login"
                                    label="Логин"
                                    :label-cols="labelCols"
                                    :label-align="labelAlign"
                                    v-model="user.login"
                                    description="Пользователь будет создан при задании логина"
                                    invalid-feedback="Должно быть уникальным"
                                    :state="$v.user.login.$dirty  ? !$v.user.login.$error : null"
                                    :is-edit="isEditUser"/>
                        <FieldBoolean label="Заблокирован"
                                      :label-cols="labelCols"
                                      :label-align="labelAlign"
                                      v-model="user.locked"
                                      :is-edit="isEdit"/>
                        <field-group-checkbox label="Роли"
                                              name="userRoles"
                                              :label-cols="labelCols"
                                              :label-align="labelAlign"
                                              description=""
                                              valid-feedback=""
                                              invalid-feedback=""
                                              :options="dictionaries['role']"
                                              stacked
                                              :itemId="user.id"
                                              item-id-field="user_id"
                                              item-key-field="role_id"
                                              v-model="userRoles"
                                              :isEdit="isEdit"/>
                    </b-card-body>
                </b-tab>
                <b-tab title="Установить пароль" v-if="isAdmin">
                    <FieldInput label="Новый пароль"
                                :label-cols="labelCols"
                                :label-align="labelAlign"
                                type="password"
                                description="Сохраняется не пустой пароль"
                                valid-feedback=""
                                invalid-feedback=""
                                :state="$v.user.password.$dirty  ? !$v.user.password.$error : null"
                                v-model="user.password"
                                :is-edit="isEdit"/>
                    <FieldInput label="Повторить пароль"
                                :label-cols="labelCols"
                                :label-align="labelAlign"
                                type="password"
                                description="Проверка корректности ввода пароля"
                                :state="$v.repeatPassword.$dirty  ? !$v.repeatPassword.$error : null"
                                valid-feedback=""
                                invalid-feedback="Не совпадвает с паролем выше"
                                v-model="repeatPassword"
                                :is-edit="isEdit"/>
                </b-tab>
            </b-tabs>
        </b-form>
    </b-card>
</template>

<script>
    import storage from '../storage';
    import { GeneralController, UserController } from "../controllers";
    import { Employee, User, UserRole } from '../../shared/models';
    import ItemCardTab from "../components/ItemCardTab";
    import FieldGroupCheckbox from '../components/FieldGroupCheckbox.vue'
    import FieldInput from "../components/FieldInput";
    import FieldBoolean from "../components/FieldBoolean";
    import ItemList from "../components/ItemList.vue";
    import ScienceReportCard from './ScienceReportCard.vue';
    import { sameAs, requiredIf } from 'vuelidate/lib/validators'

    export default {
        name:          "EmployeeCard",
        components:    {
            ItemCardTab,
            FieldGroupCheckbox,
            FieldInput,
            FieldBoolean,
            ItemList,
            ScienceReportCard
        }
        ,
        props:         {
            employee:     { type: Object, default: function () { return new Employee(); } }
            , isEdit:     { type: Boolean, default: false }
            , labelCols:  { type: [ String, Number ], default: 3 }
            , labelAlign: { type: String, default: 'right' }
        }
        ,
        data:          function () {
            return {
                schemaManager:    storage.state.schemaManager
                , dictionaries:   {}
                , user:           new User()
                , repeatPassword: ''
                , userRoles:      []
                , localEmployee:  this.getLocalEmployee( this.employee )
                , alert:          { show: false, message: '' }
            };
        }
        , computed:    {
            schema: function () { return this.schemaManager.getSchemaById( 'employee' ); }
            , isEditUser() { return ( !this.localEmployee.user_id && this.isEdit ) }
            , computedEmployeeId() {
                return ( this.employee && this.employee.id ) || storage.emptyUID;
            }
            , showReport() {
                return ( storage.state.session && ( storage.state.session.isAnalytic() || storage.state.session.isDepartment() || storage.state.session.isAdmin() ) );
            }
            , isAdmin() {
                return ( storage.state.session && storage.state.session.isAdmin() );
            }
        }
        , validations: {
            user:             {
                login:    {
                    isUnique( value ) {
                        if ( !value ) { return ( true ); }

                        if ( !this.$refs.login || !this.$refs.login.isEdit ) { return ( true ); }
                        let filter = { login: value };
                        if ( this.user.id ) { filter.id = { $neq: this.user.id }; }
                        return ( GeneralController.checkUniqueField( 'user', filter ) );
                    }
                },
                locked:   {},
                password: {
                    required: requiredIf( function ( data ) { return ( !data.id && data.login ) } )
                },
            }
            , repeatPassword: {
                sameAs: sameAs( function ( data ) { return ( data.user.password ); } )
            }
        }
        , watch:       {
            employee: function ( value ) { this.localEmployee = this.getLocalEmployee( value ); }
        }
        , methods:     {
            touch() {
                let tabs = [ 'employeeTabInfo', 'employeeTabContact', 'employeeTabMember', 'employeeTabOrganization', 'employeeTabRelative' ];
                if ( this.isEdit ) {
                    tabs.forEach( ( item ) => this.$refs[ item ].touch() );
                }
                this.$v.user.$touch();
                this.$v.repeatPassword.$touch();
            }
            , reset() {
                let tabs = [ 'employeeTabInfo', 'employeeTabContact', 'employeeTabMember', 'employeeTabOrganization', 'employeeTabRelative' ];
                tabs.forEach( ( item ) => this.$refs[ item ].reset() );
                this.$v.user.$reset();
                this.$v.repeatPassword.$reset();
            }
            , pending() { return this.$v.user.$pending; }
            , checkValidity() {
                let state = !( this.$v.user.$pending || this.$v.user.$anyError );
                state     = state && !( this.$v.repeatPassword.$pending || this.$v.repeatPassword.$anyError );
                let tabs  = [ 'employeeTabInfo', 'employeeTabContact', 'employeeTabMember', 'employeeTabOrganization', 'employeeTabRelative' ];
                if ( this.isEdit ) {
                    state = state && tabs.reduce( ( agg, item ) => agg && this.$refs[ item ].checkValidity(), true );
                }
                return ( state );
            }
            , getLocalEmployee( value ) { return Object.assign( {}, value ); }
            , debounceSave: function ( e ) {
                this.touch();
                if ( !this.pending() ) { return this.save( e ); }

                let intervalId;
                intervalId = setInterval( () => {
                    if ( !this.pending() ) {
                        this.save( e );
                        clearInterval( intervalId );
                    }
                }, storage.debouncers.default );
            }
            , save() {
                this.show( 'Проверяем корректность данных', true );
                // this.touch();
                if ( !this.checkValidity() ) { return this.show( 'Не корректно заполнены данные...' ); }

                this.show( 'Идет сохранение...', true );

                let roles = this.userRoles.map( ( item ) => ( { id: item.role_id } ) );
                UserController.setEmployeeUserRoles( this.localEmployee, this.user, roles )
                    .then( ( data ) => {
                        // data.employee
                        this.localEmployee = new Employee( data.employee );
                        this.$emit( 'update', data.employee );

                        // data.user // locked, password
                        this.user = new User( data.user );

                        // data.roles
                        // this.userRoles = data.roles.map( ( item ) => ( { role_id: item.id } ) );
                        this.userRoles = data.roles;

                        this.repeatPassword = '';
                        this.show( 'Данные сохранены' );
                    } )
                    .catch( ( error ) => {
                        storage.logNetworkError( error );
                        this.show( 'Ошибка в работе сервера' )
                    } );
            }
            , cancel() {
                this.localEmployee = this.getLocalEmployee();
                this.reset();
                this.hide();
            }
            , show( message, timeSec = 5 ) {
                this.alert.message = message;
                this.alert.show    = timeSec
            }
            , hide() { this.alert.show = false; }
        }
        ,
        created:       function () {
            let user_id = this.employee.user_id || storage.emptyUID;

            storage.getDictionary( 'role' ).then( ( items ) => { this.dictionaries[ 'role' ] = items; } );
            storage.getDictionary( 'department' ).then( ( items ) => { this.dictionaries[ 'department' ] = items; } );
            storage.getDictionary( 'memberType' ).then( ( items ) => { this.dictionaries[ 'memberType' ] = items; } );


            return Promise.all( [
                GeneralController.getItems( 'userRole', { filter: { user_id } } )
                , GeneralController.getItemById( 'user', user_id )
            ] ).then( ( result ) => {
                this.userRoles = result[ 0 ].rows;
                if ( result[ 1 ] ) { this.user = new User( result[ 1 ] ); }

            } ).catch( ( error ) => {
                storage.logNetworkError( error );
                this.show( 'Ошибка загрузки данных', true );
            } );
        }
    }
</script>

<style scoped>

</style>
