/**
 * Created by User on 12.10.2019.
 */
'use strict';
import IEntity from "./i-entity.js";


export default class ParticipationResearchProgram extends IEntity {
    /**
     * @typedef {IEntityConfig} ParticipationResearchProgramConfig
     * @property {string} [science_report_id]
     * @property {string} [name]
     * @property {string} [research_program]
     */

    /**
     *
     * @param {ParticipationResearchProgramConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.science_report_id      = config.science_report_id;
        this.name                   = config.name;
        this.research_program       = config.research_program;
    }
}
