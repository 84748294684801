<template>
    <ItemList ref="list"
              :model="model"
              :is-add-item="isAddItem && isAdmin"
              :is-view-item="isViewItem"
              :is-edit-item="isEditItem && isAdmin"
              :is-delete-item="isDeleteItem && isAdmin"/>
</template>

<script>
    import storage from '../storage';
    import ItemList from '../components/ItemList.vue';

    export default {
        name: "ModelList"
        , components: { ItemList }
        , props: {
            isAddItem: { type: Boolean, default: true }
            , isViewItem: { type: Boolean, default: true }
            , isEditItem: { type: Boolean, default: true }
            , isDeleteItem: { type: Boolean, default: true }
        }
        , data: function () {
            return {
                schemaManager: storage.state.schemaManager
                , session: storage.state.session
                , model: this.$route.params.model
                , totalCount: 0
                , isBusy: false
                , items: []
            };
        }
        , watch: {
            $route: function ( to, from ) {
                this.model = to.params.model;
                this.$refs.list.refresh();
            }
        }
        , computed: {
            isAdmin() { return this.session.isAdmin(); }
            , isMember() { return this.session.isMember(); }
            , isAnalytic() { return this.session.isAnalytic(); }
        }
        , methods: {}
    }
</script>

<style scoped>

</style>
