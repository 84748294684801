/**
 * Created by User on 10.10.2019.
 */
'use strict';

import IEntity from "./i-entity.js";


export default class PopularSciencePublication extends IEntity {
    /**
     * @typedef {IEntityConfig} PopularSciencePublicationConfig
     * @property {string} [science_report_id]
     * @property {string} [name]
     * @property {boolean} [is_mass_media]
     * @property {string} [http_link]
     */

    /**
     *
     * @param {PopularSciencePublicationConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.science_report_id = config.science_report_id;
        this.name              = config.name;
        this.is_mass_media     = !!config.is_mass_media;
        this.http_link         = config.http_link;
    }
}
