<template>
    <div>
        <slot v-for="field in computedFields" :name="field.systemName" v-bind="field">
            <FieldInput v-if="field.type === 'uuid'"
                        type="text"
                        :state="$v.item[field.systemName].$dirty  ? !$v.item[field.systemName].$error : null"
                        @blur="$v.item[field.systemName].$touch()"
                        :ref="field.systemName"
                        :label="field.name"
                        :label-cols="labelCols"
                        :label-align="labelAlign"
                        :description="field.description"
                        :placeholder="field.placeholder"
                        :name="field.systemName"
                        v-model="item[field.systemName]"
                        :required="field.isRequired"
                        :valid-feedback="field.validFeedback"
                        :invalid-feedback="field.invalidFeedback"
                        :is-edit="field.isAddOnly ? isAddMode : isEdit"/>
            <FieldInput v-if="field.type === 'string'"
                        type="text"
                        :state="$v.item[field.systemName].$dirty  ? !$v.item[field.systemName].$error : null"
                        @blur="$v.item[field.systemName].$touch()"
                        :ref="field.systemName"
                        :label="field.name"
                        :label-cols="labelCols"
                        :label-align="labelAlign"
                        :description="field.description"
                        :placeholder="field.placeholder"
                        :name="field.systemName"
                        v-model="item[field.systemName]"
                        :required="field.isRequired"
                        :valid-feedback="field.validFeedback"
                        :invalid-feedback="field.invalidFeedback"
                        :is-edit="field.isAddOnly ? isAddMode : isEdit"/>
            <FieldInput v-if="field.type === 'password'"
                        type="text"
                        :state="$v.item[field.systemName].$dirty  ? !$v.item[field.systemName].$error : null"
                        @blur="$v.item[field.systemName].$touch()"
                        :ref="field.systemName"
                        :label="field.name"
                        :label-cols="labelCols"
                        :label-align="labelAlign"
                        :description="field.description"
                        :placeholder="field.placeholder"
                        :name="field.systemName"
                        v-model="item[field.systemName]"
                        :required="field.isRequired"
                        :valid-feedback="field.validFeedback"
                        :invalid-feedback="field.invalidFeedback"
                        :is-edit="field.isAddOnly ? isAddMode : isEdit"/>
            <FieldInput v-if="field.type === 'phone'"
                        type="tel"
                        :state="$v.item[field.systemName].$dirty  ? !$v.item[field.systemName].$error : null"
                        @blur="$v.item[field.systemName].$touch()"
                        :ref="field.systemName"
                        :label="field.name"
                        :label-cols="labelCols"
                        :label-align="labelAlign"
                        :description="field.description"
                        :placeholder="field.placeholder"
                        :name="field.systemName"
                        v-model="item[field.systemName]"
                        :required="field.isRequired"
                        :valid-feedback="field.validFeedback"
                        :invalid-feedback="field.invalidFeedback"
                        :is-edit="field.isAddOnly ? isAddMode : isEdit"/>
            <FieldInput v-if="field.type === 'url'"
                        type="url"
                        :state="$v.item[field.systemName].$dirty  ? !$v.item[field.systemName].$error : null"
                        @blur="$v.item[field.systemName].$touch()"
                        :ref="field.systemName"
                        :label="field.name"
                        :label-cols="labelCols"
                        :label-align="labelAlign"
                        :description="field.description"
                        :placeholder="field.placeholder"
                        :name="field.systemName"
                        v-model="item[field.systemName]"
                        :required="field.isRequired"
                        :valid-feedback="field.validFeedback"
                        :invalid-feedback="field.invalidFeedback"
                        :is-edit="field.isAddOnly ? isAddMode : isEdit"/>
            <FieldInput v-if="field.type === 'email'"
                        type="email"
                        :state="$v.item[field.systemName].$dirty  ? !$v.item[field.systemName].$error : null"
                        @blur="$v.item[field.systemName].$touch()"
                        :ref="field.systemName"
                        :label="field.name"
                        :label-cols="labelCols"
                        :label-align="labelAlign"
                        :description="field.description"
                        :placeholder="field.placeholder"
                        :name="field.systemName"
                        v-model="item[field.systemName]"
                        :required="field.isRequired"
                        :valid-feedback="field.validFeedback"
                        :invalid-feedback="field.invalidFeedback"
                        :is-edit="field.isAddOnly ? isAddMode : isEdit"/>
            <FieldInput v-if="field.type === 'number'"
                        type="number"
                        :state="$v.item[field.systemName].$dirty  ? !$v.item[field.systemName].$error : null"
                        @blur="$v.item[field.systemName].$touch()"
                        :ref="field.systemName"
                        :label="field.name"
                        :label-cols="labelCols"
                        :label-align="labelAlign"
                        :description="field.description"
                        :placeholder="field.placeholder"
                        :name="field.systemName"
                        v-model="item[field.systemName]"
                        :required="field.isRequired"
                        :valid-feedback="field.validFeedback"
                        :invalid-feedback="field.invalidFeedback"
                        :is-edit="field.isAddOnly ? isAddMode : isEdit"/>
            <FieldDate v-if="field.type === 'date'"
                       :state="$v.item[field.systemName].$dirty  ? !$v.item[field.systemName].$error : null"
                       @blur="$v.item[field.systemName].$touch()"
                       :ref="field.systemName"
                       :label="field.name"
                       :label-cols="labelCols"
                       :label-align="labelAlign"
                       :description="field.description"
                       :placeholder="field.placeholder"
                       :name="field.systemName"
                       v-model="item[field.systemName]"
                       :required="field.isRequired"
                       :valid-feedback="field.validFeedback"
                       :invalid-feedback="field.invalidFeedback"
                       :is-edit="field.isAddOnly ? isAddMode : isEdit"/>
            <FieldTextarea v-if="field.type === 'text'"
                           :state="$v.item[field.systemName].$dirty  ? !$v.item[field.systemName].$error : null"
                           @blur="$v.item[field.systemName].$touch()"
                           :ref="field.systemName"
                           :label="field.name"
                           :label-cols="labelCols"
                           :label-align="labelAlign"
                           :description="field.description"
                           :placeholder="field.placeholder"
                           :name="field.systemName"
                           v-model="item[field.systemName]"
                           :required="field.isRequired"
                           :rows="field.rows"
                           :max-rows="field.maxRows"
                           :valid-feedback="field.validFeedback"
                           :invalid-feedback="field.invalidFeedback"
                           :is-edit="field.isAddOnly ? isAddMode : isEdit"/>
            <FieldBoolean v-if="field.type === 'boolean'"
                          :state="$v.item[field.systemName].$dirty  ? !$v.item[field.systemName].$error : null"
                          :ref="field.systemName"
                          :label="field.name"
                          :label-cols="labelCols"
                          :label-align="labelAlign"
                          :description="field.description"
                          :name="field.systemName"
                          v-model="item[field.systemName]"
                          :required="field.isRequired"
                          :valid-feedback="field.validFeedback"
                          :invalid-feedback="field.invalidFeedback"
                          :is-edit="field.isAddOnly ? isAddMode : isEdit"/>
            <FieldSelect v-if="field.type === 'select'"
                         :state="$v.item[field.systemName].$dirty  ? !$v.item[field.systemName].$error : null"
                         :ref="field.systemName"
                         :label="field.name"
                         :label-cols="labelCols"
                         :label-align="labelAlign"
                         :description="field.description"
                         :name="field.systemName"
                         :options="dictionaries[field.lookupSchemaId]"
                         v-model="item[field.systemName]"
                         :required="field.isRequired"
                         :valid-feedback="field.validFeedback"
                         :invalid-feedback="field.invalidFeedback"
                         :is-edit="field.isAddOnly ? isAddMode : isEdit"/>
            <field-lookup v-if="field.type === 'lookup'"
                          :state="$v.item[field.systemName].$dirty  ? !$v.item[field.systemName].$error : null"
                          :ref="field.systemName"
                          :label="field.name"
                          :label-cols="labelCols"
                          :label-align="labelAlign"
                          :description="field.description"
                          :name="field.systemName"
                          :lookup-schema-id="field.lookupSchemaId"
                          :lookup-search-fields="field.lookupSearchFields"
                          v-model="item[field.systemName]"
                          :placeholder="'Выберите значение...'"
                          :required="field.isRequired"
                          :valid-feedback="field.validFeedback"
                          :invalid-feedback="field.invalidFeedback"
                          :is-edit="field.isAddOnly ? isAddMode : isEdit"/>
        </slot>
        <slot/>
    </div>
</template>

<script>
    import storage from '../storage';
    import FieldInput from "./FieldInput";
    import FieldTextarea from "./FieldTextarea";
    import FieldBoolean from "./FieldBoolean";
    import FieldSelect from "./FieldSelect";
    import FieldLookup from "./FieldLookup";
    import FieldDate from "./FieldDate";
    import { requiredIf } from 'vuelidate/lib/validators'
    import { GeneralController } from "../controllers";

    export default {
        name:          "ItemCardFieldList"
        , components:  { FieldSelect, FieldBoolean, FieldTextarea, FieldInput, FieldLookup, FieldDate }
        , props:       {
            title:        { type: String, default: 'Без имени' }
            , schemaId:   { type: String, required: true }
            , fields:     { type: Array, default: function () {return [];} }
            , item:       { type: Object, required: true }
            , isEdit:     { type: Boolean, default: false }
            , labelCols:  { type: [ String, Number ], default: 3 }
            , labelAlign: { type: String, default: 'right' }
        }, data:       function () {
            return {
                schemaManager:             storage.state.schemaManager
                , dictionaries:            {}
            };
        }, computed:   {
            schema:                       function () { return this.schemaManager.getSchemaById( this.schemaId ); },
            computedFields:               function () {
                if ( this.fields.length !== 0 ) {
                    return this.fields
                        .map( ( item ) => this.schema.fields.find( ( elm ) => elm.systemName === item ) )
                        .filter( ( item ) => !!item );
                    // .filter( ( item ) => item.isCard );
                }
                return this.schema.fields.filter( ( item ) => item.isCard );
            }
            , isAddMode:                  function () { return ( !this.item.id && this.isEdit ); }
            , isEditMode:                 function () { return ( this.item.id && this.isEdit ) }
            , pending:                    function () { return this.$v.item.$pending; }
            , invalid:                    function () { return this.$v.item.$invalid; }
        }
        , validations: function () {
            let item = {};
            this.computedFields.forEach( ( field ) => {
                item[ field.systemName ]          = {};
                item[ field.systemName ].required = requiredIf( () => field.isRequired );
                if ( field.isUnique ) {
                    item[ field.systemName ].isUnique = function ( value ) {
                        if ( value === '' ) { return true; }
                        if ( !this.$refs[ field.systemName ] || !this.$refs[ field.systemName ][ 0 ].isEdit ) { return true; }
                        let filter = {
                            id:                     { $neq: this.item.id }
                            , [ field.systemName ]: value
                        };

                        return ( GeneralController.checkUniqueField( this.schema.id, filter ) );
                    }
                }
            } );
            return { item };
        }
        , methods:     {
            touch() { this.$v.item.$touch(); }
            , reset() { this.$v.item.$reset(); }
            , checkValidity() { return !( this.$v.item.$pending || this.$v.item.$anyError ); }
        }
        , created:     function () {
            this.computedFields.forEach( ( field ) => {
                if ( field.type !== 'select' ) { return; }

                storage.getDictionary( field.lookupSchemaId )
                    .then( ( items ) => {
                        this.$set( this.dictionaries, field.lookupSchemaId, items );
                    } );
            } );
        }
    }
</script>

<style scoped>

</style>
