/**
 * Created by User on 29.09.2019.
 */

'use strict';
import Schema from "../models/schema.js";


export default class SchemaManager {
    constructor( config ) {
        this.schemas = {};
    }

    /**
     *
     * @param {string} schemaId
     * @return {Schema}
     */
    getSchemaById( schemaId ) { return this.schemas[ schemaId ]; }

    /**
     *
     * @param {Schema|SchemaConfig} config
     */
    addSchema( config ) {
        if ( this.schemas[ config.id ] ) { throw new Error( `Схама с таким id (${ config.id }) уже есть` ); }
        let schema                = new Schema( config );
        this.schemas[ schema.id ] = schema;
    }

    load( schemas = [] ) {
        this.schemas = {};
        schemas.forEach( ( schema ) => this.addSchema( schema ) );
    }
}

