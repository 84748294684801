<template>
    <OlapDepartmentView :data="data"
                        v-model="year"
                        :years="years"
                        :name="schema.name"
    />
</template>

<script>
    import storage from '../storage.js';
    import { ReportController } from '../controllers/index.js'
    import OlapDepartmentView from '../components/OlapDepartmentView.vue';

    export default {
        name:         "ViewReportGroup"
        , components: { OlapDepartmentView }
        , data:       function () {
            return {
                year:       storage.state.defaultViewReportYear
                , schemaId: this.$route.params.schemaId
                , reports:  []
                , data:     []
                , years:    []
            };
        }
        , computed:   {
            schema() { return storage.state.schemaManager.getSchemaById( this.schemaId ); }
        }
        , watch:      {
            year: function ( value ) {
                this.getData( this.schemaId, value );
            }
        }
        , methods:    {
            getData( schemaId, year ) {
                let filter = { year };
                ReportController.getOlapSchemeData( schemaId, filter )
                    .then( ( items ) => {
                        this.data = items
                            .filter( ( item ) => {
                                if ( storage.state.session.isAnalytic() ) {
                                    return true;
                                } else if ( storage.state.session && storage.state.session.isDepartment() ) {
                                    return ( item.department_id === storage.state.session.employee.department_id );
                                }
                                return false;
                            } )
                    } );
            }
        }
        , created() {
            this.getData( this.schemaId, this.year );

            ReportController.getReportYears()
                .then( ( items ) => {
                    this.years = items.map( ( item ) => ( { value: item.year, text: `${ item.year } год` } ) );
                } );
        }
        , beforeRouteUpdate( to, from, next ) {
            this.schemaId = to.params.schemaId;
            this.getData( this.schemaId, this.year );
            next();
        }
    }
</script>

<style scoped>

</style>
