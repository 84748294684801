import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import router from './router';

import 'vue-search-select/dist/VueSearchSelect.css'
import './custom.scss';
import 'whatwg-fetch';

Vue.config.productionTip = false;

Vue.use( BootstrapVue );
Vue.use( Vuelidate );

new Vue( {
    router,
    render: h => h( App )
} ).$mount( '#app' );
