/**
 * Created by User on 12.10.2019.
 */
'use strict';

import IEntity from "./i-entity.js";


export default class ParticipationFederalProgram extends IEntity {
    /**
     * @typedef {IEntityConfig} ParticipationFederalProgramConfig
     * @property {string} [science_report_id]
     * @property {string} [name]
     * @property {string} [direction]
     * @property {string} [project_name]
     * @property {string} [project_role]
     * @property {string} [project_result]
     */

    /**
     *
     * @param {ParticipationFederalProgramConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.science_report_id = config.science_report_id;
        this.name              = config.name;
        this.direction         = config.direction;
        this.project_name      = config.project_name;
        this.project_role      = config.project_role;
        this.project_result    = config.project_result;
    }
}
