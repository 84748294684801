/**
 * Created by User on 09.11.2019.
 */
'use strict';
import IEntity from "./i-entity.js";

export default class FgosName extends IEntity {
    /**
     * @typedef {IEntityConfig} FgosNameConfig
     * @property {string} [name]
     * @property {string} [fgos_type_id]
     * @property {boolean} [is_end]
     * @property {string} [doc_link]
     */

    /**
     *
     * @param {FgosNameConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.name         = config.name;
        this.fgos_type_id = config.fgos_type_id;
        this.is_end       = !!config.is_end;
        this.doc_link     = config.doc_link;
    }
}
