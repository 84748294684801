/**
 * Created by User on 10.10.2019.
 */
'use strict';

import IEntity from "./i-entity.js";


export default class PerformanceInnovationSite extends IEntity {
    /**
     * @typedef {IEntityConfig} PerformanceInnovationSiteConfig
     * @property {string} [science_report_id]
     * @property {string} [name]
     * @property {string} [innovation_site_type_id]
     */

    /**
     *
     * @param {PerformanceInnovationSiteConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.science_report_id       = config.science_report_id;
        this.name                    = config.name;
        this.innovation_site_type_id = config.innovation_site_type_id;
    }
}
