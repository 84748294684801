<template>
    <div class="container">
        <header v-if="state.session && state.session.user">
            <b-navbar toggleable="lg" type="light" variant="light">
                <b-navbar-brand :to="{name: 'home'}">
                    <img src="/gerb.png" class="d-inline-block align-top" style="height: 30px" alt="Герб">
                    Российская академия образования
                </b-navbar-brand>
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav>
                        <b-nav-item-dropdown text="Отчеты" v-if="state.session.isMember()">
                            <b-dropdown-item :to="{ name: 'science-report-list'}">Отчет о
                                научной деятельности
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                        <b-nav-item-dropdown text="Данные" v-if="state.session.isViewer() || state.session.isDepartment()">
                            <b-dropdown-item
                                    :to="{ name: 'data-report-status', params:{model: 'dataScienceReportStatus', isAddItem: false, isViewItem: false, isEditItem: false, isDeleteItem: false}}">
                                Статус предоставления отчета о научной деятельности
                            </b-dropdown-item>
                            <b-dropdown-item
                                    :to="{ name: 'data-view-list', params:{model: 'dataMonograph', isAddItem: false, isViewItem: true, isEditItem: false, isDeleteItem: false}}">
                                Монографии
                            </b-dropdown-item>
                            <b-dropdown-item
                                    :to="{ name: 'data-view-list', params:{model: 'dataSchoolBook', isAddItem: false, isViewItem: true, isEditItem: false, isDeleteItem: false}}">
                                Учебники и учебные пособия
                            </b-dropdown-item>
                            <b-dropdown-item
                                    :to="{ name: 'data-view-list', params:{model: 'dataRecommendationPublication', isAddItem: false, isViewItem: true, isEditItem: false, isDeleteItem: false}}">
                                Методические пособия и рекомендации
                            </b-dropdown-item>
                            <b-dropdown-item
                                    :to="{ name: 'data-view-list', params:{model: 'dataSciencePublication', sciencePublicationFilter: true, isAddItem: false, isViewItem: true, isEditItem: false, isDeleteItem: false}}">
                                Статьи
                            </b-dropdown-item>
                            <b-dropdown-item
                                    :to="{ name: 'data-view-list', params:{model: 'dataRidResult', isAddItem: false, isViewItem: true, isEditItem: false, isDeleteItem: false}}">
                                РИД
                            </b-dropdown-item>
                            <b-dropdown-item
                                    :to="{ name: 'data-view-list', params:{model: 'dataOtherPublication', isAddItem: false, isViewItem: true, isEditItem: false, isDeleteItem: false}}">
                                Другое
                            </b-dropdown-item>
                            <b-dropdown-item
                                    :to="{ name: 'data-view-list', params:{model: 'dataParticipationInWorkEducationSystem', isAddItem: false, isViewItem: true, isEditItem: false, isDeleteItem: false}}">
                                Участие в работе органов
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                        <b-nav-item-dropdown text="Экспертиза"
                                             v-if="state.session.isMember() || state.session.isExpertise()">
                            <b-dropdown-item :to="{ name: 'fgos-name-list'}"
                                             v-if="state.session.isExpertise()"
                            >ФГОС
                            </b-dropdown-item>
                            <b-dropdown-item :to="{ name: 'fgos-expertise-list'}"
                                             v-if="state.session.isMember()"
                            >Экспертные заключения ФГОС
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                        <b-nav-item-dropdown text="Аналитика"
                                             v-if="state.session.isAnalytic() || state.session.isDepartment()">
                            <b-dropdown-item
                                             :to="{ name: 'view-report-statistic-index'}">
                                Сводный данные - Статистика по Web Of Science, Scopus
                            </b-dropdown-item>
                            <b-dropdown-item v-for="report in getReports()" v-bind:key="report.id"
                                             :to="{ name: 'view-report-group', params: { schemaId: report.id }}">
                                Сводный данные - {{ report.name }}
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                        <b-nav-item-dropdown text="Отделение" v-if="state.session.isDepartment()">
                            <b-dropdown-item
                                    :to="{name: 'employee-list'}">
                                Члены отделения
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                        <b-nav-item-dropdown text="Справочники" v-if="state.session.isAdmin()">
                            <b-dropdown-item :to="{name: 'model-list', params: { model: 'department'}}">Отделения
                            </b-dropdown-item>
                            <b-dropdown-item
                                    :to="{name: 'model-list', params:{model: 'publicationProjectType'}}">
                                Типы проектов
                            </b-dropdown-item>
                            <b-dropdown-item
                                    :to="{name: 'model-list', params:{model: 'eduType'}}">
                                Цифровые образовательные среды
                            </b-dropdown-item>
                            <b-dropdown-item
                                    :to="{name: 'model-list', params:{model: 'innovationSiteType'}}">
                                Типы инновационных площадок
                            </b-dropdown-item>
                            <b-dropdown-item
                                    :to="{name: 'model-list', params:{model: 'workTrainingType'}}">
                                Типы подготовок
                            </b-dropdown-item>
                            <b-dropdown-item
                                    :to="{name: 'model-list', params:{model: 'workDefendedType'}}">
                                Типы защит
                            </b-dropdown-item>
                            <b-dropdown-item
                                    :to="{name: 'model-list', params:{model: 'expertCouncilVak'}}">
                                Экспертные советы ВАК
                            </b-dropdown-item>
                            <b-dropdown-item
                                    :to="{name: 'model-list', params:{model: 'organizationActivity'}}">
                                Конкурсы организаций
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                        <b-nav-item-dropdown text="Администрирование" v-if="state.session.isAdmin()">
                            <b-dropdown-item :to="{name: 'model-list', params: { model: 'memberType'}}">Членства в РАО
                            </b-dropdown-item>
                            <b-dropdown-item :to="{name: 'employee-list'}">Члены РАО</b-dropdown-item>
                            <b-dropdown-item :to="{name: 'user-list'}">Пользователи
                            </b-dropdown-item>
                            <b-dropdown-item :to="{name: 'science-report-remove'}">Удалить отчет о научной деятельности
                            </b-dropdown-item>
                        </b-nav-item-dropdown>
                    </b-navbar-nav>
                    <b-navbar-nav class="ml-auto">
                        <b-nav-item :to="{name: 'user-profile'}">{{ displayUserName }}</b-nav-item>
                        <b-nav-form>
                            <Logout/>
                        </b-nav-form>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </header>
        <b-alert variant="danger" v-model="error.show">{{ error.message }}</b-alert>
        <router-view/>
    </div>
</template>

<script>
    import storage from './storage';
    import Logout from './components/Logout.vue';

    export default {
        name: 'app'
        , components: { Logout }
        , data: function () {
            return {
                state: storage.state,
                error: {
                    show: false,
                    message: ''
                }
            };
        }
        , computed: {
            displayUserName: function () {
                let defaultName = 'Пользователь';

                let session = this.state.session;
                if ( !session ) { return ( defaultName ); }

                let employee = session.employee;
                let user = session.user;

                let fio = employee && employee.shortName();

                let login = ( user && user.login );

                return ( fio || login || defaultName );
            }
        }
        , methods: {
            getReports() { return storage.getReportList(); }
        }
    }
</script>

<style scoped>
</style>
