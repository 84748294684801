/**
 * Created by User on 10.10.2019.
 */
'use strict';
import IEntity from "./i-entity.js";


export default class GeneralCitation extends IEntity {
    /**
     * @typedef {IEntityConfig} GeneralCitationConfig
     * @property {string} [science_report_id]
     * @property {number} [web_of_science]
     * @property {number} [web_of_science_index_hirsch]
     * @property {number} [web_of_science_citation_past_5_years]
     * @property {number} [scopus]
     * @property {number} [scopus_index_hirsch]
     * @property {number} [scopus_citation_past_5_years]
     * @property {number} [vak]
     * @property {number} [vak_index_hirsch]
     * @property {number} [vak_citation_past_5_years]
     * @property {number} [rinc]
     * @property {number} [rinc_index_hirsch]
     * @property {number} [rinc_citation_past_5_years]
     * @property {number} [core_rinc]
     * @property {number} [core_rinc_index_hirsch]
     * @property {number} [core_rinc_citation_past_5_years]
     * @property {number} [google_scholar]
     * @property {number} [google_scholar_index_hirsch]
     * @property {number} [google_scholar_citation_past_5_years]
     * @property {number} [erich]
     * @property {number} [erich_index_hirsch]
     * @property {number} [erich_citation_past_5_years]
     * @property {number} [other]
     * @property {number} [other_index_hirsch]
     * @property {number} [other_citation_past_5_years]
     */

    /**
     *
     * @param {GeneralCitationConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.science_report_id = config.science_report_id;

        this.web_of_science                       = config.web_of_science;
        this.web_of_science_index_hirsch          = config.web_of_science_index_hirsch;
        this.web_of_science_citation_past_5_years = config.web_of_science_citation_past_5_years;

        this.scopus                       = config.scopus;
        this.scopus_index_hirsch          = config.scopus_index_hirsch;
        this.scopus_citation_past_5_years = config.scopus_citation_past_5_years;

        this.vak                       = config.vak;
        this.vak_index_hirsch          = config.vak_index_hirsch;
        this.vak_citation_past_5_years = config.vak_citation_past_5_years;

        this.rinc                       = config.rinc;
        this.rinc_index_hirsch          = config.rinc_index_hirsch;
        this.rinc_citation_past_5_years = config.rinc_citation_past_5_years;

        this.core_rinc                       = config.core_rinc;
        this.core_rinc_index_hirsch          = config.core_rinc_index_hirsch;
        this.core_rinc_citation_past_5_years = config.core_rinc_citation_past_5_years;

        this.google_scholar                       = config.google_scholar;
        this.google_scholar_index_hirsch          = config.google_scholar_index_hirsch;
        this.google_scholar_citation_past_5_years = config.google_scholar_citation_past_5_years;

        this.erich                       = config.erich;
        this.erich_index_hirsch          = config.erich_index_hirsch;
        this.erich_citation_past_5_years = config.erich_citation_past_5_years;

        this.other                       = config.other;
        this.other_index_hirsch          = config.other_index_hirsch;
        this.other_citation_past_5_years = config.other_citation_past_5_years;
    }

    /**
     * Из пустых строк делает null
     */
    fix() {
        let names = [ 'web_of_science', 'scopus', 'vak', 'rinc', 'core_rinc', 'google_scholar', 'erich', 'other' ];
        names.forEach( ( name ) => {
            if ( !this[ name ] ) { this[ name ] = null; }
            if ( !this[ `${ name }_index_hirsch` ] ) { this[ `${ name }_index_hirsch` ] = null; }
            if ( !this[ `${ name }_citation_past_5_years` ] ) { this[ `${ name }_citation_past_5_years` ] = null; }
        } );
    }
}
