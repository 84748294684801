<template>
    <div>
        <b-row>
            <b-col cols="2">
                <b-form-select class="border-success text-success"
                               :options="dictionaries.years"
                               v-model="filter.year"
                               @change="update"></b-form-select>
            </b-col>
            <b-col cols="5">
                <FieldSelect label="Членство в РАО"
                             label-cols="5"
                             label-align='right'
                             :options="dictionaries.memberTypes"
                             v-model="filter.member_type_id"
                             @change="update"
                             is-edit
                />
            </b-col>
            <b-col cols="5" v-if="isViewer">
                <FieldSelect label="Отделение"
                             label-cols="5"
                             label-align='right'
                             :options="dictionaries.departments"
                             v-model="filter.department_id"
                             @change="update"
                             is-edit
                />
            </b-col>
        </b-row>
        <b-row v-if="sciencePublicationFilter">
            <b-col>
                <b-form-checkbox @change="updateIndex('is_web_of_science', $event)">Web of Science</b-form-checkbox>
                <b-form-checkbox @change="updateIndex('is_scopus', $event)">Scopus</b-form-checkbox>
            </b-col>
            <b-col>
                <b-form-checkbox @change="updateIndex('is_vak', $event)">ВАК</b-form-checkbox>
                <b-form-checkbox @change="updateIndex('is_rinc', $event)">РИНЦ</b-form-checkbox>
            </b-col>
            <b-col>
                <b-form-checkbox @change="updateIndex('is_core_rinc', $event)">ядро РИНЦ</b-form-checkbox>
                <b-form-checkbox @change="updateIndex('is_google_scholar', $event)">Google Scholar</b-form-checkbox>
            </b-col>
            <b-col>
                <b-form-checkbox @change="updateIndex('is_erich', $event)">ERICH</b-form-checkbox>
                <b-form-checkbox @change="updateIndex('is_other', $event)">Прочие</b-form-checkbox>
            </b-col>
        </b-row>
        <ItemList :model="model"
                  ref="table"
                  export-word
                  :filter="filter"
                  :is-add-item="false"
                  :is-view-item="true"
                  :is-edit-item="false"
                  :is-delete-item="false"/>
    </div>
</template>

<script>
    import ItemList from "../components/ItemList.vue";
    import { ReportController } from '../controllers/index.js'
    import storage from "../storage.js";
    import FieldSelect from "../components/FieldSelect.vue";

    export default {
        name: "DataView"
        , components: { ItemList, FieldSelect }
        , props: {
            model: { type: String, required: true },
            sciencePublicationFilter: { type: Boolean, default: false }
        }
        , data() {
            return {
                isViewer: storage.state.session.isViewer(),
                isDepartment: storage.state.session.isDepartment(),
                filter: {
                    year: storage.state.defaultViewReportYear,
                    member_type_id: undefined,
                    department_id: undefined,
                }
                , dictionaries: { years: [], departments: [], memberTypes: [] }
            };
        }
        , methods: {
            update: function () {
                if ( !this.filter.department_id ) { this.filter.department_id = undefined; }
                if ( !this.filter.member_type_id ) { this.filter.member_type_id = undefined; }

                this.$refs.table.refresh();
            },
            updateIndex( name, value ) {
                this.filter[ name ] = value ? value : undefined;
                this.$refs.table.refresh();
            }
        }
        , beforeRouteUpdate( to, from, next ) {
            if ( this.sciencePublicationFilter ) {
                delete this.filter.is_web_of_science;
                delete this.filter.is_scopus;
                delete this.filter.is_vak;
                delete this.filter.is_rinc;
                delete this.filter.is_core_rinc;
                delete this.filter.is_google_scholar;
                delete this.filter.is_erich;
                delete this.filter.is_other;
            }

            this.$refs.table.refresh();
            next();
        }
        , created: function () {
            storage.getDictionary( 'department' ).then( ( items ) => { this.dictionaries.departments = items; } );
            storage.getDictionary( 'memberType' ).then( ( items ) => { this.dictionaries.memberTypes = items; } );

            if ( this.isDepartment ) { this.filter.department_id = storage.state.session.employee.department_id; }

            ReportController.getReportYears()
                .then( ( items ) => {
                    this.dictionaries.years = items.map( ( item ) => ( {
                        value: item.year,
                        text: `${ item.year } год`
                    } ) );
                } );
        }
    }
</script>

<style scoped>

</style>