<template>
    <b-card no-body>
        <div class="text-center">
            <h3>Уважаемый коллега,<br/>просим Вас проверить и уточнить информацию о себе ниже.</h3>
        </div>
        <b-alert v-model="alert.show" fade>{{ alert.message }}</b-alert>
        <b-form @submit.stop.prevent="debounceSave" novalidate>
            <b-card-body>
                <b-button-group>
                    <b-button v-if="!isEdit"
                              @click="isEdit = !isEdit"
                              variant="outline-secondary">Редактировать
                    </b-button>
                    <b-button v-if="isEdit"
                              type="submit"
                              variant="outline-secondary">Сохранить
                    </b-button>
                    <b-button v-if="isEdit"
                              @click="cancel()"
                              variant="outline-secondary">Отменить
                    </b-button>
                </b-button-group>
            </b-card-body>
            <b-tabs card>
                <ItemCardTab title="Профиль"
                             ref="employeeTabInfo"
                             schema-id="employee"
                             :fields="['lastname', 'firstname', 'middlename', 'department_id', 'academic_degree', 'academic_rank']"
                             :dictionaries="dictionaries"
                             :item="employee"
                             :label-cols="labelCols"
                             :label-align="labelAlign"
                             :is-edit="isEditEmployee"/>
                <ItemCardTab title="Контакты"
                             ref="employeeTabContact"
                             schema-id="employee"
                             :fields="['email', 'mobile_phone', 'home_phone', 'registration_address', 'post_address']"
                             :dictionaries="dictionaries"
                             :item="employee"
                             :label-cols="labelCols"
                             :label-align="labelAlign"
                             :is-edit="isEditEmployee"/>
                <ItemCardTab title="РАО"
                             ref="employeeTabMember"
                             schema-id="employee"
                             :fields="['member_info_rao', 'title_info_rao', 'member_info_other']"
                             :dictionaries="dictionaries"
                             :item="employee"
                             :label-cols="labelCols"
                             :label-align="labelAlign"
                             :is-edit="isEditEmployee"/>
                <ItemCardTab title="Место работы"
                             ref="employeeTabOrganization"
                             schema-id="employee"
                             :fields="['work_organization', 'work_position', 'work_additional_organization', 'work_additional_position']"
                             :dictionaries="dictionaries"
                             :item="employee"
                             :label-cols="labelCols"
                             :label-align="labelAlign"
                             :is-edit="isEditEmployee"/>
                <ItemCardTab title="Контактные лица"
                             ref="employeeTabRelative"
                             schema-id="employee"
                             :fields="['contact_person', 'contact_person_phone', 'contact_person_email']"
                             :dictionaries="dictionaries"
                             :item="employee"
                             :label-cols="labelCols"
                             :label-align="labelAlign"
                             :is-edit="isEditEmployee"/>
                <b-tab title="Изменить пароль">
                    <FieldInput label="Пользователь"
                                :label-cols="labelCols"
                                :label-align="labelAlign"
                                type="text"
                                description=""
                                valid-feedback=""
                                invalid-feedback=""
                                v-model="user.login"
                                :is-edit="false"/>
                    <FieldInput label="Старый пароль"
                                :label-cols="labelCols"
                                :label-align="labelAlign"
                                type="password"
                                description=""
                                valid-feedback=""
                                invalid-feedback=""
                                :state="$v.form.oldPassword.$dirty  ? !$v.form.oldPassword.$error : null"
                                v-model="form.oldPassword"
                                :is-edit="isEdit"/>
                    <FieldInput label="Новый пароль"
                                :label-cols="labelCols"
                                :label-align="labelAlign"
                                type="password"
                                description="Сохраняется не пустой пароль"
                                valid-feedback=""
                                invalid-feedback=""
                                :state="$v.form.password.$dirty  ? !$v.form.password.$error : null"
                                v-model="form.password"
                                :is-edit="isEdit"/>
                    <FieldInput label="Повторить пароль"
                                :label-cols="labelCols"
                                :label-align="labelAlign"
                                type="password"
                                description="Проверка корректности ввода пароля"
                                :state="$v.form.repeatPassword.$dirty  ? !$v.form.repeatPassword.$error : null"
                                valid-feedback=""
                                invalid-feedback="Не совпадвает с паролем выше"
                                v-model="form.repeatPassword"
                                :is-edit="isEdit"/>
                </b-tab>
            </b-tabs>
        </b-form>
    </b-card>
</template>

<script>
    import storage from '../storage';
    import { GeneralController, UserController } from "../controllers";
    import { Employee } from "../../shared/models";
    import ItemCardTab from "../components/ItemCardTab";
    import FieldInput from "../components/FieldInput.vue";
    import { debounce } from "debounce";
    import { required, sameAs, requiredUnless, requiredIf, maxLength } from 'vuelidate/lib/validators'

    export default {
        name:          "UserProfile"
        , components:  { ItemCardTab, FieldInput }
        , data:        function () {
            return {
                schemaManager:  storage.state.schemaManager
                , labelCols:    3
                , labelAlign:   'right'
                , dictionaries: {}
                , user:         storage.state.session.user
                , employee:     storage.state.session.getEmployee()
                , form:         {
                    oldPassword:      ''
                    , password:       ''
                    , repeatPassword: ''
                }
                , alert:        {
                    message: '',
                    show:    false
                }
                , isEdit:       false
            };
        }
        , validations: {
            form: {
                oldPassword:      {
                    required: requiredIf( ( data ) => {
                        return data.password !== '';
                    } )
                }
                , password:       {}
                , repeatPassword: {
                    sameAs: sameAs( 'password' )
                }
            }
        }
        , computed:    {
            isEditEmployee: function () { return this.employee.id && this.isEdit; }
        }
        , methods:     {
            touch() {
                let tabs = [ 'employeeTabInfo', 'employeeTabContact', 'employeeTabMember', 'employeeTabOrganization', 'employeeTabRelative' ];
                if ( this.isEditEmployee ) {
                    tabs.forEach( ( item ) => this.$refs[ item ].touch() );
                }
                this.$v.form.$touch();
            }
            , reset() {
                let tabs = [ 'employeeTabInfo', 'employeeTabContact', 'employeeTabMember', 'employeeTabOrganization', 'employeeTabRelative' ];
                tabs.forEach( ( item ) => this.$refs[ item ].reset() );
                this.$v.form.$reset();
            }
            , checkValidity() {
                let state = !( this.$v.form.$pending || this.$v.form.$anyError );
                let tabs  = [ 'employeeTabInfo', 'employeeTabContact', 'employeeTabMember', 'employeeTabOrganization', 'employeeTabRelative' ];
                if ( this.isEditEmployee ) {
                    state = state && tabs.reduce( ( agg, item ) => agg && this.$refs[ item ].checkValidity(), true );
                }
                return ( state );
            }
            , debounceSave: debounce( function ( e ) { this.save() }, storage.debouncers.default )
            , save() {
                this.show( 'Проверка формы...', true );
                this.touch();
                if ( !this.checkValidity() ) {
                    this.show( 'Найдены ошибки, пожалуйста, исправьте их.' );
                    return;
                }

                this.show( 'Подождите идет сохранение...', true );

                let promises = [];
                if ( this.isEditEmployee ) {
                    promises.push( GeneralController.updateItemById( 'employee', this.employee.id, this.employee ) );
                } else { promises.push( Promise.resolve( null ) ); }

                if ( this.form.password !== '' ) {
                    promises.push( UserController.changePassword( this.form.oldPassword, this.form.password ) );
                }

                Promise.all( promises )
                    .then( ( data ) => {
                        let message = '';
                        if ( this.isEditEmployee ) {
                            if ( data[ 0 ] ) {
                                message                        = 'Данные профиля обновлены.';
                                storage.state.session.employee = new Employee( data[ 0 ] );
                            } else {
                                message = 'Данные профиля не сохранены';
                            }
                        }

                        // было сохранение пароля
                        if ( data.length === 2 ) {
                            message += data[ 1 ] ? ' Пароль изменен' : ' Не верный пароль. Пароль не изменен';
                        }

                        this.show( message );
                    } )
                    .catch( ( error ) => {
                        storage.logNetworkError( error );
                        this.show( 'ошибка при сохранении данных' );
                    } );
            }
            , cancel() {
                this.employee            = Object.assign( {}, storage.state.session.employee );
                this.form.oldPassword    = '';
                this.form.password       = '';
                this.form.repeatPassword = '';
                this.reset();
                this.isEdit = false;
            }
            , show( message, timeSec = 5 ) {
                this.alert.message = message;
                this.alert.show    = timeSec;
            }
            , hide() { this.alert.show = false; }
        }
        , created:     function () {
            storage.getDictionary( 'role' ).then( ( items ) => this.dictionaries[ 'role' ] = items );
            storage.getDictionary( 'department' ).then( ( items ) => this.dictionaries[ 'department' ] = items );
            storage.getDictionary( 'memberType' ).then( ( items ) => this.dictionaries[ 'memberType' ] = items );
        }
    }
</script>

<style scoped>

</style>
