<template>
  <div class="row vh-100 align-items-center justify-content-center">
    <div class="col-sm-10 col-md-7 col-lg-5">
      <form class="card" @submit.stop.prevent="onSubmit">
        <h5 class="card-header">Вход в личный кабинет РАО</h5>
        <div class="card-body">
          <b-alert variant="danger" v-model="error.show" fade>{{ error.message }}</b-alert>
          <div class="form-group row">
            <label for="loginId" class="col-sm-3 col-form-label">Логин:</label>
            <div class="col">
              <input type="text" class="form-control" id="loginId" v-model="login">
            </div>
          </div>
          <div class="form-group row">
            <label for="passwordId" class="col-sm-3 col-form-label">Пароль:</label>
            <div class="col">
              <input type="password" class="form-control" id="passwordId" v-model="password">
            </div>
          </div>
          <button type="submit" class="btn btn-outline-secondary btn-block">Вход</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import storage from "../storage";
import {SchemaController, UserController} from "../controllers";

export default {
  name: "login"
  , data: function () {
    return {
      login: ''
      , password: ''
      , error: {
        show: false
        , message: 'Ошибка авторизации'
      }
    };
  }
  , methods: {
    onSubmit: function () {
      UserController.authorization(this.login, this.password)
          .then((userId) => {
            if (!userId) { return this.show('Ошибка авторизации'); }

            this.hide();
            this.resetForm();

            return storage.initApp()
                .then(() => {
                  this.$router.push({name: 'home'});
                });
          })
          .catch((err) => {
            storage.logNetworkError(err);
            this.show('Ошибка соединения с сервером');
          });
    }
    , resetForm() {
      this.login = '';
      this.password = '';
    }
    , show(message, timeSec = 5) {
      this.error.message = message;
      this.error.show = timeSec;
    }
    , hide() {
      this.error.show = false;
    }
  }
}
</script>

<style scoped>

</style>
