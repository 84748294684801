/**
 * Created by User on 12.10.2019.
 */
'use strict';

import IEntity from './i-entity.js';

export default class ParticipationOrganizationActivity extends IEntity {
    /**
     * @typedef {IEntityConfig} ParticipationOrganizationActivityConfig
     * @property {string} [science_report_id]
     * @property {name} [name]
     * @property {boolean} [is_win]
     * @property {string} [organization_activity_id]
     * @property {string} [other_organization_activity]
     */

    /**
     *
     * @param {ParticipationOrganizationActivityConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.science_report_id           = config.science_report_id;
        this.name                        = config.name;
        this.is_win                      = !!config.is_win;
        this.organization_activity_id    = config.organization_activity_id;
        this.other_organization_activity = config.other_organization_activity;
    }
}

