/**
 * Created by User on 08.09.2019.
 */
'use strict';

const RoleCodeEnum = {
    /** член академии */
    Member: 'MEMBER'
    /** Просмотр данных */
    , Viewer: 'VIEWER'
    /** аналитик */
    , Analytic: 'ANALYTIC'
    /** отделение */
    , Department: 'DEPARTMENT'
    /** Экспертиза */
    , Expertise: 'EXPERTISE'
    /** админ */
    , Admin: 'ADMIN'
};

export default RoleCodeEnum;
