<template>
    <ItemList model="scienceReport"
              :filter="{employee_id: employeeId}"
              is-add-item
              is-edit-item>
        <template v-slot:default="modal">
            <ScienceReportCard :scienceReport="modal.item"
                               @update="modal.update"
                               :is-edit="modal.isEdit"/>
        </template>
    </ItemList>
</template>

<script>
    import ItemList from "../components/ItemList";
    import ScienceReportCard from "./ScienceReportCard.vue";
    import storage from "../storage";

    export default {
        name:       "ScienceReportList",
        components: { ItemList, ScienceReportCard },
        data:       function () {
            return {
                employee: storage.state.session.getEmployee()
            };
        }
        , computed: {
            employeeId: function () { return (this.employee && this.employee.id) || storage.emptyUID; }
        }
    }
</script>

<style scoped>

</style>
