/**
 * Created by User on 10.10.2019.
 */
'use strict';

import IEntity from "./i-entity.js";


export default class SchoolBook extends IEntity {
    /**
     * @typedef {IEntityConfig} SchoolBookConfig
     * @property {string} [science_report_id]
     * @property {string} [name]
     * @property {boolean} [is_foreign]
     * @property {boolean} [is_federal_school_book]
     */

    /**
     *
     * @param {SchoolBookConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.science_report_id      = config.science_report_id;
        this.name                   = config.name;
        this.is_foreign             = !!config.is_foreign;
        this.is_federal_school_book = !!config.is_federal_school_book;
    }
}
