/**
 * Created by User on 28.09.2019.
 */
'use strict';
import IEntity from "./i-entity.js";


export default class User extends IEntity {
    /**
     * @typedef {IEntityConfig} UserConfig
     * @property {boolean} [locked=false]
     * @property {string} [login]
     * @property {string} [password]
     */

    /**
     *
     * @param {UserConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.locked   = !!config.locked;
        this.login    = config.login;
        this.password = config.password || '';
    }
}
