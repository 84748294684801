<template>
    <div>
        <b-row>
            <b-col cols="2">
                <b-form-select class="border-success text-success"
                               :options="dictionaries.years"
                               v-model="year"
                               @change="update"></b-form-select>
            </b-col>
            <b-col cols="5">
                <FieldSelect label="Членство в РАО"
                             label-cols="5"
                             label-align='right'
                             :options="dictionaries.memberTypes"
                             v-model="filter.member_type_id"
                             @change="update"
                             is-edit
                />
            </b-col>
            <b-col cols="5" v-if="!isDepartment">
                <FieldSelect label="Отделение"
                             label-cols="5"
                             label-align='right'
                             :options="dictionaries.departments"
                             v-model="filter.department_id"
                             @change="update"
                             is-edit
                />
            </b-col>
        </b-row>

        <table class="mt-2 table table-hover">
            <thead>
            <tr class="text-center">
                <th style="width: 30%"></th>
                <th style="width: 35%">Web of Science</th>
                <th style="width: 35%">Scopus</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th>Количество публикаций</th>
                <td class="text-center">{{ source.webOfScience.count | numberFormat }}</td>
                <td class="text-center">{{ source.scopus.count | numberFormat }}</td>
            </tr>
            <tr>
                <th>Суммарная цитируемость РАО</th>
                <td class="text-center">{{ source.webOfScience.sum | numberFormat }}</td>
                <td class="text-center">{{ source.scopus.sum | numberFormat }}</td>
            </tr>
            <tr>
                <th>Максимальный индекс</th>
                <td class="text-center">
                    <b-row v-for="item in source.webOfScience.employeeMax" v-bind:key="item.fio">
                        <b-col>{{ item.value | numberFormat }} - {{ item.fio }}</b-col>
                    </b-row>
                </td>
                <td class="text-center">
                    <b-row v-for="item in source.scopus.employeeMax" v-bind:key="item.fio">
                        <b-col>{{ item.value | numberFormat }} - {{ item.fio }}</b-col>
                    </b-row>
                </td>
            </tr>
            <tr>
                <th>Максимальный индекс хирша</th>
                <td class="text-center">
                    <b-row v-for="item in source.webOfScience.employeeMaxIndexHirsch" v-bind:key="item.fio">
                        <b-col>{{ item.value | numberFormat }} - {{ item.fio }}</b-col>
                    </b-row>
                </td>
                <td class="text-center">
                    <b-row v-for="item in source.scopus.employeeMaxIndexHirsch" v-bind:key="item.fio">
                        <b-col>{{ item.value | numberFormat }} - {{ item.fio }}</b-col>
                    </b-row>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { ReportController } from "../controllers";
    import FieldSelect from "../components/FieldSelect.vue";
    import storage from "../storage.js";

    export default {
        name: "ReportStatisticIndex",
        components: { FieldSelect },
        data() {
            return {
                isDepartment: storage.state.session.isDepartment(),
                dictionaries: { years: [], memberTypes: [], departments: [] },
                year: storage.state.defaultViewReportYear,
                filter: { },
                source: { webOfScience: {}, scopus: {} }
            };
        },
        methods: {
            /**
             *
             * @returns {Promise<{sumWebOfScienceAndScopus: {sum_web_of_science: number, sum_scopus: number}, scopusCount: number, webOfScienceCount: number, statistics: {year: number, employee_id: string, fio: string, max_web_of_science: number, max_web_of_science_index_hirsch: number, max_scopus: number, max_scopus_index_hirsch: number}[]}>}
             */
            async getData() {
                let promises = [];
                promises.push( ReportController.getWebOfScienceCount( this.year, this.filter ) );
                promises.push( ReportController.getScopusCount( this.year, this.filter ) );
                promises.push( ReportController.getSumWebOfScienceAndScopus( this.year, this.filter ) );
                promises.push( ReportController.getStatistic( this.year, this.filter ) );

                return Promise.all( promises )
                    .then( ( data ) => {
                        let webOfScience = {
                            count: data[ 0 ].count,
                            sum: data[ 2 ].sum_web_of_science,
                            employeeMax: data[ 3 ]
                                .filter( ( item ) => item.max_web_of_science !== null )
                                .map( ( item ) => ( { fio: item.fio, value: item.max_web_of_science } ) ),
                            employeeMaxIndexHirsch: data[ 3 ]
                                .filter( ( item ) => item.max_web_of_science_index_hirsch !== null )
                                .map( ( item ) => ( { fio: item.fio, value: item.max_web_of_science_index_hirsch } ) ),
                        };

                        let scopus = {
                            count: data[ 1 ].count,
                            sum: data[ 2 ].sum_scopus,
                            employeeMax: data[ 3 ]
                                .filter( ( item ) => item.max_scopus !== null )
                                .map( ( item ) => ( { fio: item.fio, value: item.max_scopus } ) ),
                            employeeMaxIndexHirsch: data[ 3 ]
                                .filter( ( item ) => item.max_scopus_index_hirsch !== null )
                                .map( ( item ) => ( { fio: item.fio, value: item.max_scopus_index_hirsch } ) ),
                        };

                        return ( { webOfScience, scopus, data: data[ 3 ] } );
                    } );

            },
            update() {
                this.getData()
                    .then( ( source ) => this.source = source );
            },
        },
        filters: {
            numberFormat( value ) {
                return new Intl.NumberFormat( 'ru-RU' ).format( value )
            }
        },
        created() {
            storage.getDictionary( 'department' ).then( ( items ) => { this.dictionaries.departments = items; } );
            storage.getDictionary( 'memberType' ).then( ( items ) => { this.dictionaries.memberTypes = items; } );

            ReportController.getReportYears()
                .then( ( items ) => {
                    this.dictionaries.years = items.map( ( item ) => ( {
                        value: item.year,
                        text: `${ item.year } год`
                    } ) );
                } );

            if ( this.isDepartment ) {  this.filter.department_id = storage.state.session.employee.department_id; }

            this.update();
        }
    }
</script>

<style scoped>

</style>