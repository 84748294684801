<template>
    <b-table>

    </b-table>
</template>

<script>
    export default {
        name: "ViewReportList"
    }
</script>

<style scoped>

</style>
