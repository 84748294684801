<template>
    <div v-on:keyup.enter="find">
        <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block href="#" v-b-toggle.filter-1 variant="outline-secondary">Фильтр</b-button>
            </b-card-header>
            <b-collapse id="filter-1" :visible="false" accordion="table-filter" role="tabpanel">
                <b-card-body>
                    <b-row>
                        <b-col cols="4">
                            <FieldInput label="Фамилия"
                                        label-cols="4"
                                        v-model="search.lastname"
                                        is-edit
                            />
                        </b-col>
                        <b-col cols="4">
                            <FieldSelect label="Членство в РАО"
                                         label-cols="4"
                                         :options="memberType"
                                         v-model="search.member_type_id"
                                         is-edit
                            />
                        </b-col>
                        <b-col cols="4">
                            <b-button-group>
                                <b-button @click="find" variant="outline-primary">Найти</b-button>
                                <b-button @click="cancelFind" variant="outline-secondary">Сбросить</b-button>
                            </b-button-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="permission.isAdmin">
                        <b-col cols="4">
                            <FieldSelect label="Отделение"
                                         label-cols="4"
                                         :options="departments"
                                         v-model="search.department_id"
                                         is-edit
                            />
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-collapse>
        </b-card>
        <ItemList ref="table"
                  :model="model"
                  :is-add-item="permission.isDepartment || permission.isAdmin"
                  :is-edit-item="permission.isDepartment || permission.isAdmin"
                  :filter="localFilter"
                  is-view-item>
            <template v-slot:top-header="data">
                <div class="py-3">{{ computedEmployeeMemberTypeInfo }}</div>
            </template>
            <template v-slot:default="modal">
                <EmployeeCard :employee="modal.item"
                              @update="modal.update"
                              :is-edit="modal.isEdit"/>
            </template>
        </ItemList>
    </div>
</template>

<script>
    import FieldSelect from '../components/FieldSelect.vue';
    import FieldInput from '../components/FieldInput.vue';
    import ItemList from '../components/ItemList.vue';
    import EmployeeCard from "./EmployeeCard.vue";

    import storage from '../storage.js';
    import { GeneralController } from '../controllers/index.js';
    import { MemberTypeCodeEnum } from '../../shared/enums/index.js';

    export default {
        name: "EmployeeList"
        , components: { EmployeeCard, ItemList, FieldInput, FieldSelect }
        , data: function () {
            return {
                model: 'employee'
                , permission: {
                    isAdmin: storage.state.session && storage.state.session.isAdmin()
                    , isMember: storage.state.session && storage.state.session.isMember()
                    , isAnalytic: storage.state.session && storage.state.session.isAnalytic()
                    , isDepartment: storage.state.session && storage.state.session.isDepartment()
                },
                localFilter: {},
                memberType: [],
                departments: [],
                employeeMemberTypeCount: [],
                search: {
                    lastname: ''
                }
            };
        }
        , computed: {
            keymap() {
                return {
                    // 'enter': this.find
                }
            }
            , computedEmployeeMemberTypeInfo() {
                return this.memberType
                    .map( ( item ) => {
                        let elm = this.employeeMemberTypeCount.find( ( elm ) => elm.member_type_id === item.id );
                        let value = elm ? elm.value : 0;
                        return { sort_number: item.sort_number, value: `${ item.name } - ${ value }` }
                    } )
                    .sort( ( a, b ) => a.sort_number - b.sort_number )
                    .map( ( item ) => item.value )
                    .join( ', ' );
            }
        }
        , methods: {
            find() {
                this.localFilter = {};

                if ( storage.state.session && storage.state.session.isDepartment() ) {
                    this.localFilter.department_id = storage.state.session.employee.department_id || storage.emptyUID;
                }

                if ( this.search.lastname ) {
                    this.localFilter.lastname = { $like: this.search.lastname };
                }

                if ( this.search.member_type_id ) {
                    this.localFilter.member_type_id = this.search.member_type_id;
                }

                if ( this.permission.isAdmin ) {
                    let departments = [];
                    if ( this.search.department_id ) {
                        this.localFilter.department_id = this.search.department_id;
                        departments.push( this.search.department_id );
                    }
                    GeneralController.getEmployeeMemberTypeCount( departments, false )
                        .then( ( data ) => {
                            this.employeeMemberTypeCount = data;
                        } )
                        .catch( ( error ) => {
                            storage.logNetworkError( error );
                            return ( [] );
                        } );
                }


                this.$refs.table.refresh();
            },
            cancelFind() {
                this.search.lastname = undefined;
                this.search.member_type_id = undefined;

                if ( this.permission.isAdmin ) {
                    this.search.department_id = undefined;
                    let departments = this.getEmployeeDepartment();
                    if ( departments.length ) {
                        this.localFilter.department_id = departments[ 0 ] || storage.emptyUID;
                    }
                    GeneralController.getEmployeeMemberTypeCount( departments, false )
                        .then( ( data ) => {
                            this.employeeMemberTypeCount = data;
                        } )
                        .catch( ( error ) => {
                            storage.logNetworkError( error );
                            return ( [] );
                        } );
                }

                this.find();
            },
            getEmployeeDepartment() {
                let departments = [];
                if ( storage.state.session && storage.state.session.isDepartment() ) {
                    departments.push( storage.state.session.employee.department_id );
                }

                return ( departments );
            }
        }
        , created() {
            let departments = this.getEmployeeDepartment();
            if ( departments.length ) { this.localFilter.department_id = departments[ 0 ] || storage.emptyUID; }

            storage.getDictionary( 'memberType' )
                .then( ( items ) => { this.memberType = items; } );

            storage.getDictionary( 'department' )
                .then( ( items ) => { this.departments = items; } );


            GeneralController.getEmployeeMemberTypeCount( departments, false )
                .then( ( data ) => {
                    this.employeeMemberTypeCount = data;
                } )
                .catch( ( error ) => {
                    storage.logNetworkError( error );
                    return ( [] );
                } );
        }
    }
</script>

<style scoped>

</style>
