<template>
    <ItemList model="fgosName"
              is-edit-item
              :filter="localFilter"
              is-view-item
    >
        <template v-slot:default="modal">
            <FgosExpertiseCard :item="modal.item"
                               :is-edit="modal.isEdit"
            />
        </template>
    </ItemList>
</template>

<script>
    import ItemList from "../components/ItemList";
    import FgosExpertiseCard from "./FgosExpertiseCard";

    export default {
        name:       "FgosExpertiseList",
        components: { FgosExpertiseCard, ItemList },
        computed:   {
            localFilter() { return { is_end: false }; }
        }
    }
</script>

<style scoped>

</style>
