/**
 * Created by User on 28.09.2019.
 */
'use strict';
import { User, Role, Employee, Session } from '../../shared/models';
import storage from '../storage';
import { getFetchInit } from '../../shared/helper';
import qs from "qs";

export default class UserController {
    constructor( config ) { }

    /**
     *
     * @param {string} login
     * @param {string} password
     * @return {Promise<string>}
     */
    static async authorization( login, password ) {
        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'post', { login, password } );
        let response    = await fetch( `${ apiUrl }/user/authorization`, initOptions );

        if ( response.status !== 200 ) {
            storage.logNetworkError( response );
            throw new Error( 'Ошибка авторизации' );
        }

        // null or userId
        let json = await response.json();
        return ( json );
    }

    /**
     *
     * @return {Promise<Session>}
     */
    static async getSession() {
        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'get' );
        let response    = await fetch( `${ apiUrl }/user/session`, initOptions );

        if ( response.status !== 200 ) {
            storage.logNetworkError( response );
            throw new Error( 'Ошибка получения сессии' );
        }

        // null or session
        let json    = await response.json();
        let session = json && new Session( json );
        return ( session );
    }

    /**
     *
     * @return {Promise<boolean>}
     */
    static async logout() {
        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'get' );
        let response    = await fetch( `${ apiUrl }/user/logout`, initOptions );

        if ( response.status !== 200 ) {
            storage.logNetworkError( response );
            throw new Error( 'Ошибка получения сессии' );
        }

        let json = await response.json();
        return ( json );
    }

    static async getItems( options ) {
        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'get' );
        let params      = qs.stringify( options );
        let response    = await fetch( `${ apiUrl }/user?${ params }`, initOptions );

        if ( response.status !== 200 ) {
            storage.logNetworkError( response );
            throw new Error( `Ошибка в работе сервера` );
        }

        let json = await response.json();

        return ( json );
    }

    /**
     *
     * @param {User} user
     * @param {Role[]} roles
     * @return {Promise<{user: User, role: Role[]}>}
     */
    static async addUser( user, roles ) {
        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'post', { user, roles } );
        let response    = await fetch( `${ apiUrl }/user`, initOptions );

        if ( response.status !== 200 ) {
            storage.logNetworkError( response );
            throw new Error( `Ошибка в работе сервера` );
        }

        let json = await response.json();

        return ( json );
    }

    /**
     *
     * @param {User} user
     * @param {Role[]} roles
     * @return {Promise<{ locked: Boolean, roles: Role[], password: Boolean }>}
     */
    static async updateUser( user, roles ) {
        let apiUrl = storage.state.apiUrl;

        // user.id -> error?!

        let initOptions = getFetchInit( 'put', { user, roles } );
        let response    = await fetch( `${ apiUrl }/user/${ user.id }`, initOptions );

        if ( response.status !== 200 ) {
            storage.logNetworkError( response );
            throw new Error( `Ошибка в работе сервера` );
        }

        let json = await response.json();

        return ( json );
    }

    /**
     *
     * @param {string} oldPassword
     * @param {string} newPassword
     * @return {Promise<Boolean>}
     */
    static async changePassword( oldPassword, newPassword ) {
        let apiUrl = storage.state.apiUrl;


        let initOptions = getFetchInit( 'put', { oldPassword, newPassword } );
        let response    = await fetch( `${ apiUrl }/user/changePassword`, initOptions );

        if ( response.status !== 200 ) {
            storage.logNetworkError( response );
            throw new Error( `Ошибка в работе сервера` );
        }

        let json = await response.json();

        return ( json );
    }

    /**
     * создает обновляет и т.п.
     * @param {Employee} employee
     * @param {User} user
     * @param {Role[]} roles
     * @return {Promise<{employee: Employee, user: User, roles: Role[]}>}
     */
    static async setEmployeeUserRoles( employee, user, roles ) {
        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'post', { employee, user, roles } );
        let response    = await fetch( `${ apiUrl }/user/setEmployeeUserRoles`, initOptions );

        if ( response.status !== 200 ) {
            storage.logNetworkError( response );
            throw new Error( `Ошибка в работе сервера` );
        }

        let json = await response.json();

        return ( json );
    }
}
