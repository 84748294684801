<template>
    <ItemCard :model="schemaId"
              v-model="item"
              :is-edit="isEdit"
    />
</template>

<script>
    import ItemCard from '../components/ItemCard.vue';
    import { GeneralController } from '../controllers/index.js';

    export default {
        name:         "ModelCard"
        , props:      {}
        , components: { ItemCard }
        , data:       function () {
            return {
                schemaId: this.$route.params.model
                , isEdit: this.$route.query.isEdit === 'true'
                , item:   {}
            };
        }
        , methods:    {
            getItemById( schemaId, itemId ) {
                if ( !itemId ) { return Promise.resolve( {} ); }
                return GeneralController.getItemById( schemaId, itemId )
                    .then( ( data ) => data || {} );
            }
        }
        , beforeRouteUpdate( to, from, next ) {
            this.isEdit   = to.query.isEdit === 'true';
            this.schemaId = to.params.model;
            this.getItemById( this.schemaId, to.query.itemId )
                .then( ( data ) => { this.item = data; } );
            next();
        }
        , created() {
            this.getItemById( this.schemaId, this.$route.query.itemId )
                .then( ( data ) => { this.item = data; } );
        }
    }
</script>

<style scoped>

</style>
