<template>
    <b-table-simple caption-top hover striped>
        <caption>
            <b-row>
                <b-col><h4>Сводные данные - {{ name }}</h4></b-col>
                <b-col cols="2">
                    <b-form-select :options="years" :value="year" @change="onChange"></b-form-select>
                </b-col>
            </b-row>
        </caption>
        <thead>
        <tr>
            <th class="text-center align-middle"></th>
            <th class="text-center align-middle">Отделение</th>
            <th style="width: 150px" class="text-center align-middle" v-for="memberType in dictionaries.memberType"
                v-bind:key="memberType.id">{{ memberType.name }}
            </th>
            <th style="width: 150px" class="text-center align-middle">Всего</th>
        </tr>
        </thead>
        <template v-for="department in dictionaries.department">
            <tr v-bind:key="department.id">
                <th>
                    <b-button size="sm"
                              variant="outline-secondary"
                              v-b-tooltip.hover
                              title="Сформировать отчет в формате docx"
                              :href="departmentReport(department.id, year)"
                              v-if="departmentReport(department.id, year)"
                              target="_blank"
                    >
                        <font-awesome-icon icon="file-word" size="lg"/>
                    </b-button>
                </th>
                <th>
                    <b-form-checkbox v-model="selectedDepartment[department.id]">{{department.name}}
                    </b-form-checkbox>
                </th>
                <td class="text-center align-middle" v-for="memberType in dictionaries.memberType"
                    v-bind:key="memberType.id" @click="cellClick(department.id, memberType.id )">{{
                    getDepartmentData(
                    department.id, memberType.id )}}
                </td>
                <th class="text-center align-middle" @click="cellClick(department.id)">{{ getDepartmentTotalData(
                    department.id ) }}
                </th>
            </tr>
            <tbody v-if="selectedDepartment[department.id]" v-bind:key="`employee(${department.id})`">
            <tr v-for="employee in getEmployeeByDepartment(department.id)" v-bind:key="employee.id">
                <th>
                    <b-button size="sm"
                              variant="outline-secondary"
                              v-b-tooltip.hover
                              title="Сформировать отчет в формате docx"
                              :href="employeeReport(employee.id, year)"
                              v-if="employeeReport(employee.id, year)"
                              target="_blank"
                    >
                        <font-awesome-icon icon="file-word" size="lg"/>
                    </b-button>
                </th>
                <th class="text-right">
                    {{ employee.shortName() + ( employee.is_dead ? '*' : '' )}}
                </th>
                <td class="text-center align-middle" v-for="memberType in dictionaries.memberType"
                    v-bind:key="memberType.id +'_info'" @click="cellClick(null, memberType.id, null)">
                    {{ employee.member_type_id === memberType.id ? getEmployeeData( employee.id, memberType.id ) :
                    '' }}
                </td>
            </tr>
            </tbody>
        </template>
        <tfoot>
        <tr>
            <th></th>
            <th class="text-right">Всего</th>
            <th class="text-center align-middle" v-for="memberType in dictionaries.memberType"
                v-bind:key="memberType.id + 'total'" @click="cellClick(department.id, memberType.id )">{{
                getMemberTypeTotalData( memberType.id )}}
            </th>
            <th class="text-center align-middle">{{ getTotalData() }}</th>
        </tr>
        </tfoot>
    </b-table-simple>
</template>

<script>
    import storage from '../storage.js';
    import { ReportController } from '../controllers';
    import { Employee, MemberType, Department } from '../../shared/models';
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faFileWord } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    library.add( faFileWord );


    export default {
        name:         "OlapDepartmentView"
        , components: { FontAwesomeIcon }
        , model:      {
            prop:    'year'
            , event: 'update'
        }
        , props:      {
            name:    { type: String, default: '' }
            , year:  { type: Number }
            , years: { type: Array, default: function () {return [] } }
            , data:  { type: Array, default: function () { return ( [] ); } }
        }
        , data() {
            return {
                dictionaries:         { employee: [], memberType: [], department: [] }
                , selectedDepartment: {}
            };
        }
        , methods:    {
            getDepartmentData( departmentId, memberTypeId ) {
                let res = this.data.reduce( ( s, e ) => s + ( e.department_id === departmentId && e.member_type_id === memberTypeId ? 1 : 0 ), 0 );
                return ( res );
            }
            , getDepartmentTotalData( departmentId ) {
                let res = this.data.reduce( ( s, e ) => s + ( e.department_id === departmentId ? 1 : 0 ), 0 );
                return ( res );
            }
            , getMemberTypeTotalData( memberTypeId ) {
                let res = this.data.reduce( ( s, e ) => s + ( e.member_type_id === memberTypeId ? 1 : 0 ), 0 );
                return ( res );
            }
            , getTotalData() {
                let res = this.data.reduce( ( s ) => s + 1, 0 );
                return ( res );
            }
            , getEmployeeData( employeeId, memberTypeId ) {
                let res = this.data.reduce( ( s, e ) => s + ( e.employee_id === employeeId && e.member_type_id === memberTypeId ? 1 : 0 ), 0 );
                return ( res );
            }
            , getEmployeeByDepartment( departmentId ) {
                return this.dictionaries.employee
                    .filter( ( item ) => item.department_id === departmentId );
            }
            , cellClick( departmentId, memberTypeId, employeeId ) {
                this.$emit( 'cellClick', { departmentId, memberTypeId, employeeId } )
            }
            , employeeReport( employee_id, year ) {
                let item = this.data
                    .find( ( item ) => item.report_year === year && item.employee_id === employee_id );
                if ( !item ) { return ''; }

                return ReportController.getEmployeeDocxReportLink( item.report_id );
            }
            , departmentReport( department_id, year ) {
                let item = this.data
                    .find( ( item ) => item.report_year === year && item.department_id === department_id );
                if ( !item ) { return ''; }

                return ReportController.getDepartmentReportLink( department_id, year )
            }
            , onChange( value ) { this.$emit( 'update', value ); }
        }
        , created() {
            storage.getDictionary( 'department' ).then( ( items ) => this.dictionaries.department = items
                .filter( ( item ) => {
                    if ( storage.state.session.isAnalytic() ) {
                        return true;
                    } else if ( storage.state.session && storage.state.session.isDepartment() ) {
                        return ( item.id === storage.state.session.employee.department_id );
                    }
                    return ( false );
                } )
                .map( ( item ) => new Department( item ) )
                .sort( ( a, b ) => a.sort_number - b.sort_number )
            );
            storage.getDictionary( 'memberType' ).then( ( items ) => this.dictionaries.memberType = items
                .map( ( item ) => new MemberType( item ) )
                .sort( ( a, b ) => a.sort_number - b.sort_number )
            );
            storage.getDictionary( 'employee' ).then( ( items ) => this.dictionaries.employee = items
                .filter( ( item ) => {
                    if ( storage.state.session.isAnalytic() ) {
                        return true;
                    } else if ( storage.state.session && storage.state.session.isDepartment() ) {
                        return ( item.department_id === storage.state.session.employee.department_id );
                    }
                    return ( false );
                } )
                .map( ( item ) => new Employee( item ) )
                .sort( ( a, b ) => {
                    if ( a.lastname < b.lastname ) { return -1; }
                    if ( a.lastname > b.lastname ) { return 1; }
                    return 0;
                } )
            );
        }
    }
</script>

<style scoped>

</style>
