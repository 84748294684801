/**
 * Created by User on 01.10.2019.
 */
'use strict';
import storage from '../storage';
import { getFetchInit } from '../../shared/helper';

export default class SchemaController {
    constructor( config ) { }

    /**
     *
     * @return {Promise<Schema[]>}
     */
    static async getItems() {
        let apiUrl = storage.state.apiUrl;

        let initOptions = getFetchInit( 'get' );
        let response    = await fetch( `${ apiUrl }/schema`, initOptions );

        if ( response.status !== 200 ) {
            storage.logNetworkError( response );
            throw new Error( 'Ошибка получения данных' );
        }

        let json = await response.json();
        return ( json );
    }

    /**
     *
     * @param {string} schemaId
     * @return {Promise<Schema>}
     */
    static async getItemById( schemaId ) {
        throw new Error( 'Метод (getItemById) не реализован' );
    }

    /**
     *
     * @param {string} schema
     * @return {Promise<Schema>}
     */
    static async addItem( schema ) {
        throw new Error( 'Метод (addItem) не реализован' );
    }

    /**
     * @param {string} schemaId
     * @param {Schema} schema
     * @return {Promise<Schema>}
     */
    static async updateItem( schemaId, schema ) {
        throw new Error( 'Метод (updateItem) не реализован' );
    }

    /**
     *
     * @param {Schema} schemaId
     * @return {Promise<Schema>}
     */
    static async removeItem( schemaId ) {
        throw new Error( 'Метод (removeItem) не реализован' );
    }
}
