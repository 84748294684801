/**
 * Created by User on 12.10.2019.
 */
'use strict';

import IEntity from "./i-entity.js";


export default class ParticipationVak extends IEntity {
    /**
     * @typedef {IEntityConfig} ParticipationVakConfig
     * @property {string} [science_report_id]
     * @property {boolean} [is_vak=false]
     * @property {boolean} [is_presidium_vak=false]
     */

    /**
     *
     * @param {ParticipationVakConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.science_report_id = config.science_report_id;
        this.is_vak            = !!config.is_vak;
        this.is_presidium_vak  = !!config.is_presidium_vak;
    }
}
