/**
 * Created by User on 10.10.2019.
 */
'use strict';


import IEntity from './i-entity.js';

export default class DigitalEducationalResult extends IEntity {
    /**
     * @typedef {IEntityConfig} DigitalEducationalResultConfig
     * @property {string} [science_report_id]
     * @property {string} [name]
     * @property {string} [http_link]
     * @property {string} [edu_type_id]
     */

    /**
     *
     * @param {DigitalEducationalResultConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.science_report_id = config.science_report_id;
        this.name              = config.name;
        this.edu_type_id       = config.edu_type_id;
    }
}
