/**
 * Created by User on 10.10.2019.
 */
'use strict';
import IEntity from "./i-entity.js";


export default class Monograph extends IEntity {
    /**
     * @typedef {IEntityConfig} MonographConfig
     * @property {string} [science_report_id]
     * @property {string} [name]
     * @property {boolean} [is_foreign]
     */

    /**
     *
     * @param {MonographConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.science_report_id = config.science_report_id;
        this.name              = config.name;
        this.is_foreign         = !!config.is_foreign;
    }
}
