<template>
    <b-form ref="form" @submit.stop.prevent="debounceSave" novalidate :validated="validated">
        <b-card :title="schema.singleName">
            <b-alert :variant="alert.variant" v-model="alert.show" fade>{{ alert.message }}</b-alert>
            <b-card-header v-if="isEdit">
                <b-button-group>
                    <b-button type="submit" variant="outline-secondary">Сохранить</b-button>
                    <b-button variant="outline-secondary" @click="cancel()">Отменить</b-button>
                </b-button-group>
            </b-card-header>
            <b-card-body>
                <b-table-simple hover fixed>
                    <b-thead class="text-center">
                        <b-tr>
                            <b-th style="width: 250px" class="align-middle">Название базы данных</b-th>
                            <b-th class="align-middle">Общая цитируемость</b-th>
                            <b-th class="align-middle">Индекс Хирша</b-th>
                            <b-th class="align-middle">Цитируемость публикаций за последние 5 лет</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr>
                            <b-td class="text-right">Web of Science</b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number"
                                              v-model="localItem.web_of_science"
                                              :readonly="!isEdit"/>
                            </b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number"
                                              v-model="localItem.web_of_science_index_hirsch"
                                              :readonly="!isEdit"/>
                            </b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number"
                                              v-model="localItem.web_of_science_citation_past_5_years"
                                              :readonly="!isEdit"/>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="text-right">Scopus</b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number" v-model="localItem.scopus"
                                              :readonly="!isEdit"/>
                            </b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number"
                                              v-model="localItem.scopus_index_hirsch"
                                              :readonly="!isEdit"/>
                            </b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number"
                                              v-model="localItem.scopus_citation_past_5_years"
                                              :readonly="!isEdit"/>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="text-right">Перечень ВАК при Минобрнауки России</b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number" v-model="localItem.vak"
                                              :readonly="!isEdit"/>
                            </b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number"
                                              v-model="localItem.vak_index_hirsch"
                                              :readonly="!isEdit"/>
                            </b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number"
                                              v-model="localItem.vak_citation_past_5_years"
                                              :readonly="!isEdit"/>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="text-right">РИНЦ</b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number" v-model="localItem.rinc"
                                              :readonly="!isEdit"/>
                            </b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number"
                                              v-model="localItem.rinc_index_hirsch"
                                              :readonly="!isEdit"/>
                            </b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number"
                                              v-model="localItem.rinc_citation_past_5_years"
                                              :readonly="!isEdit"/>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="text-right">Ядро РИНЦ</b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number" v-model="localItem.core_rinc"
                                              :readonly="!isEdit"/>
                            </b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number"
                                              v-model="localItem.core_rinc_index_hirsch"
                                              :readonly="!isEdit"/>
                            </b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number"
                                              v-model="localItem.core_rinc_citation_past_5_years"
                                              :readonly="!isEdit"/>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="text-right">Google Scholar</b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number"
                                              v-model="localItem.google_scholar"
                                              :readonly="!isEdit"/>
                            </b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number"
                                              v-model="localItem.google_scholar_index_hirsch"
                                              :readonly="!isEdit"/>
                            </b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number"
                                              v-model="localItem.google_scholar_citation_past_5_years"
                                              :readonly="!isEdit"/>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="text-right">ERIH</b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number" v-model="localItem.erich"
                                              :readonly="!isEdit"/>
                            </b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number"
                                              v-model="localItem.erich_index_hirsch"
                                              :readonly="!isEdit"/>
                            </b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number"
                                              v-model="localItem.erich_citation_past_5_years"
                                              :readonly="!isEdit"/>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td class="text-right">Другое</b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number" v-model="localItem.other"
                                              :readonly="!isEdit"/>
                            </b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number"
                                              v-model="localItem.other_index_hirsch"
                                              :readonly="!isEdit"/>
                            </b-td>
                            <b-td>
                                <b-form-input class="text-center" number type="number"
                                              v-model="localItem.other_citation_past_5_years"
                                              :readonly="!isEdit"/>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-card-body>
        </b-card>
    </b-form>
</template>

<script>
    import storage from '../storage';
    import { GeneralCitation } from "../../shared/models";
    import { GeneralController } from "../controllers";
    import { debounce } from "debounce";

    export default {
        name: "GeneralCitationCard"
        , model: {
            prop: 'item'
            , event: 'update'
        }
        , props: {
            title: { type: String, default: 'Без имени' }
            , item: { type: Object }
            , isEdit: { type: Boolean, default: false }
            , labelCols: { type: [ String, Number ], default: 3 }
            , labelAlign: { type: String, default: 'right' }
        }
        , data: function () {
            return {
                schemaManager: storage.state.schemaManager
                , schemaId: 'generalCitation'
                , localItem: this.getLocalItem( this.item )
                , validated: null
                , alert: {
                    show: false
                    , variant: 'info'
                    , message: ''
                }
            };
        }
        , computed: {
            schema: function () { return this.schemaManager.getSchemaById( this.schemaId ); }
            , isAddMode: function () { return ( !this.item.id && this.isEdit ); }
            , isEditMode: function () { return ( this.item.id && this.isEdit ) }
        }
        , watch: {
            item: function ( value, oldValue ) {
                this.localItem = this.getLocalItem( value );
            }
        }
        , methods: {
            touch() { }
            , reset() { }
            , checkValidity() { return true; }
            , getLocalItem( value ) { return new GeneralCitation( value || {} ); }
            , debounceSave: debounce( function ( e ) { this.save( e ); }, storage.debouncers.default )
            , save: async function () {
                this.show( 'Проверяем данные...', true );
                this.touch();
                if ( !this.checkValidity() ) {
                    this.show( 'Поправьте данные...' );
                    return;
                }

                // удаляем не корректные значения
                this.localItem.fix();

                this.show( 'Идет сохранение...', true );
                let promise;
                if ( this.localItem.id ) {
                    promise = GeneralController.updateItemById( this.schemaId, this.localItem.id, this.localItem );
                } else {
                    promise = GeneralController.addItem( this.schemaId, this.localItem );
                }

                promise
                    .then( ( json ) => {
                        this.localItem = json;
                        this.$emit( 'update', json );
                        this.show( 'Запись сохранена', 5 );
                    } )
                    .catch( ( error ) => {
                        storage.logNetworkError( error );
                        this.show( 'Ошибка сохранения на сервере', 5 );
                    } );
            }
            , cancel() {
                this.localItem = this.getLocalItem( this.item );
                this.reset()
            }
            , show( message, timeSec = 5 ) {
                this.alert.message = message;
                this.alert.show = timeSec || true;
            }
            , hide() { this.alert.show = false; }
        }
    }
</script>

<style scoped>

</style>
