<template>
    <b-table-simple striped>
        <b-thead>
            <b-tr>
                <b-th class="align-middle">Фамилия</b-th>
                <b-th class="align-middle">Имя</b-th>
                <b-th class="align-middle">Отчество</b-th>
                <b-th class="align-middle">Отделение</b-th>
            </b-tr>
        </b-thead>
        <b-tbody>
            <b-tr v-for="item in experts" v-bind:key="item.id">
                <b-td>{{ item.lastname }}</b-td>
                <b-td>{{ item.firstname }}</b-td>
                <b-td>{{ item.middlename }}</b-td>
                <b-td>{{ item.department_name }}</b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>

<script>
    import storage from '../storage.js'
    import { GeneralController } from '../controllers/index.js'

    export default {
        name:    "FgosExpertList",
        props:   {
            fgosNameId: { type: String }
        },
        data() {
            return {
                experts: []
            };
        },
        methods: {
            getFgosExperts() {
                if ( !this.fgosNameId ) { return; }
                GeneralController.getFgosExperts( this.fgosNameId )
                    .then( ( data ) => {
                        this.experts = data;
                    } )
                    .catch( ( error ) => {
                        storage.logNetworkError( error );
                        this.show( 'Ошибка получения данных от сервера', true );
                    } )
            }
        },
        created() {
            this.getFgosExperts();
        }
    }
</script>

<style scoped>

</style>
