/**
 * Created by User on 28.09.2019.
 */
'use strict';
import IEntity from "./i-entity.js";


export default class Role extends IEntity {
    /**
     * @typedef {IEntityConfig} RoleConfig
     * @property {string} [code]
     * @property {string} [name]
     */

    /**
     *
     * @param {RoleConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.code = config.code;
        this.name = config.name;
    }
}
