/**
 * Created by User on 28.09.2019.
 */

'use strict';
import SchemaManager from "../shared/managers/schema-manager";
import { GeneralController, UserController, SchemaController } from "./controllers";


export default {
    debug: true
    , emptyUID: '00000000-0000-0000-0000-000000000000'
    , debouncers: {
        default: 300
    }
    , state: {
        apiUrl: [ '/api', 'http://localhost/api' ][ 0 ]
        , isInitApp: false
        , defaultViewReportYear: ( new Date() ).getFullYear() - 1
        , schemaManager: new SchemaManager()
        , reports: [
            'monograph'
            , 'schoolBook'
            , 'recommendationPublication'
            , 'sciencePublication'
            , 'popularSciencePublication'
            , 'otherPublication'
            , 'digitalEducationalResult'
            , 'ridResult'
            , 'performanceInnovationSite'
            , 'postgraduateTraining'
            , 'defendedUnderSupervision'
            , 'participationDissertationCouncil'
            , 'participationOrganizationActivity'
            , 'participationFederalProgram'
            , 'participationResearchProgram'
            , 'participationScientificPracticalActivity'
        ]
        , session: null
        , dictionaries: {}
    }
    /**
     *
     * @param error
     */
    , logNetworkError( error ) {
        if ( !this.debug ) { return; }
        console.log( 'logNetworkError\n', error );
    }
    /**
     * Инициализация приложения
     */
    , async initApp() {
        this.state.isInitApp = true;

        let session = await UserController.getSession();
        if ( !session ) { return; }

        this.state.session = session;

        //ToDo: сейчас каждый раз когда авторизация даннеые обновляются
        let schemas = await SchemaController.getItems();
        this.state.schemaManager.load( schemas );
    }
    /**
     *
     * @return {boolean}
     */
    , authorized() { return ( !!this.state.session && !!this.state.session.user ) }

    /**
     *
     * @return {Schema[]}
     */
    , getReportList() {
        return this.state.reports
            .map( ( schemaId ) => this.state.schemaManager.getSchemaById( schemaId ) )
            .filter( ( item ) => !!item )
            .sort( ( a, b ) => {
                if ( a.name < b.name ) { return -1; }
                if ( a.name > b.name ) { return 1; }
                return 0;
            } );
    }
    /**
     *
     * @param {string} schemaId
     * @return {Array}
     */
    , async getDictionary( schemaId ) {
        let items = this.state.dictionaries[ schemaId ];
        if ( items ) { return ( items ); }

        return GeneralController.getItems( schemaId )
            .then( ( result ) => {
                this.state.dictionaries[ schemaId ] = result.rows;
                return ( result.rows );
            } )
            .catch( ( error ) => {
                this.logNetworkError( error );
                return ( [] );
            } );
    }
};


