<template>
    <router-view/>
</template>

<script>
    export default {
        name: "FgosExpertise"
    }
</script>

<style scoped>

</style>
