/**
 * Created by User on 29.09.2019.
 */
'use strict';


const SchemaFieldTypeEnum = {
    UUID:      'uuid'       //input[type='text']
    , String:  'string'     //input[type='text']
    , Text:    'text'       //textarea
    , Phone:   'phone'      //input[type='tel']
    , URL:     'url'        //input[type='url']
    , Email:   'email'      //input[type='email']
    , Number:  'number'     //input[type='number']
    , Boolean: 'boolean'    //checkbox
    , Date:    'date'       //input[type='date']

    , Select: 'select'      //select
    , Lookup: 'lookup'      //
};

export default SchemaFieldTypeEnum;
