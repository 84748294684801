/**
 * Created by User on 28.09.2019.
 */
'use strict';

import IEntity from './i-entity.js';

export default class Employee extends IEntity {
    /**
     * @typedef {IEntityConfig} EmployeeConfig
     * @property {string} [user_id]
     * @property {string} [department_id]
     * @property {string} [member_type_id]
     * @property {string} [firstname]
     * @property {string} [lastname]
     * @property {string} [middlename]
     * @property {string} [member_info_rao]
     * @property {string} [title_info_rao]
     * @property {string} [member_info_other]
     * @property {boolean} [is_dead]
     * @property {string} [academic_degree]
     * @property {string} [academic_rank]
     * @property {string} [registration_address]
     * @property {string} [post_address]
     * @property {string} [mobile_phone]
     * @property {string} [home_phone]
     * @property {string} [email]
     * @property {string} [contact_person]
     * @property {string} [contact_person_phone]
     * @property {string} [contact_person_email]
     * @property {string} [work_organization]
     * @property {string} [work_position]
     * @property {string} [work_additional_organization]
     * @property {string} [work_additional_position]
     *
     */

    /**
     *
     * @param {EmployeeConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.user_id = config.user_id;

        this.department_id  = config.department_id;
        this.member_type_id = config.member_type_id;
        this.firstname      = config.firstname;
        this.lastname       = config.lastname;
        this.middlename     = config.middlename;

        this.member_info_rao = config.member_info_rao;
        this.title_info_rao  = config.title_info_rao;
        this.member_info_other = config.member_info_other;

        this.is_dead         = !!config.is_dead;

        this.academic_degree = config.academic_degree;
        this.academic_rank   = config.academic_rank;

        this.registration_address = config.registration_address;
        this.post_address         = config.post_address;

        this.mobile_phone         = config.mobile_phone;
        this.home_phone           = config.home_phone;
        this.email                = config.email;
        this.contact_person       = config.contact_person;
        this.contact_person_phone = config.contact_person_phone;
        this.contact_person_email = config.contact_person_email;

        this.work_organization            = config.work_organization;
        this.work_position                = config.work_position;
        this.work_additional_organization = config.work_additional_organization;
        this.work_additional_position     = config.work_additional_position;
    }

    /**
     *
     * @return {string}
     */
    shortName() {
        let name = this.lastname;
        name += !this.firstname ? '' : ' ' + this.firstname[ 0 ].toUpperCase() + '.';
        name += !this.middlename ? '' : ' ' + this.middlename[ 0 ].toUpperCase() + '.';
        return ( name );
    }

    /**
     *
     * @return {string}
     */
    fullName() {
        let name = this.lastname;
        name += !this.firstname ? '' : ' ' + this.firstname;
        name += !this.middlename ? '' : ' ' + this.middlename;
        return ( name );
    }
}
