/**
 * Created by User on 12.10.2019.
 */
'use strict';
import IEntity from "./i-entity.js";


export default class ParticipationInWorkEducationSystem extends IEntity {
    /**
     * @typedef {IEntityConfig} ParticipationInWorkEducationSystemConfig
     * @property {string} [science_report_id]
     * @property {string} [name]
     * @property {string} [participation_form]
     * @property {string} [participation_type]
     */

    /**
     *
     * @param {ParticipationInWorkEducationSystemConfig} config
     */
    constructor( config = {} ) {
        super( config );

        this.science_report_id      = config.science_report_id;
        this.name                   = config.name;
        this.participation_form    = config.participation_form;
        this.participation_type = config.participation_type;
    }
}
