<template>
    <b-card no-body>
        <b-alert fade v-model="alert.show">{{ alert.message }}</b-alert>
        <b-tabs card
                pills
                vertical
                nav-wrapper-class="w-25">
            <b-tab title="Отчет">
                <b-form>
                    <b-alert :show="localScienceReport.is_done && !session.isAdmin()" variant="success"><h5>Отчет зафиксирован</h5>Для возврата отчета на доработку обратитесь к администратору.</b-alert>
                    <b-card-header>
                        <b-button-group>
                            <b-button v-if="isLocalEdit"
                                      variant="outline-secondary"
                                      @click="debounceSave"
                            >Сохранить
                            </b-button>
                            <b-button v-if="isLocalEdit"
                                      variant="outline-secondary"
                                      @click="cancel"
                            >Отменить
                            </b-button>
                            <b-button :class="{'ml-5': isLocalEdit}" variant="outline-secondary"
                                      :href="docxURLReport" target="_blank"
                                      :disabled="!localScienceReport.id">Печатная форма
                                отчета
                            </b-button>
                            <b-button v-if="!localScienceReport.is_done && session.isMember()"
                                      variant="outline-secondary"
                                      @click="done( true )">Зафиксировать отчет
                            </b-button>
                            <b-button v-if="localScienceReport.is_done && session.isAdmin()"
                                    variant="outline-secondary"
                                    @click="done( false )">Вернуть в работу
                            </b-button>
                        </b-button-group>
                    </b-card-header>
                    <b-card-body>
                        <FieldInput type="number"
                                    label="Год"
                                    :label-cols="labelCols"
                                    :label-align="labelAlign"
                                    v-model="localScienceReport.year"
                                    :state="$v.localScienceReport.year.$dirty ? !$v.localScienceReport.year.$error: null"
                                    :is-edit="isLocalEdit"/>
                    </b-card-body>
                    <b-card-body title="Текст отчета">
                        <small id="popover-report-note-1">Обновить там же текст</small>
                        <FieldEditor ref="textEdit" v-model="localScienceReport.note" :is-edit="isLocalEdit"/>
                    </b-card-body>
                </b-form>
            </b-tab>
            <b-tab title="Опубликованные произведения"
                   :disabled="!isViewTab">
                <b-tabs card>
                    <b-tab title="Монографии">
                        <ItemList model="monograph"
                                  :defaultItem="defaultMonograph"
                                  :filter="{science_report_id}"
                                  :is-view-item="!isLocalEdit"
                                  :is-add-item="isLocalEdit"
                                  :is-delete-item="isLocalEdit"
                                  :is-edit-item="isLocalEdit"/>
                    </b-tab>
                    <b-tab title="Учебники">
                        <ItemList model="schoolBook"
                                  :defaultItem="defaultSchoolBook"
                                  :filter="{science_report_id}"
                                  :is-view-item="!isLocalEdit"
                                  :is-add-item="isLocalEdit"
                                  :is-delete-item="isLocalEdit"
                                  :is-edit-item="isLocalEdit"/>
                    </b-tab>
                    <b-tab title="Пособия">
                        <ItemList model="recommendationPublication"
                                  :defaultItem="defaultRecommendationPublication"
                                  :filter="{science_report_id}"
                                  :is-view-item="!isLocalEdit"
                                  :is-add-item="isLocalEdit"
                                  :is-delete-item="isLocalEdit"
                                  :is-edit-item="isLocalEdit"/>
                    </b-tab>
                    <b-tab title="Статьи">
                        <ItemList model="sciencePublication"
                                  :defaultItem="defaultSciencePublication"
                                  :filter="{science_report_id}"
                                  :is-view-item="!isLocalEdit"
                                  :is-add-item="isLocalEdit"
                                  :is-delete-item="isLocalEdit"
                                  :is-edit-item="isLocalEdit"/>
                    </b-tab>
                    <b-tab title="Публикации">
                        <ItemList model="popularSciencePublication"
                                  :defaultItem="defaultPopularSciencePublication"
                                  :filter="{science_report_id}"
                                  :is-view-item="!isLocalEdit"
                                  :is-add-item="isLocalEdit"
                                  :is-delete-item="isLocalEdit"
                                  :is-edit-item="isLocalEdit"/>
                    </b-tab>
                    <b-tab title="Цитируемость">
                        <GeneralCitationCard v-model="generalCitation"
                                             :is-edit="isLocalEdit"/>
                        <!--                        <ItemCard model="generalCitation"-->
                        <!--                                  v-model="generalCitation"-->
                        <!--                                  :is-edit="isLocalEdit"/>-->
                    </b-tab>
                    <b-tab title="Другое">
                        <ItemList model="otherPublication"
                                  :defaultItem="defaultOtherPublication"
                                  :filter="{science_report_id}"
                                  :is-view-item="!isLocalEdit"
                                  :is-add-item="isLocalEdit"
                                  :is-delete-item="isLocalEdit"
                                  :is-edit-item="isLocalEdit"/>
                    </b-tab>
                </b-tabs>
            </b-tab>
            <b-tab title="Цифровая образовательная среда"
                   :disabled="!isViewTab">
                <ItemList model="digitalEducationalResult"
                          :defaultItem="defaultDigitalEducationalResult"
                          :filter="{science_report_id}"
                          :is-view-item="!isLocalEdit"
                          :is-add-item="isLocalEdit"
                          :is-delete-item="isLocalEdit"
                          :is-edit-item="isLocalEdit"/>
            </b-tab>
            <b-tab title="РИД"
                   :disabled="!isViewTab">
                <ItemList model="ridResult"
                          :defaultItem="defaultRidResult"
                          :filter="{science_report_id}"
                          :is-view-item="!isLocalEdit"
                          :is-add-item="isLocalEdit"
                          :is-delete-item="isLocalEdit"
                          :is-edit-item="isLocalEdit"/>
            </b-tab>
            <b-tab title="Инновационные площадки"
                   :disabled="!isViewTab">
                <ItemList model="performanceInnovationSite"
                          :defaultItem="defaultPerformanceInnovationSite"
                          :filter="{science_report_id}"
                          :is-view-item="!isLocalEdit"
                          :is-add-item="isLocalEdit"
                          :is-delete-item="isLocalEdit"
                          :is-edit-item="isLocalEdit"/>
            </b-tab>
            <b-tab title="Участие в работе"
                   :disabled="!isViewTab">
                <ItemList model="participationInWorkEducationSystem"
                          :defaultItem="defaultParticipationInWorkEducationSystem"
                          :filter="{science_report_id}"
                          :is-view-item="!isLocalEdit"
                          :is-add-item="isLocalEdit"
                          :is-delete-item="isLocalEdit"
                          :is-edit-item="isLocalEdit"/>
            </b-tab>
            <b-tab title="Подготовка научных кадров"
                   :disabled="!isViewTab">
                <b-tabs card>
                    <b-tab title="Подготовка">
                        <ItemList model="postgraduateTraining"
                                  :defaultItem="defaultPostgraduateTraining"
                                  :filter="{science_report_id}"
                                  :is-view-item="!isLocalEdit"
                                  :is-add-item="isLocalEdit"
                                  :is-delete-item="isLocalEdit"
                                  :is-edit-item="isLocalEdit"/>
                    </b-tab>
                    <b-tab title="Защиты">
                        <ItemList model="defendedUnderSupervision"
                                  :defaultItem="defaultDefendedUnderSupervision"
                                  :filter="{science_report_id}"
                                  :is-view-item="!isLocalEdit"
                                  :is-add-item="isLocalEdit"
                                  :is-delete-item="isLocalEdit"
                                  :is-edit-item="isLocalEdit"/>
                    </b-tab>
                    <b-tab title="Диссертационные советы">
                        <ItemList model="participationDissertationCouncil"
                                  :defaultItem="defaultParticipationDissertationCouncil"
                                  :filter="{science_report_id}"
                                  :is-view-item="!isLocalEdit"
                                  :is-add-item="isLocalEdit"
                                  :is-delete-item="isLocalEdit"
                                  :is-edit-item="isLocalEdit"/>
                    </b-tab>
                    <b-tab title="ВАК">
                        <ItemCard model="participationVak"
                                  v-model="participationVak"
                                  :is-edit="isLocalEdit"/>
                    </b-tab>
                    <b-tab title="Экспертные советы ВАК">
                        <ItemList model="participationExpertCouncilVAK"
                                  :defaultItem="defaultParticipationExpertCouncilVAK"
                                  :filter="{science_report_id}"
                                  :is-view-item="!isLocalEdit"
                                  :is-add-item="isLocalEdit"
                                  :is-delete-item="isLocalEdit"
                                  :is-edit-item="isLocalEdit"/>
                    </b-tab>
                </b-tabs>
            </b-tab>
            <b-tab title="Конкурсные мероприятия"
                   :disabled="!isViewTab">
                <ItemList model="participationOrganizationActivity"
                          :defaultItem="defaultParticipationOrganizationActivity"
                          :filter="{science_report_id}"
                          :is-view-item="!isLocalEdit"
                          :is-add-item="isLocalEdit"
                          :is-delete-item="isLocalEdit"
                          :is-edit-item="isLocalEdit"/>
            </b-tab>
            <b-tab title="Государственные программы"
                   :disabled="!isViewTab">
                <ItemList model="participationFederalProgram"
                          :defaultItem="defaultParticipationFederalProgram"
                          :filter="{science_report_id}"
                          :is-view-item="!isLocalEdit"
                          :is-add-item="isLocalEdit"
                          :is-delete-item="isLocalEdit"
                          :is-edit-item="isLocalEdit"/>
            </b-tab>
            <b-tab title="Участие в проектах РАО"
                   :disabled="!isViewTab">
                <ItemList model="participationResearchProgram"
                          :defaultItem="defaultParticipationResearchProgram"
                          :filter="{science_report_id}"
                          :is-view-item="!isLocalEdit"
                          :is-add-item="isLocalEdit"
                          :is-delete-item="isLocalEdit"
                          :is-edit-item="isLocalEdit"/>
            </b-tab>
            <b-tab title="Конференции"
                   :disabled="!isViewTab">
                <ItemList model="participationScientificPracticalActivity"
                          :defaultItem="defaultParticipationScientificPracticalActivity"
                          :filter="{science_report_id}"
                          :is-view-item="!isLocalEdit"
                          :is-add-item="isLocalEdit"
                          :is-delete-item="isLocalEdit"
                          :is-edit-item="isLocalEdit"/>
            </b-tab>
        </b-tabs>
    </b-card>
</template>

<script>
    import {
        ScienceReport,
        Monograph,
        RidResult,
        SchoolBook,
        SciencePublication,
        GeneralCitation,
        PopularSciencePublication,
        PerformanceInnovationSite,
        OtherPublication,
        PostgraduateTraining,
        DefendedUnderSupervision,
        ParticipationDissertationCouncil,
        ParticipationOrganizationActivity,
        DigitalEducationalResult,
        ParticipationVak,
        ParticipationFederalProgram,
        ParticipationResearchProgram,
        ParticipationExpertCouncilVak,
        ParticipationScientificPracticalActivity,
        ParticipationInWorkEducationSystem,
        RecommendationPublication
    } from '../../shared/models';

    import { GeneralController, ReportController } from "../controllers";
    import ItemList from "../components/ItemList";
    import ItemCard from "../components/ItemCard";
    import FieldEditor from "../components/FieldEditor.vue";
    import storage from "../storage";
    import FieldInput from "../components/FieldInput";
    import GeneralCitationCard from '../components/GeneralCitationCard';

    // import { library } from '@fortawesome/fontawesome-svg-core';
    // import { faInfo } from '@fortawesome/free-solid-svg-icons';
    // import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    //
    // library.add( faInfo );

    export default {
        name: "ScienceReportCard",
        components: { FieldInput, ItemList, FieldEditor, ItemCard, GeneralCitationCard }
        , props: {
            scienceReport: { type: Object }
            , isEdit: { type: Boolean, default: false }
            , labelCols: { type: [ String, Number ], default: 3 }
            , labelAlign: { type: String, default: 'right' }
        }
        , data: function () {
            return {
                dictionaries: {},
                localScienceReport: this.getScienceReport(),
                generalCitation: null,
                participationVak: null,
                alert: { message: '', show: false },
                session: storage.state.session
            };
        }
        , computed: {
            isLocalEdit(){ return this.isEdit && !this.localScienceReport.is_done },
            science_report_id() { return ( this.localScienceReport && this.localScienceReport.id ) || storage.emptyUID; },
            isViewTab() { return ( !!( this.localScienceReport && this.localScienceReport.id ) ) },
            defaultMonograph: function () { return ( new Monograph( { science_report_id: this.localScienceReport && this.localScienceReport.id } ) ) },
            defaultSchoolBook: function () { return ( new SchoolBook( { science_report_id: this.localScienceReport && this.localScienceReport.id } ) ) },
            defaultRecommendationPublication: function () { return ( new RecommendationPublication( { science_report_id: this.localScienceReport && this.localScienceReport.id } ) ) },
            defaultSciencePublication: function () { return ( new SciencePublication( { science_report_id: this.localScienceReport && this.localScienceReport.id } ) ) },
            defaultPopularSciencePublication: function () { return ( new PopularSciencePublication( { science_report_id: this.localScienceReport && this.localScienceReport.id } ) ) },
            defaultGeneralCitation: function () { return ( new GeneralCitation( { science_report_id: this.localScienceReport && this.localScienceReport.id } ) ) },
            defaultOtherPublication: function () { return ( new OtherPublication( { science_report_id: this.localScienceReport && this.localScienceReport.id } ) ) },
            defaultDigitalEducationalResult: function () { return ( new DigitalEducationalResult( { science_report_id: this.localScienceReport && this.localScienceReport.id } ) ) },
            defaultRidResult: function () { return ( new RidResult( { science_report_id: this.localScienceReport && this.localScienceReport.id } ) ) },
            defaultPerformanceInnovationSite: function () { return ( new PerformanceInnovationSite( { science_report_id: this.localScienceReport && this.localScienceReport.id } ) ) },
            defaultPostgraduateTraining: function () { return ( new PostgraduateTraining( { science_report_id: this.localScienceReport && this.localScienceReport.id } ) ) },
            defaultDefendedUnderSupervision: function () { return ( new DefendedUnderSupervision( { science_report_id: this.localScienceReport && this.localScienceReport.id } ) ) },
            defaultParticipationDissertationCouncil: function () { return ( new ParticipationDissertationCouncil( { science_report_id: this.localScienceReport && this.localScienceReport.id } ) ) },
            defaultParticipationVak: function () { return ( new ParticipationVak( { science_report_id: this.localScienceReport && this.localScienceReport.id } ) ) },
            defaultParticipationExpertCouncilVAK: function () { return ( new ParticipationExpertCouncilVak( { science_report_id: this.localScienceReport && this.localScienceReport.id } ) ) },
            defaultParticipationOrganizationActivity: function () { return ( new ParticipationOrganizationActivity( { science_report_id: this.localScienceReport && this.localScienceReport.id } ) ) },
            defaultParticipationFederalProgram: function () { return ( new ParticipationFederalProgram( { science_report_id: this.localScienceReport && this.localScienceReport.id } ) ) },
            defaultParticipationResearchProgram: function () { return ( new ParticipationResearchProgram( { science_report_id: this.localScienceReport && this.localScienceReport.id } ) ) },
            defaultParticipationScientificPracticalActivity: function () { return ( new ParticipationScientificPracticalActivity( { science_report_id: this.localScienceReport && this.localScienceReport.id } ) ) },

            defaultParticipationInWorkEducationSystem: function () { return ( new ParticipationInWorkEducationSystem( { science_report_id: this.localScienceReport && this.localScienceReport.id } ) ) },
            docxURLReport: function () {
                return ReportController.getEmployeeDocxReportLink( this.localScienceReport.id )
            }
        }
        , validations: {
            localScienceReport: {
                year: {
                    isUnique( value ) {
                        if ( !value ) { return false; }
                        let filter = {
                            id: { $neq: this.localScienceReport.id },
                            employee_id: this.localScienceReport.employee_id,
                            year: value
                        };
                        return GeneralController.checkUniqueField( 'scienceReport', filter );
                    }
                }
            }
        }
        , methods: {
            getScienceReport() {
                if ( this.scienceReport && this.scienceReport.id ) {
                    if ( typeof this.scienceReport.note === 'string' ) {
                        this.scienceReport.note = JSON.parse( this.scienceReport.note );
                    }
                    return Object.assign( {}, this.scienceReport );
                }

                return new ScienceReport( {
                    name: 'Отчет о научной деятельности',
                    year: ( new Date() ).getFullYear(),
                    employee_id: storage.state.session.employee.id,
                    note: `
<p>Отчет должен содержать описание научной деятельности и полученных за отчетный год результатов.</p>
<p>В Отчете отражаются следующие стороны научной деятельности:</p>
<ul>
<li>новизна и актуальность результатов исследований, их научная и возможная практическая значимость в части использования в общем образовании,  в среднем профессиональном образовании, в высшем образовании и дополнительном образовании;</li>
<li>представление результатов  исследований  в виде публикаций, в цифровой образовательной среде, на научно-практических мероприятиях;</li>
<li>результаты интеллектуальной деятельности;</li>
<li>участие в разработке федеральных государственных стандартов общего, среднего профессионального и высшего образования (разработка проектов стандартов, участие в общественном обсуждении, участие в независимой экспертизе);</li>
<li>участие в федеральных целевых, ведомственных и других программах;</li>
<li>участие в реализации проектов, поддержанных научными фондами; участие в национальных проектах; участие в исследованиях, проводимых РАО;</li>
<li>участие в подготовке научных кадров;</li>
<li>участие в работе федеральных и региональных органов государственной власти и общественных организаций;</li>
<li>участие в работе научных проблемных советов;</li>
<li>участие в работе редколлегий научных журналов и других научных периодических изданий;</li>
<li>сведения о наградах, почетных званиях, премиях, полученных в отчетном году.</li>
</ul>`
                } )
            }
            , touch() { this.$v.$touch(); }
            , reset() { this.$v.$reset(); }
            , pending() { return this.$v.$pending; }
            , checkValidity() { return !( this.$v.$pending || this.$v.$anyError ); }
            , debounceSave: function ( e ) {
                this.touch();
                if ( !this.pending() ) { return this.save( e ); }

                let intervalId;
                intervalId = setInterval( () => {
                    if ( !this.pending() ) {
                        this.save( e );
                        clearInterval( intervalId );
                    }
                }, storage.debouncers.default );
            }
            , save() {
                this.show( 'Идет проверка корректности данных...', true );
                // this.touch();
                if ( !this.checkValidity() ) {
                    this.show( 'Обнаружена ошибка. Исправьте, пожалуйста, и повторите попытку заново.', true );
                    return;
                }

                this.show( 'Идет сохранения данных...', true );
                let promise;

                if ( !this.localScienceReport.id ) {
                    promise = GeneralController.addItem( 'scienceReport', this.localScienceReport );
                } else {
                    promise = GeneralController.updateItemById( 'scienceReport', this.localScienceReport.id, this.localScienceReport );
                }

                promise
                    .then( ( item ) => {

                        this.localScienceReport = item;

                        this.$emit( 'update', item );
                        this.show( 'Данные сохранены' );

                        if ( !this.generalCitation.science_report_id ) { this.generalCitation = this.defaultGeneralCitation; }
                        if ( !this.participationVak.science_report_id ) { this.participationVak = this.defaultParticipationVak; }

                    } )
                    .catch( ( error ) => {
                        storage.logNetworkError( error );

                        this.show( 'Обнаружена ошибка на сервере... Пожалуйста, повторите попытку чуть погодя', true );
                    } );


            }
            , cancel() {
                this.$v.$reset();
            }
            , show( message, timeSec = 5 ) {
                this.alert.message = message;
                this.alert.show = timeSec;
            }
            , hide() {
                this.alert.show = false;
            }
            , done( status ) {
                this.localScienceReport.is_done = !!status;
                this.debounceSave();
            }
        }
        , created: function () {
            let filter = { science_report_id: this.science_report_id };
            GeneralController.getItems( 'generalCitation', { filter } )
                .then( ( items ) => {
                    if ( items.count !== 0 ) {
                        this.generalCitation = new GeneralCitation( items.rows[ 0 ] );
                    } else {
                        this.generalCitation = this.defaultGeneralCitation;
                    }
                } )
                .catch( ( error ) => {
                    storage.logNetworkError( error );
                    this.show( 'Ошибка загрузки страницы...' );
                } );

            GeneralController.getItems( 'participationVak', { filter } )
                .then( ( items ) => {
                    if ( items.count !== 0 ) {
                        this.participationVak = new ParticipationVak( items.rows[ 0 ] );
                    } else {
                        this.participationVak = this.defaultParticipationVak;
                    }
                } )
                .catch( ( error ) => {
                    storage.logNetworkError( error );
                    this.show( 'Ошибка загрузки страницы...' );
                } );
        }
    }
</script>

<style scoped>

</style>
