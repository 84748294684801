/**
 * Created by User on 29.09.2019.
 */
'use strict';

import SchemaField from './schema-field.js' ;


export default class Schema {
    /**
     * @typedef {Object} SchemaConfig
     * @property {string} id
     * @property {string} [controller]
     * @property {string} [source]
     * @property {string} name
     * @property {string} [singleName]
     * @property {string} [tooltip]
     * @property {string} [description]
     * @property {{ sortBy: string, sortDesc: boolean }[]} [sorts]
     * @property {{ default: number, items: number[]}} [pagination]
     * @property {SchemaFieldConfig[]} [fields]
     */

    /**
     *
     * @param {SchemaConfig} config
     */
    constructor( config ) {
        this.id = config.id;

        this.source     = config.source;
        this.controller = config.controller;

        this.name       = config.name;
        this.singleName = config.singleName || config.name;

        this.tooltip     = config.tooltip;
        this.description = config.description;

        this.sorts = [];
        ( config.sorts || [] ).forEach( ( item ) => this.sorts.push( item ) );

        this.pagination = { default: 0, items: [] };
        if ( config.pagination ) {
            this.pagination.default = config.pagination.default || 0;
            ( config.pagination.items || [] ).forEach( ( item ) => this.pagination.items.push( item ) );
        }

        this.fields = [];
        config.fields.forEach( ( item ) => this.addField( item ) );

        //ToDo: что то с  действиями и как с ролями быть?! где мапинг дкействия ролей
        this.actions = {
            add:      true
            , view:   true
            , edit:   true
            , remove: true
            , close:  true
            , save:   true
            , cancel: true
        };
    }

    /**
     *
     * @param {SchemaFieldConfig} config
     */
    addField( config ) { this.fields.push( new SchemaField( config ) ); }

    /**
     * @returns {SchemaField}
     */
    getPrimaryKey() {
        return this.fields.find( ( item ) => item.isPrimaryKey );
    }
}
